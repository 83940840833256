import axios from "axios";
import { GET_FEEDBACK_BY_ID, GET_FEEDBACK_COUNTS, GET_FEEDBACK_COUNTS_FAIL, GET_FEEDBACK_LIST, GET_FEEDBACK_LIST_FAIL } from "./feedback.type";

// for feedback counts
export const getFeedbackCount = () => async (dispatch) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}v1/feedback/feedback_count`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

        const responseData = await response.json();

        if (responseData.success) {
            dispatch({ type: GET_FEEDBACK_COUNTS, payload: responseData.data });
        } else {
            dispatch({ type: GET_FEEDBACK_COUNTS_FAIL, payload: responseData.data });
        }
    } catch (error) {
        console.error("Fetching feedback counts failed:", error);
        dispatch({ type: GET_FEEDBACK_COUNTS_FAIL, payload: null }); // Dispatch failure action in case of an error
    }
}

// for  feedback list
export const getFeedbackList = (data) => async (dispatch) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}v1/feedback/all_feedback_list`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data) // Use `body` to send the data
        });

        const responseData = await response.json(); // Correctly handle the JSON response

        // Check if the operation was successful
        if (responseData.success) {
            dispatch({ type: GET_FEEDBACK_LIST, payload: { feedback: responseData.data.feedback, total: responseData.data.total } });
        } else {
            dispatch({ type: GET_FEEDBACK_LIST_FAIL, payload: { feedback: [], total: 0 } });
        }
    } catch (error) {
        console.error("Fetching feedback list failed:", error);
        dispatch({ type: GET_FEEDBACK_LIST_FAIL, payload: { feedback: [], total: 0 } }); // Dispatch failure action in case of an error
    }

}

// get feedback by id
export const getFeedbackById = (id) => async (dispatch) => {
    await axios.get(`v1/feedback/get_feedback_byId?id=${id}`,).then((res) => {
        if (res.data.success) {
            dispatch({ type: GET_FEEDBACK_BY_ID, payload: res.data.data })
        }
    }).catch((error) => console.log(error))
}

// for delete feedback
export const deleteFeedbackById = (data, navigate) => async (dispatch) => {
    await axios.post(`v1/feedback/delete_feedback`, data).then((res) => {
        if (res.data.success) {
            navigate("/feedback")
        }
    }).catch((error) => console.log(error))
}

// for delete multiple feedback
export const deleteMultipleFeedback = (data, navigate) => async (dispatch) => {
    await axios.post(`v1/feedback/delete_multiple_feedback`, data).then((res) => {
        if (res.data.success) {
            navigate("/feedback")
        }
    }).catch((error) => console.log(error))
}