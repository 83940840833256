import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserList } from '../../store/user/user.action';
import { CLEAR_FILTERS_USER } from '../../store/user/user.type';
import moment from 'moment';
import userProfile from '../../Assest/user.png';
import { Action, CloseIcon, CrossIcon, FilterIcon, Leftarrow, Listmenu, Rightlongarrow, Search } from '../../Assest/Allsvg';
import Filtermodal from '../Filtermodal/Filtermodal';
import Actionpopup from '../Actionpopup/Actionpopup';
import Header from '../Header/Header';

const Userlistdetails = () => {
    const [searchParams, setSearchParams] = useState({
        page: 1,
        limit: 12,
        search: '',
        sort: 0,
        startDate: 'ALL',
        endDate: 'ALL',
        status: 3,
        plan: 3,
    });
    const [Id, setId] = useState('');
    const dispatch = useDispatch();
    const location = useLocation();
    const user = useSelector((state) => state.user.user);
    const totalNumber = useSelector((state) => state.user.totalPage);
    const filterData = useSelector((state) => state.user.filterData);
    useEffect(() => {
        if (location.pathname === '/user/userlistdetails') {
            const defaultState = {
                sort: 0,
                startDate: 'ALL',
                endDate: 'ALL',
                status: 3,
                plan: 3
            };
            dispatch({ type: CLEAR_FILTERS_USER, payload: defaultState });
        }
    }, [location.pathname, dispatch]);
    useEffect(() => {
        if (filterData) {
            setSearchParams((prevParams) => ({
                ...prevParams,
                sort: filterData.sort,
                endDate: filterData?.endDate || prevParams.endDate,
                startDate: filterData?.startDate || prevParams.startDate,
                status: filterData.status,
                plan: filterData.plan,
            }));
        }
    }, [filterData]);
    useEffect(() => {
        dispatch(getUserList(searchParams));
    }, [searchParams, dispatch]);
    const totalPages = Math.ceil(totalNumber / searchParams.limit)
    const [currentPage, setCurrentPage] = useState(1);

    // pagination
    const paginationItems = () => {
        let items = [];
        for (let number = 1; number <= totalPages; number++) {
            if (number === 1 || number === totalPages || (number >= currentPage - 1 && number <= currentPage + 1)) {
                items.push(
                    <Link to="#" key={number}
                        className={`p-2 ${number === currentPage ? 'bg-amour-gray' : 'text-cloud-gray'} font-semibold rounded w-8 h-8 flex items-center justify-center`}
                        onClick={() => handleSelectPage(number)}>
                        {number}
                    </Link>
                );
            } else if (number === currentPage - 2 || number === currentPage + 2) {
                if (items[items.length - 1].key !== '...') { // To avoid consecutive "..."
                    items.push(
                        <li key={'...' + number} className='p-2 text-cloud-gray font-semibold rounded w-8 h-8 flex items-center justify-center'>...</li>
                    );
                }
            }
        }
        return items;
    };
    // next button 
    const handleNextClick = () => {
        if (currentPage < totalPages) {
            setSearchParams(prevParams => ({
                ...prevParams,
                page: prevParams.page + 1
            }));
        }
    };
    // prev button
    const handlePriviousClick = () => {
        if (currentPage > 1) {
            setSearchParams(prevParams => ({
                ...prevParams,
                page: prevParams.page - 1
            }));
        }
    };
    const handleSelectPage = (number) => {
        setSearchParams(prevParams => ({
            ...prevParams,
            page: number
        }));

    };
    // filter modal
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // action popover
    const [anchoractionEl, setactionanchorEl] = React.useState(null);
    const handleactionClick = (event, data) => {
        setactionanchorEl(event.currentTarget);
        setId(data);
    };
    const handleactionClose = () => {
        setactionanchorEl(null);
    };
    const handleClearSearch = () => {
        setSearchParams(prevParams => ({
            ...prevParams,
            search: ''
        }))
    }
    useEffect(() => {
        setCurrentPage(searchParams.page);
    }, [searchParams.page]);
    return (
        <>
            <div className='hidden md:block'>
                <Header title="User Management" />
            </div>
            <div className='md:px-5 md:pb-6 md:h-[calc(100%_-_73px)] flex flex-col h-full'>
                <div className='md:pt-6 flex items-center justify-between'>
                    <h4 className='text-base sm:text-xl text-dark-black font-bold'>| Users List</h4>
                    <div className='md:flex hidden items-center'>
                        <Link href="#" to="/user/userlistdetails" className={`border border-moon-gray p-[10px] rounded-lg mr-2
                         ${location.pathname === '/user/userlistdetails' ? 'bg-camo-green listmenuicon' : 'bg-white'}`}><CrossIcon className="w-5 h-5" /></Link>
                        <Link href="#" to="/user" className={`p-[10px] rounded-lg border border-moon-gray bg-white ${location.pathname === 'user' ? 'bg-camo-green' : ''}`}><Listmenu className="w-5 h-5" /></Link>
                    </div>
                </div>
                <div className="overflow-x-auto md:mt-5 bg-white py-4 md:p-5 rounded-lg flex flex-col h-full">
                    <div className='pb-5 flex items-center justify-between text-lg'>
                        <div className='relative flex md:block  w-full md:w-auto'>
                            <div className='absolute top-1/2 -translate-y-1/2 left-4'>
                                <Search className="w-4 sm:w-5" />
                            </div>
                            <input type="text" placeholder='Search name, mail ...' value={searchParams.search} onChange={(e) => setSearchParams({ ...searchParams, search: e.target.value })} className='border border-moon-gray rounded-lg text-sm font-medium 2xl:min-w-[420px] pl-11 py-[10px] placeholder:text-cloud-gray w-full md:w-auto sm:min-w-[420px]' />
                            {searchParams.search && <div className='absolute top-1/2 -translate-y-1/2 right-3 mt-1'>
                                <button onClick={handleClearSearch}>  <CloseIcon className="w-5 h-5" /></button>
                            </div>}
                            <div className='block absolute top-1/2 right-4 -translate-y-1/2 md:hidden'>
                                {searchParams?.search ?
                                    <button onClick={handleClearSearch} className='mt-2'>  <CloseIcon className="w-5 h-5" /></button> :
                                    <>
                                        <Link href="#" onClick={handleClick} className='w-[18px]'>
                                            <FilterIcon className="w-5 h-5" />
                                        </Link>
                                        <Filtermodal anchorEl={anchorEl} handleClose={handleClose} />
                                    </>
                                }
                            </div>
                        </div>
                        <div className='hidden md:block'>
                            <Link href="#" onClick={handleClick} className=' text-dark-black border border-moon-gray flex items-center py-[10px] px-3 gap-2 rounded-lg leading-[normal] text-base font-medium'> <FilterIcon className="w-5 h-5" /> Filters</Link>
                            <Filtermodal className="desktop_filter_modal" anchorEl={anchorEl} handleClose={handleClose} />
                        </div>
                    </div>
                    <div className='overflow-auto flex-1'>
                        <div className='grid md:grid-cols-2 2xl:grid-cols-3 md:gap-4' >
                            {user?.length > 0 ? user.map((item) => (
                                <div className='border-b md:border border-amour-gray pb-5 md:p-4 mb-5 md:mb-0 gap-4 sm:gap-6 flex justify-center flex-col usergridlist'>
                                    <div className='flex justify-between items-center gap-2 text-dark-black font-semibold text-sm md:text-base'>
                                        <div className='flex items-center gap-4'>
                                            <img className='w-8 h-8 object-cover rounded-full md:w-9 md:h-9' src={item?.profile_img || userProfile} alt="" />
                                            <div className='word-break'>
                                                {item?.username}
                                                {item?.isOnline === 1 ? <button className='flex items-center gap-1 text-green font-semibold text-xs'>
                                                    <span className='block w-[6px] h-[6px] bg-green rounded-full' ></span> Active</button> : <button className='flex items-center gap-1 rounded-[63px] text-green-grey font-semibold text-xs'><span className='block w-[6px] h-[6px] bg-green-grey rounded-full' ></span> InActive</button>}
                                            </div>
                                        </div>
                                        <Link href="#" onClick={(e) => handleactionClick(e, item?._id)}><Action className='m-auto w-5' /></Link>
                                        <Actionpopup anchoractionEl={anchoractionEl} handleactionClose={handleactionClose} data={Id} />
                                    </div>
                                    <table>
                                        <tbody className='flex justify-center flex-col gap-2'>
                                            <tr className='flex sm:items-center flex-col sm:flex-row sm:gap-2 md:gap-3'>
                                                <td className='xl:min-w-[150px] flex md:justify-between gap-1 items-center text-sm font-medium md:font-semibold text-cloud-gray md:text-camo-green word-break'>Email ID <span>:</span> </td>
                                                <td className='text-dark-black font-semibold text-sm word-break'>{item?.email}</td>
                                            </tr>
                                            <tr className='flex sm:items-center gap-2 md:gap-3'>
                                                <td className='xl:min-w-[150px] flex md:justify-between gap-1 items-center text-sm font-medium md:font-semibold text-cloud-gray md:text-camo-green'>Created Date <span>:</span></td>
                                                <td className='text-dark-black font-semibold text-sm'>{moment(item?.analytic).format("DD MMM, YYYY")}</td>
                                            </tr>
                                            <tr className='flex sm:items-center gap-2 md:gap-3'>
                                                <td className='xl:min-w-[150px] flex md:justify-between gap-1 items-center text-sm font-medium md:font-semibold text-cloud-gray md:text-camo-green'>Plan Status <span>:</span></td>
                                                <td><button className='bg-light-green-gray py-[3px] md:py-[6px] px-3 md:px-5 rounded-[63px] text-green-grey font-semibold text-sm '>{item?.plan == null ? "Unpaid" : "Paid"}</button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )) : (<div className='col-span-4'>
                                <p className='text-center text-sm text-cloud-gray font-semibold'>Data not found</p>
                            </div>
                            )}
                        </div>
                    </div>
                    <div className='hidden items-center justify-between pt-4 w-full md:flex'>

                        <button className='flex items-center text-cloud-gray text-sm font-semibold gap-2' onClick={currentPage > 1 ? handlePriviousClick : undefined}> <Leftarrow className="w-6" /> PREV</button>

                        <ul className='flex items-center'>
                            {paginationItems()}
                        </ul>

                        <button className='flex items-center text-cloud-gray text-sm font-semibold gap-2' onClick={currentPage < totalPages ? handleNextClick : undefined}>NXT <Rightlongarrow className="w-6" /></button>

                    </div>
                </div>
            </div >
        </>
    )
}

export default Userlistdetails;