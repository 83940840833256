import React from "react";
import { Logoimage } from "../Assest/Allsvg";
import { Link } from "react-router-dom";
import pageNotFound from '../Assest/404.svg';

const defaultPage = () => {
    return (
        <>
            <div className='bg-light-gray h-screen flex justify-center items-center'>
                <div>
                    <div className="d-flex justify-content-center">
                        <Logoimage className='mx-auto' />
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                        <img src={pageNotFound} alt=""></img>
                    </div>
                    <div className="text-center mt-10">
                        <h1 className='fs-responsive fw-blod'>Something gone wrong!</h1>
                    </div>
                    <div className=" text-center mt-8">
                        <Link to='/' className='bg-camo-green text-white text-sm font-semibold py-[3px] px-3 rounded-[63px] md:py-[6px] md:px-5' >Go back</Link>
                    </div>
                </div>
            </div >
        </>
    )
}
export default defaultPage;