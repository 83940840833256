import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Leftangle, PhotosIcon } from '../../Assest/Allsvg';
import { getUserById, updateUser } from '../../store/user/user.action';
import { validateEmail, validateName, validatePhone, validateUsername } from '../validaiton';
import Header from '../Header/Header';

const Edituserdetail = () => {
    const dispatch = useDispatch()
    const fileInputRef = useRef(null);
    const user = useSelector((state) => state.user.userObject)
    useEffect(() => {
        if (user && user._id) {
            localStorage.setItem('userId', user._id);
        }
    }, [user]);
    useEffect(() => {
        const userId = localStorage.getItem('userId');
        if (userId) {
            dispatch(getUserById({ user_id: userId }));
        }
    }, [dispatch]);
    const [imagePreview, setImagePreview] = useState(user?.profile_img);
    const [image, setImage] = useState(null)
    const [name, setName] = useState(user?.name)
    const [username, setUsername] = useState(user?.username)
    const [email, setEmail] = useState(user?.email)
    const [contact_no, setContact_no] = useState(user?.contact_no)
    const [isOnline, setIsOnline] = useState(user?.isOnline)
    const [plan, setPlan] = useState(user?.plan ? 1 : 0)
    const [error, setError] = useState({})
    useEffect(() => {
        setName(user?.name);
        setUsername(user?.username);
        setEmail(user?.email);
        setContact_no(user?.contact_no);
        setIsOnline(user?.isOnline);
        setPlan(user?.plan ? 1 : 0);
        setImagePreview(user?.profile_img);
    }, [user]);
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file)
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const navigate = useNavigate();
    const handleUpdate = () => {
        let obj = {}
        if (!validateEmail(email)) {
            obj.email = 'Please enter valid email address'
        }
      
        if(contact_no){
            if (!validatePhone(contact_no)) {
                obj.phone = 'Please enter valid phone number'
            }
        }
        if (!validateName(name)) {
            obj.name = 'Please enter valid name'
        }
        if (!validateUsername(username) || username == 'Please Enter Username') {
            obj.username = 'Please enter valid username';
        }
        if (!validateName(username)) {
            obj.username = 'Please enter valid username'
        }
        setError(obj)
        const formData = new FormData()
        formData.append('name', name)
        formData.append('username', username)
        formData.append('email', email)
        formData.append('contact_no', contact_no)
        formData.append('isOnline', isOnline)
        formData.append('plan', plan)
        if (image) {
            formData.append('profile_img', image)
        }
        if (Object.keys(obj).length == 0) {
            dispatch(updateUser(formData, user?._id, navigate))
        }
    }
    return (
        <>
            <Header title="User Management" />
            <div className='flex flex-col flex-1 px-5 py-6 h-[calc(100%_-_65px)] md:h-[calc(100%_-_85px)]'>
                <div className='flex flex-col flex-1 overflow-auto bg-white p-5 rounded-[10px] md:bg-transparent md:p-0'>
                    <h4 className='flex items-center text-dark-black text-sm font-bold gap-3 md:text-xl'> <Link className="hidden md:block" href="#" to="/user/userdetails"><Leftangle className="w-6 h-6" /></Link> | Edit User Detail</h4>
                    <div className='flex-col justify-between overflow-auto pt-5 h-full gap-7 rounded-[10px] md:mt-5 md:px-5 md:flex    
                     md:bg-white md:pb-6'>
                        <div>
                            <div className='flex flex-col items-start gap-4 rounded-lg md:flex-row md:items-center'>
                                <div className='relative'>
                                    <img src={imagePreview} alt="" className='w-[100px] h-[100px] rounded-full object-cover' />
                                    <div className='flex items-center absolute bottom-[-5px] right-[-5px] justify-center border border-pele-light-gray 
                                    bg-white rounded-full w-10 h-10' onClick={() => fileInputRef.current.click()}><PhotosIcon /></div>
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleImageChange}
                                    />
                                </div>
                            </div>
                            <div className="grid pt-4 gap-4 md:grid-cols-2 xl:grid-cols-3 md:pt-5 md:gap-5">
                                <div className='flex flex-col gap-2'>
                                    <label className='block text-dark-black text-sm font-semibold'>User Name</label>
                                    <input type="text" placeholder='Please Enter Username' value={username} onChange={(e) => setUsername(e.target.value)} className='border border-spring-rain text-cloud-gray text-sm font-medium py-[10px] pl-3 rounded-lg leading-none  placeholder:text-cloud-gray placeholder:font-medium' />
                                    {error && <p className='text-red text-sm pt-1'>{error.username}</p>}
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <label className='block text-dark-black text-sm font-semibold'>Name</label>
                                    <input type="text" placeholder='Please Enter Name' value={name} onChange={(e) => setName(e.target.value)} className='border border-spring-rain text-cloud-gray text-sm font-medium py-[10px] pl-3 rounded-lg leading-none  placeholder:text-cloud-gray placeholder:font-medium' />
                                    {error && <p className='text-red text-sm pt-1'>{error.name}</p>}
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <label className='block text-dark-black text-sm font-semibold'>Email Address</label>
                                    <input type="text" placeholder='Please Enter email Address' value={email} onChange={(e) => setEmail(e.target.value)} className='border border-spring-rain text-cloud-gray text-sm font-medium py-[10px] pl-3 rounded-lg leading-none  placeholder:text-cloud-gray placeholder:font-medium' />
                                    {error && <p className='text-red text-sm pt-1'>{error.email}</p>}
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <label className='block text-dark-black text-sm font-semibold'>Phone Number</label>
                                    <input type="text" placeholder='Please Enter Phone Number' value={contact_no} onChange={(e) => setContact_no(e.target.value)} className='border border-spring-rain text-cloud-gray text-sm font-medium py-[10px] pl-3 rounded-lg leading-none  placeholder:text-cloud-gray placeholder:font-medium' />
                                    {error && <p className='text-red text-sm pt-1'>{error.phone}</p>}
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <label className='block text-dark-black text-sm font-semibold'>Status</label>
                                    <select value={isOnline} onChange={(e) => setIsOnline(parseInt(e.target.value))} className="border border-spring-rain text-cloud-gray font-medium py-[10px] pl-3 rounded-lg leading-none text-sm">
                                        <option value="1">Active</option>
                                        <option value="0">InActive</option>
                                    </select>
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <label className='block text-dark-black text-sm font-semibold'>Plan Status</label>
                                    <select value={plan} onChange={(e) => setPlan(parseInt(e.target.value))} className="border border-spring-rain text-cloud-gray font-medium py-[10px] pl-3 rounded-lg leading-none text-sm">
                                        <option value="1">Paid</option>
                                        <option value="0">Unpaid</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 items-center justify-center text-center pt-5 gap-4 md:flex md:pt-0 md:gap-7'>
                            <Link to="/user" className='border border-camo-green text-camo-green text-sm
                            font-semibold py-3 px-12 box-border rounded-lg min-w-[50%] md:text-base md:min-w-[150px]'>Cancel</Link>
                            <Link className='border border-transparent bg-camo-green text-white text-sm font-semibold py-3 px-12 
                             box-border rounded-lg min-w-[50%] md:text-base md:min-w-[150px]' onClick={handleUpdate}>Save</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Edituserdetail;