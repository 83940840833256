import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Sidebar from '../Components/Sidebar/Sidebar';
import ClubManagement from '../Components/ClubManagement/ClubManagement';
import Clublistgrid from '../Components/Clublistgrid/Clublistgrid';
import Addclub from '../Components/Addclub/Addclub';
import Editclubdetails from '../Components/Editclubdetails/Editclubdetails';
import Clubdetail from '../Components/Clubdetail/Clubdetail';
import Deleteclub from '../Components/Deleteclub/Deleteclub';

const Clubmanagementpage = () => {
  return (
    <>
      <div className='bg-light-gray h-screen'>
        <div>
          <Sidebar />
        </div>
        <div className='w-full lg:w-[calc(100%_-_350px)] ml-auto h-full overflow-auto flex flex-col'>
          <Routes>
            <Route path="/*" element={<ClubManagement />} />
            <Route path="/clublistgrid" element={<Clublistgrid />} />
            <Route path="/addclub" element={<Addclub />} />
            <Route path="/clubdetail" element={<Clubdetail />} />
            <Route path="/deleteclub" element={<Deleteclub />} />
            <Route path="/editclubdetails" element={<Editclubdetails />} />
          </Routes>
        </div>
      </div>
    </>
  )
}
export default Clubmanagementpage;