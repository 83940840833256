import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from '../Components/Header/Header';
import Sidebar from '../Components/Sidebar/Sidebar';
import Issuereport from '../Components/Issuereport/Issuereport';
import Reportdetails from '../Components/Reportdetails/Reportdetails';

const issuereportpage = () => {
    return (
        <>
            <div className='bg-light-gray h-screen'>
                <Sidebar />
                <div className='w-full lg:w-[calc(100%_-_350px)] ml-auto h-full overflow-auto flex flex-col'>
                    <Header title="Support and Feedback" />
                    <Routes>
                        <Route path="/*" element={<Issuereport />} />
                        <Route path="/reportdetail" element={<Reportdetails />} />
                    </Routes>
                </div>
            </div>
        </>
    )
}

export default issuereportpage;
