import React, { useEffect } from "react";
import Chart from 'react-apexcharts';

const MyAreaChart = () => {
  const allMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const [chartOptions, setChartOptions] = React.useState({
    chart: {
      id: 'area-chart',
      toolbar: {
        show: false
      },
    },
    xaxis: {
      categories: allMonths.slice(0, 6),
      labels: {
        style: {
          fontSize: '12px',
        }
      },
      tooltip: {
        enabled: false,
      }
    },
    colors: ['#5F6C53'],
    stroke: {
      curve: 'smooth'
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0.2,
      }
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      borderColor: '#A3A3A326',
      strokeDashArray: 7,
    },
    tooltip: {
      enabled: true,
      theme: 'light',
      style: {
        fontSize: '24'
      },
      x: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          if (value >= 1000) {
            return (value / 1000).toLocaleString('en', { maximumFractionDigits: 1 }) + '$';
          }
          return value;
        }
      }
    },
  });
  const [series, setSeries] = React.useState([
    {
      name: "Revenue Overview",
      data: [3000, 4000, 2500, 5000, 4900, 2100],
    },
  ]);
  
  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      let categories = allMonths.slice(0, 6);
      if (windowWidth > 640) {
        categories = allMonths;
      }
      setChartOptions(prevOptions => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: categories,
        },
      }));
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div>
      <Chart options={chartOptions} series={series} type="area" height={350} />
    </div>
  );
};
export default MyAreaChart;
