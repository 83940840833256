import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dialog } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { CloseIcon, Deleteicon, Leftangle, Search } from '../../Assest/Allsvg';
import { deleteFeedbackById, getFeedbackById } from '../../store/feedback/feedback.action';
import { DELETE_FEEDBACK } from '../../store/feedback/feedback.type';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import userProfile from '../../Assest/user.png';

const Feedbackdetails = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const item = useSelector((state) => state.feedback.feedbackObject);
    console.log(item, 'item');
    useEffect(() => {
        if (item && item._id) {
            localStorage.setItem('feedbackId', item._id);
        }
    }, [item]);
    useEffect(() => {
        const feedbackId = localStorage.getItem('feedbackId');
        if (feedbackId) {
            dispatch(getFeedbackById(feedbackId));
        }
    }, [dispatch]);
    const handleDelete = () => {
        dispatch({ type: DELETE_FEEDBACK, payload: { id: item._id } })
        dispatch(deleteFeedbackById({ id: item._id }, navigate));
    }
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleClickDelete = () => {
        handleClose()
        handleDelete()
    }
    function Rating({ value }) {
        const validValue = Number.isFinite(value) && value >= 0 ? value : 0;
        const totalStars = 5;
        const filledStars = Array(Math.floor(validValue)).fill('★');
        const halfStar = validValue % 1 !== 0 ? '½' : '';
        const emptyStars = Array(totalStars - Math.ceil(validValue)).fill('☆');
        const starStyle = {
            color: '#EBC230',
            height: '29px',
            width: '29px',
            display: 'inline-block',
            fontSize: '25px',
        };
        return (
            <div className="rating">
                {filledStars.map((star, index) => <span key={`filled-${index}`} style={starStyle}>{star}</span>)}
                {halfStar && <span style={{ ...starStyle, color: '#ffc107' }}>{halfStar}</span>}
                {emptyStars.map((star, index) => <span key={`empty-${index}`} style={{ ...starStyle, color: 'gray' }}>{star}</span>)}
            </div>
        );
    }
    return (
        <>
            <div className='px-4 py-5 sm:px-5 sm:py-6 flex-1 flex flex-col '>
                <div className='bg-white md:bg-transparent flex-1 p-5 md:p-0 rounded-[10px] flex flex-col'>
                    <div className='flex items-center justify-between gap-3'>
                        <h4 className='text-base md:text-xl text-dark-black font-bold flex items-center gap-2'>
                            <Link to="/feedback/">
                                <Leftangle className='w-5' />
                            </Link>
                            | Feedback Details</h4>
                        <div className='flex items-center gap-2 md:hidden'>
                            <Link href="#" onClick={handleClickOpen} className='bg-light-red border border-coral-red-light p-[8px] rounded-lg'><Deleteicon /></Link>
                            <React.Fragment>
                                <Dialog
                                    className="deletemodal"
                                    fullScreen={fullScreen}
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="responsive-dialog-title"
                                >
                                    <button onClick={handleClose} className='absolute right-5 hidden md:block'><CloseIcon /></button>
                                    <h3 className='text-lg md:text-[26px] font-bold md:font-semibold text-woodsmoke text-center pb-[6px] md:pb-6'>Delete Feedback</h3>
                                    <h5 className='text-cloud-gray text-xs text-center md:text-lg font-semibold leading-6'>Are you sure you want to Delete this feedback ?</h5>
                                    <div className='pt-[18px] md:pt-10 flex items-center justify-center gap-5'>
                                        <button className='bg-amour-gray text-camo-green text-sm md:text-lg font-semibold min-w-[143px] md:min-w-[177px] py-3 md:py-4 px-10 md:px-12 rounded-lg' autoFocus onClick={handleClose} >
                                            Cancel
                                        </button>
                                        <button onClick={handleClickDelete} autoFocus className='bg-red-orange text-white text-sm md:text-lg font-semibold min-w-[143px] md:min-w-[177px] py-3 md:py-4 rounded-lg'>
                                            Delete
                                        </button>
                                    </div>
                                </Dialog>
                            </React.Fragment>
                        </div>
                    </div>
                    <div className='md:bg-white rounded-[10px] md:mt-5 md:px-5 pt-4 md:py-5 flex-1'>
                        <div className='md:flex hidden justify-between items-start'>
                            <div className='flex items-center gap-4 bg-light-gray pb-5 md:bg-transparent rounded-lg flex-col md:flex-row'>
                                <img src={item?.user_id?.profile_img || userProfile} alt="" className='w-[60px] h-[60px] rounded-full object-cover' />
                                <h5 className='text-sm md:text-lg lg:text-xl font-bold'>{item?.user_id?.name}</h5>
                            </div>
                            <div className='flex items-center gap-2'>
                                <Link href="#" onClick={handleClickOpen} className='bg-light-red border border-coral-red-light p-[10px] rounded-lg'><Deleteicon /></Link>
                                <React.Fragment>
                                    <Dialog
                                        className="deletemodal"
                                        fullScreen={fullScreen}
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="responsive-dialog-title"
                                    >
                                        <button onClick={handleClose} className='absolute right-5 hidden md:block'><CloseIcon /></button>
                                        <h3 className='text-lg md:text-[26px] font-bold md:font-semibold text-woodsmoke text-center pb-[6px] md:pb-6'>Delete Feedback</h3>
                                        <h5 className='text-cloud-gray text-xs text-center md:text-lg font-semibold leading-6'>Are you sure you want to Delete this feedback ?</h5>
                                        <div className='pt-[18px] md:pt-10 flex items-center justify-center gap-5'>
                                            <button className='bg-amour-gray text-camo-green text-sm md:text-lg font-semibold min-w-[143px] md:min-w-[177px] py-3 md:py-4 px-10 md:px-12 rounded-lg' autoFocus onClick={handleClose} >
                                                Cancel
                                            </button>
                                            <button onClick={handleClickDelete} autoFocus className='bg-red-orange text-white text-sm md:text-lg font-semibold min-w-[143px] md:min-w-[177px] py-3 md:py-4 rounded-lg'>
                                                Delete
                                            </button>
                                        </div>
                                    </Dialog>
                                </React.Fragment>
                            </div>
                        </div>
                        <div className='border-t md:border-none border-t-amour-gray pt-4 md:pt-0 pb-[18px] gap-4 flex justify-center flex-col rounded-lg'>
                            <div className='flex items-center gap-2 text-dark-black font-semibold md:hidden'>
                                <img src={item?.user_id?.profile_img || userProfile} alt="" className='w-10 h-10 object-cover rounded-full' />{item?.user_id?.name}
                            </div>
                            <table className='flex justify-center flex-col gap-[6px] md:gap-3'>
                                <tbody>
                                    <tr className='flex items-center gap-1 md:gap-6'>
                                        <td className='md:min-w-[150px] flex justify-between items-center text-sm font-medium md:font-semibold text-cloud-gray md:leading-[normal]'>Date <span>:</span></td>
                                        <td className='text-dark-black text-sm font-semibold md:leading-[normal]'>{moment(item?.createdAt).format("DD MMM, YYYY")}</td>
                                    </tr>
                                    <tr className='flex items-center gap-1 md:gap-6'>
                                        <td className='md:min-w-[150px] flex justify-between items-center text-sm font-medium md:font-semibold text-cloud-gray md:text-camo-green'>Rating <span>:</span></td>
                                        <Rating value={item?.rating} />
                                    </tr>
                                    <tr className='flex items-start flex-col md:flex-row gap-1 md:gap-6'>
                                        <td className='md:min-w-[150px] flex justify-between items-center text-sm font-medium md:font-semibold text-cloud-gray md:leading-[normal]'>Reviews<span>:</span></td>
                                        <td className="text-dark-black font-semibold text-sm max-w-[450px]">{item?.reviews}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Feedbackdetails;