import React from 'react';
import { Deleteicon, CloseIcon } from '../../Assest/Allsvg';
import { Popover, useMediaQuery, useTheme, Dialog } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DELETE_NOTIFICATION } from '../../store/notification/notification.type';
import { deleteMultipleNotification } from '../../store/notification/notification.action';

const Notificationaction = ({ data, anchoractionEl, handleactionClose }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const handleDelete = () => {
    dispatch({ type: DELETE_NOTIFICATION, payload: { id: data } })
    dispatch(deleteMultipleNotification({ id: [data] }, navigate));
  }
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const handleClickOpen = () => {
    setOpenDeleteDialog(true);
  };
  const handleClickClose = () => {
    setOpenDeleteDialog(false);
    handleactionClose();
  };
  const handleConfirmDelete = () => {
    handleactionClose();
    handleDelete();
  }
  const open = Boolean(anchoractionEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <Popover
        className='action-popover'
        id={id}
        open={Boolean(anchoractionEl)}
        anchorEl={anchoractionEl}
        onClose={handleactionClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className='flex flex-col gap-2'>
          <React.Fragment>
            <button className='flex items-center text-dark-black font-medium gap-3' onClick={handleClickOpen}><Deleteicon /> Delete</button>
            <Dialog
              className="deletemodal"
              fullScreen={fullScreen}
              open={openDeleteDialog}
              onClose={handleClickClose}
              aria-labelledby="responsive-dialog-title"
            >
              <button onClick={handleClickClose} className='hidden absolute right-5 md:block'><CloseIcon /></button>
              <h3 className='text-woodsmoke text-center text-lg font-bold pb-[6px] md:text-[26px] md:font-semibold md:pb-6'>Delete Notification</h3>
              <h5 className='text-cloud-gray text-xs text-center font-semibold leading-6 md:text-lg'>Are you sure you want to Delete this notification ?</h5>
              <div className='flex items-center justify-center pt-[18px] gap-5 md:pt-10'>
                <button className='bg-amour-gray text-camo-green text-sm font-semibold py-3 min-w-[143px] 
                px-10 rounded-lg md:text-lg md:py-4 md:px-12 md:min-w-[177px]' autoFocus onClick={handleClickClose} >
                  Cancel
                </button>
                <button onClick={handleConfirmDelete} autoFocus className='bg-red-orange text-white text-sm font-semibold py-3 min-w-[143px] rounded-lg md:text-lg md:py-4 md:min-w-[177px]'>
                  Delete
                </button>
              </div>
            </Dialog>
          </React.Fragment>
        </div>
      </Popover>
    </>
  )
}
export default Notificationaction;