export const GET_USER_LIST = "GET_USER_LIST"
export const FILTER_DATA_USER = "FILTER_DATA_USER"
export const GET_USER_LIST_FAIL = 'GET_USER_LIST_FAIL';
export const CLEAR_FILTERS_USER = "CLEAR_FILTERS_USER"
export const ADD_NEW_USER = 'ADD_NEW_USER'
export const GET_USER_OBJECT = "GET_USER_OBJECT"
export const GET_USER_BY_ID = "GET_USER_BY_ID"
export const DELETE_USER = "DELETE_USER"
export const UPDATE_USER = "UPDATE_USER"
export const LOADER_START = "LOADER_START"
export const LOADER_CLOSE = "LOADER_CLOSE"
export const DELETE_MULTIPLE_USER = "DELETE_MULTIPLE_USER"