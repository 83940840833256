import { DELETE_ALL_SUPPORT, DELETE_SUPPORT_ID, GET_LIST_AND_COUNT, GET_LIST_AND_COUNT_FAIL, GET_LIST_ERROR, GET_SUPPORT_OBJECT, GET_SUPPORT_OBJECT_BY_ID, SUPPORT_UPDATE_ERROR } from "./support.type"


const initialState = {
    supportList: [],
    supportCounts: null,
    totalPage: 0,
    supportObject: null,
    error: null
}

const supportReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_AND_COUNT:
            return {
                ...state,
                supportList: action.payload.support,
                totalPage: action.payload.total,
                supportCounts: action.payload.counts
            }
        case GET_LIST_AND_COUNT_FAIL:
            return {
                ...state,
                supportList: [],
                totalPage: 0,
                supportCounts: action.payload.counts
            }
        case GET_LIST_ERROR:
            return {
                ...state,
                supportList: [],
                totalPage: 0,
                supportCounts: null
            }
        case GET_SUPPORT_OBJECT:
            const data = state.supportList.find(support => support._id == action.payload.id)
            return {
                ...state,
                supportObject: data
            }

        case GET_SUPPORT_OBJECT_BY_ID:
            return {
                ...state,
                supportObject: action.payload
            }

        case DELETE_SUPPORT_ID:
            const support = state.supportList.filter(support => support._id !== action.payload.id)
            return {
                ...state,
                supportList: support,
                totalPage: state.totalPage - 1
            }

        case DELETE_ALL_SUPPORT:
            const Alldata = state.supportList.filter(support => !action.payload.includes(support._id))
            return {
                ...state,
                supportList: Alldata,
                totalPage: Alldata?.length
            }

        case SUPPORT_UPDATE_ERROR:
            return {
                ...state,
                error: action.payload
            }

        default:
            return state
    }
}

export default supportReducer