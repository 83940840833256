import React, { useMemo, useState } from 'react';
import { GoogleMap, LoadScript, Marker, Polygon, Autocomplete } from '@react-google-maps/api';
import { useMediaQuery } from '@mui/material';

const MapComponent = ({ marker, polyog, mapSize, small }) => {
    const [map, setMap] = useState(null);
    const [autocomplete, setAutocomplete] = useState(null);
    const [error, setError] = useState(null);
    const [position, setPosition] = useState({
        lat: marker?.coordinates[1],
        lng: marker?.coordinates[0]
    });

    const center = useMemo(() => ({ lat: position.lat, lng: position.lng }), [position]);

    let polygonOptions = {};
    if (polyog) {
        polygonOptions = {
            paths: polyog?.coordinates[0]?.map(coord => ({ lng: coord[0], lat: coord[1] })),
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
        };
    }

    const mapsize = mapSize ? mapSize : small;

    const onPlaceChanged = () => {
        try {
            if (autocomplete !== null) {
                const place = autocomplete.getPlace();
                if (place.geometry && place.geometry.location) {
                    const location = place.geometry.location;
                    setPosition({
                        lat: location.lat(),
                        lng: location.lng(),
                    });
                    map.panTo(location);
                } else {
                    throw new Error('Location data is missing!');
                }
            } else {
                throw new Error('Autocomplete is not loaded yet!');
            }
        } catch (err) {
            setError(err.message);
            console.error("Map Error:", err); // Log the error without exposing the API key
        }
    };
    let key = process.env.REACT_APP_GOOGLE_API_KEY

    return (
        <div className='w-full h-full'>
            {error && <div style={{ color: 'red' }}>Error: {error}</div>}
            <LoadScript
                googleMapsApiKey={key}
                libraries={["places"]}
            >
                <GoogleMap
                    mapContainerStyle={mapsize}
                    className="w-full h-full"
                    center={center}
                    zoom={10}
                    onLoad={(mapInstance) => setMap(mapInstance)}
                >
                    {/* Search Bar using Autocomplete */}
                    <div style={{
                        position: 'absolute',
                        top: '10px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: '1',
                        display: 'flex',
                        alignItems: 'center',
                        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                        borderRadius: '3px',
                        backgroundColor: '#fff',
                    }}>
                        <svg
                            width="22"
                            height="22"
                            className='ml-2'
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                                marginRight: '8px',
                                cursor: 'pointer',
                            }}
                        >
                            <path d="M10.5413 19.2497C15.3508 19.2497 19.2497 15.3508 19.2497 10.5413C19.2497 5.73186 15.3508 1.83301 10.5413 1.83301C5.73186 1.83301 1.83301 5.73186 1.83301 10.5413C1.83301 15.3508 5.73186 19.2497 10.5413 19.2497Z" stroke="#686868" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M20.1663 20.1663L18.333 18.333" stroke="#686868" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <Autocomplete
                            onLoad={(autocompleteInstance) => setAutocomplete(autocompleteInstance)}
                            onPlaceChanged={onPlaceChanged}
                        >
                            <input
                                type="text"
                                placeholder="Search for a location"
                                style={{
                                    boxSizing: 'border-box',
                                    border: '1px solid transparent',
                                    width: '240px',
                                    height: '32px',
                                    fontSize: '14px',
                                    outline: 'none',
                                    textOverflow: 'ellipsis',
                                    borderRadius: '3px 0 0 3px',
                                }}
                            />
                        </Autocomplete>
                    </div>

                    {/* Marker for selected location */}
                    {marker && <Marker position={center} />}

                    {/* Polygon if provided */}
                    {polyog && <Polygon options={polygonOptions} />}
                </GoogleMap>
            </LoadScript>
        </div>
    );
};

export default MapComponent;
