import React from 'react';
import { Eyeicon, Editicon, Deleteicon, CloseIcon } from '../../Assest/Allsvg';
import { DELETE_USER, GET_USER_OBJECT } from '../../store/user/user.type';
import { Popover, useMediaQuery, useTheme } from '@mui/material';
import { Dialog } from '@mui/material'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteUserById } from '../../store/user/user.action';

const Actionpopup = ({ anchoractionEl, handleactionClose, data }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const handleView = () => {
    dispatch({ type: GET_USER_OBJECT, payload: { id: data } })
    localStorage.setItem('userId', data);
    navigate('/user/userdetails');
  }
  const handleUpdate = () => {
    dispatch({ type: GET_USER_OBJECT, payload: { id: data } })
    localStorage.setItem('userId', data);
    navigate('/user/edituserdetail')
  }
  const handleDelete = () => {
    dispatch({ type: DELETE_USER, payload: { id: data } })
    localStorage.setItem('userId', data);
    dispatch(deleteUserById({ user_id: data }, navigate));
  }
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const handleClickOpen = () => {
    setOpenDeleteDialog(true);
    handleactionClose(false);
  };
  const handleClickClose = () => {
    setOpenDeleteDialog(false);

  };
  const handleConfirmDelete = () => {
    handleDelete();
    handleactionClose();
  }
  const open = Boolean(anchoractionEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <Popover
        className='action-popover'
        id={id}
        open={open}
        anchorEl={anchoractionEl}
        onClose={handleactionClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className='flex flex-col gap-2'>
          <button className='flex items-center text-dark-black font-medium gap-3' onClick={handleView}><Eyeicon />View</button>
          <button className='flex items-center text-dark-black font-medium gap-3' onClick={handleUpdate}><Editicon /> Edit</button>
          <button className='flex items-center text-dark-black font-medium gap-3' onClick={handleClickOpen}><Deleteicon /> Delete</button>
        </div>
      </Popover>
      <React.Fragment>
        <Dialog
          className="deletemodal"
          fullScreen={fullScreen}
          open={openDeleteDialog}
          onClose={handleClickClose}
          aria-labelledby="responsive-dialog-title"
        >
          <button onClick={handleClickClose} className='absolute hidden right-5  md:block'><CloseIcon /></button>
          <h3 className='text-lg font-bold text-woodsmoke text-center pb-[6px] md:pb-6 md:text-[26px] md:font-semibold'>Delete User</h3>
          <h5 className='text-cloud-gray text-xs text-center font-semibold leading-6 md:text-lg'>Are you sure you want to Delete this user ?</h5>
          <div className='flex items-center justify-center gap-5 pt-[18px] md:pt-10'>
            <button className='bg-amour-gray text-camo-green text-sm font-semibold min-w-[143px] py-3 px-10 rounded-lg md:text-lg md:px-12 md:py-4 md:min-w-[177px]' autoFocus onClick={handleClickClose} >
              Cancel
            </button>
            <button onClick={handleConfirmDelete} autoFocus className='bg-red-orange text-white text-sm  font-semibold min-w-[143px] py-3 
                 rounded-lg md:py-4 md:text-lg md:min-w-[177px]'>
              Delete
            </button>
          </div>
        </Dialog>
      </React.Fragment>
    </>
  )
}

export default Actionpopup