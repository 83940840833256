import React, { useState } from 'react';
import { Leftangle, Search, Uploadphoto } from '../../Assest/Allsvg';
import { Link } from 'react-router-dom';
import Header from '../Header/Header';

const Addnewuser = () => {
    const [username, setUsername] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [isOnline, setIsOnline] = useState(0);
    const [plan, setPlan] = useState(1);

    return (
        <>
            <Header title="User Management" />
            <div className='flex flex-col px-5 py-6 h-[calc(100%_-_65px)] md:h-[calc(100%_-_85px)]'>
                <div className='flex relative mb-5 md:hidden'>
                    <div className='absolute top-1/2 left-4 -translate-y-1/2'>
                        <Search />
                    </div>
                    <input type="text" placeholder='Search here...' className='border border-cloud-light-gray text-cloud-gray text-xs rounded-lg w-full py-[14px] pl-11  placeholder:text-cloud-gray placeholder:font-medium' />
                </div>
                <div className='flex flex-col flex-1 overflow-auto bg-white py-5 rounded-[10px] md:py-0 md:bg-transparent'>
                    <h4 className='flex items-center justify-start text-dark-black text-sm font-bold pb-5 px-5 gap-3 md:text-xl md:px-0'> <Link href="" to="/user" className="hidden md:block"><Leftangle className="w-6" /></Link> | Add new User</h4>
                    <div className='flex flex-col justify-between overflow-auto h-full px-5 rounded-[10px] gap-10 md:bg-white md:pb-6'>
                        <div>
                            <div className='flex flex-col items-center bg-light-gray py-6 gap-4 rounded-lg md:flex-row md:bg-transparent'>
                                <div className='flex items-center justify-center bg-amour-gray md:w-[120px] md:h-[120px] rounded-full'><Uploadphoto /></div>
                                <h5 className='text-dark-black text-sm font-semibold md:text-lg'>Upload Picture</h5>
                            </div>
                            <div className="grid pt-4 gap-5 md:grid-cols-2 xl:grid-cols-3 md:pt-0">
                                <div className='flex flex-col gap-[6px]'>
                                    <label className='block text-dark-black text-xs font-semibold md:text-sm'>User Name</label>
                                    <input type="text" placeholder='Enter first name' className='border border-spring-rain 
                                    text-cloud-gray text-xs font-medium py-2 pl-3 rounded-lg leading-none min-h-10  
                                    placeholder:text-cloud-gray placeholder:font-medium md:text-sm'
                                        value={username} onChange={(e) => setUsername(e.target.value)} />
                                </div>
                                <div className='flex flex-col gap-[6px]'>
                                    <label className='block text-dark-black text-xs font-semibold md:text-sm'>Name</label>
                                    <input type="text" placeholder='Enter last name' className='border border-spring-rain text-cloud-gray text-xs py-2 pl-3 rounded-lg leading-none  min-h-10  font-medium placeholder:text-cloud-gray placeholder:font-medium md:text-sm' value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div className='flex flex-col gap-[6px]'>
                                    <label className='block text-dark-black text-xs font-semibold md:text-sm'>Email Address</label>
                                    <input type="text" placeholder='Enter email address' className='border border-spring-rain text-cloud-gray text-xs py-2 pl-3 rounded-lg leading-none min-h-10 font-medium placeholder:text-cloud-gray placeholder:font-medium md:text-sm' value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className='flex flex-col gap-[6px]'>
                                    <label className='block text-dark-black text-xs font-semibold md:text-sm'>Phone Number</label>
                                    <input type="text" placeholder='Enter phone number' className='border border-spring-rain text-cloud-gray text-xs py-2 pl-3 rounded-lg leading-none min-h-10 font-medium placeholder:text-cloud-gray placeholder:font-medium md:text-sm' value={phone} onChange={(e) => setPhone(e.target.value)} />
                                </div>
                                <div className='flex flex-col gap-[6px]'>
                                    <label className='block text-dark-black text-xs font-semibold md:text-sm'>Status</label>
                                    <select className="border border-spring-rain text-cloud-gray text-xs py-2 pl-3 rounded-lg leading-none min-h-10  font-medium placeholder:text-cloud-gray placeholder:font-medium md:text-sm" value={isOnline} onChange={(e) => setIsOnline(parseInt(e.target.value))}>
                                        <option value="1">Active</option>
                                        <option value="0">InActive</option>
                                    </select>
                                </div>
                                <div className='flex flex-col gap-1'>
                                    <label className='block text-dark-black text-xs font-semibold md:text-sm'>Plan Status</label>
                                    <select className="border border-spring-rain text-cloud-gray text-xs py-2 pl-3 rounded-lg leading-none 
                                     min-h-10 font-medium placeholder:text-cloud-gray placeholder:font-medium md:text-sm" value={plan} onChange={(e) => setPlan(parseInt(e.target.value))}>
                                        <option value="1">Paid</option>
                                        <option value="2">Unpaid</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-2  items-center justify-center text-center pt-5 gap-4 md:flex md:pt-0 md:gap-7'>
                            <Link href="" to="/user" className='border border-camo-green text-camo-green 
                            box-border text-sm font-semibold py-3 px-12 rounded-lg min-w-[50%] leading-[100%] md:text-base md:min-w-[154px]'>Cancel</Link>
                            <Link href="" to="/user/userdetails" className='border border-transparent bg-camo-green text-white 
                            text-sm font-semibold box-border  py-3 px-12  rounded-lg min-w-[50%] leading-[100%] md:text-base md:min-w-[154px]' >Add</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Addnewuser;