import axios from "axios";
import { ADD_NEW_CLUB, FAIL_TO_ADD_CLUB, GET_CLUB_BY_ID, GET_CLUB_CATEGORY, GET_CLUB_CATEGORY_FAIL, GET_CLUB_LIST, GET_CLUB_LIST_FAIL, GET_CLUB_OBJECT, GET_USER_FOR_CLUB, GET_USER_FOR_CLUB_FAIL, LOADER_CLOSE, LOADER_START, UPDATE_CLUB } from "./club.type";


// for club list
export const getClubList = (data) => async (dispatch) => {

    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}v1/club/club_list_for_penal`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        const responseData = await response.json();

        if (responseData.success) {
            dispatch({ type: GET_CLUB_LIST, payload: { club: responseData.data.club, total: responseData.data.total } });
        } else {
            dispatch({ type: GET_CLUB_LIST_FAIL, payload: [] });
        }
    } catch (error) {
        console.log(error);
        dispatch({ type: GET_CLUB_LIST_FAIL, payload: [] }); // Handle error by dispatching a fail action
    }
}

// for delte club
export const deleteclub = (data, navigate) => async (dispatch) => {
    await axios.delete(`v1/club/delete_club`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
        ,
        data: data
    }).then((res) => {

        if (res.data.success) {
            navigate('/club')
        }
    }).catch((error) => console.log(error))
}

// for get club by id
export const getClubObject = (data) => async (dispatch) => {
    await axios.post(`v1/club/get_club_by_id`, data).then((res) => {
        if (res.data.success) {
            dispatch({ type: GET_CLUB_BY_ID, payload: res.data.data })
        }
    }).catch((error) => console.log(error))
}

// for get all club  category
export const getCategory = () => async (dispatch) => {
    await axios.get(`v1/club/show_club_category`).then((res) => {
        if (res.data.success) {
            dispatch({ type: GET_CLUB_CATEGORY, payload: res.data.data })
        } else {
            dispatch({ type: GET_CLUB_CATEGORY_FAIL })
        }
    }).catch((error) => console.log(error))

}

// get user for club owner
export const getUser = (data) => async (dispatch) => {
    await axios.post(`v1/user/search_user`, data,).then((res) => {
        if (res.data.success) {
            dispatch({ type: GET_USER_FOR_CLUB, payload: res.data.data })
        } else {
            dispatch({ type: GET_USER_FOR_CLUB_FAIL, payload: [] })
        }
    }).catch((error) => console.log(error))
}

// for add club by admin
export const addclub = (data, navigate) => async (dispatch) => {
    await axios.post(`v1/club/create_club_by_admin`, data).then((res) => {

        if (res.data.success) {
            dispatch({ type: ADD_NEW_CLUB, payload: res.data.data })
            navigate('/club')

        }
    }).catch((error) => {
        dispatch({ type: FAIL_TO_ADD_CLUB, payload: error.response.data })
        console.log(error)
    })

}

// for edit club 
export const editClub = (data, navigate) => async (dispatch) => {
    await axios.post(`v1/club/edit_club_by_admin`, data).then((res) => {
        if (res?.data?.success) {
            dispatch({ type: UPDATE_CLUB, payload: res.data.data.club })
            navigate("/club/clubdetail")
        }
    }).catch((error) => {
        dispatch({ type: FAIL_TO_ADD_CLUB, payload: error.response.data })
        console.log(error)
    })
}

// for delete multiple club
export const deleteMultipleClub = (data, navigate) => async (dispatch) => {

    await axios.post(`v1/club/deleteMultipleClub`, data).then((res) => {
        if (res?.data?.success) {
            // dispatch({ type: UPDATE_CLUB, payload: res.data.data.club })
            navigate("/club")
        }
    }).catch((error) => console.log(error))
}