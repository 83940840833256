import axios from "axios";
import { DELETE_SUPPORT_ID, GET_LIST_AND_COUNT, GET_LIST_AND_COUNT_FAIL, GET_LIST_ERROR, GET_SUPPORT_OBJECT_BY_ID, SUPPORT_UPDATE_ERROR } from "./support.type";
import Supportticket from "../../Components/Supportticket/Supportticket";

// for get list and count of supports
export const getSupportList = (data) => async (dispatch) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}v1/support/support_for_penal`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const responseData = await response.json();

        if (responseData.success) {
            if (responseData.data.totalPage > 0) {
                dispatch({
                    type: GET_LIST_AND_COUNT,
                    payload: {
                        support: responseData.data.support,
                        total: responseData.data.totalPage,
                        counts: {
                            avg: responseData.data.avgTotal,
                            supportTicket: responseData.data.totalSupportTicket
                        }
                    }
                });
            } else {
                dispatch({
                    type: GET_LIST_AND_COUNT_FAIL,
                    payload: {
                        support: responseData.data.support,
                        total: 0,
                        counts: {
                            avg: responseData.data.avgTotal,
                            supportTicket: responseData.data.totalSupportTicket
                        }
                    }
                });
            }
        } else {
            dispatch({ type: GET_LIST_ERROR });
        }
    } catch (error) {
        console.error("Fetching support data failed:", error);
        dispatch({ type: GET_LIST_ERROR });
    }
}

// get support using id
export const getSupportById = (id) => async (dispatch) => {
    await axios.get(`v1/support/get_support_byId?id=${id}`).then((res) => {
        if (res.data.success) {
            dispatch({ type: GET_SUPPORT_OBJECT_BY_ID, payload: res.data.data })
        }
    }).catch((err) => console.log(err))
}

//delete support by id
export const deleteSupport = (id, navigate) => async (dispatch) => {
    await axios.delete(`v1/support/delete_support?id=${id}`).then((res) => {
        if (res.data.success) {
            navigate('/supportticket')
        }
    }).catch((err) => console.log(err))
}

//delete all support by id
export const deleteAllSupport = (data, navigate) => async (dispatch) => {
    await axios.post(`v1/support/delete_all_support`, data).then((res) => {
        if (res.data.success) {
            navigate('/supportticket')
        }
    }).catch((err) => console.log(err))
}

// update support by admin
export const updateSupport = (data, id) => async (dispatch) => {
    await axios.post(`v1/support/updateSupport?id=${id}`, data).then((res) => {
        if (res.data.success) {
            dispatch({ type: GET_SUPPORT_OBJECT_BY_ID, payload: res.data.data })
            dispatch({ type: SUPPORT_UPDATE_ERROR, payload: null })
        } else {
            dispatch({ type: SUPPORT_UPDATE_ERROR, payload: res.data.message })
        }
    })
}