import React from 'react';

const Loader = () => {
    return (

        <div className="custom-loader-wrapper" style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
        }}>
            <div className="custom-loader "></div>
        </div>

    );
};

export default Loader;