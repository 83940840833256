import axios from 'axios'
import { ADD_NEW_NOTIFICATION, GET_LIST_FAIL, GET_NOTIFICATION_LIST } from './notification.type';


// for get notification list
export const getNotificationList = (data) => async (dispatch) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}v1/notification/get_list_for_penal`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();

        if (responseData.data.total > 0) {
            dispatch({ type: GET_NOTIFICATION_LIST, payload: { notification: responseData.data.notification, total: responseData.data.total } });
        } else {
            dispatch({ type: GET_LIST_FAIL, payload: { notification: [], total: 0 } });
        }
    } catch (error) {
        console.error("Fetching notifications failed:", error);
        dispatch({ type: GET_LIST_FAIL, payload: { notification: [], total: 0 } });
    }
}

//for send new notification
export const addNotification = (data, navigate) => async (dispatch) => {

    await axios.post(`v1/notification/sendBy_admin_all_user`, data).then((res) => {

        if (res.data.success) {
            dispatch({ type: ADD_NEW_NOTIFICATION, payload: res.data.data })
            navigate("/notification")
        }
    }).catch((err) => console.log(err))
}

// for delete multiple notification
export const deleteMultipleNotification = (data, navigate) => async (dispatch) => {
    await axios.post(`v1/notification/deleteMultipleNotification`, data).then((res) => {

        if (res.data.success) {
            // dispatch({ type: ADD_NEW_NOTIFICATION, payload: res.data.data })
            navigate("/notification")
        }
    }).catch((err) => console.log(err))
}