import { ADD_NEW_NOTIFICATION, DELETE_MULTIPLE_NOTIFICATION, DELETE_NOTIFICATION, GET_LIST_FAIL, GET_NOTIFICATION_LIST } from "./notification.type"




const initialState = {
    notification: [],
    totalPage: 0
}


const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_NOTIFICATION_LIST:
            return {
                ...state,
                notification: action.payload.notification,
                totalPage: action.payload.total
            }
        case GET_LIST_FAIL:
            return {
                ...state,
                notification: [],
                totalPage: 0
            }
        case ADD_NEW_NOTIFICATION:
            return {
                ...state,
                notification: state.notification.unshift(action.payload),
                totalPage: state.totalPage + 1
            }
        case DELETE_MULTIPLE_NOTIFICATION:
            const Alldata = state.notification.filter(data => !action.payload.includes(data?._id))
            return {
                ...state,
                notification: Alldata,
                totalPage: Alldata.length
            }
        case DELETE_NOTIFICATION:
            const data = state.notification.filter(data => data._id !== action.payload.id)
            return {
                ...state,
                notification: data,
                totalPage: state.totalPage - 1
            }

        default:
            return state
    }
}

export default notificationReducer