import React, { useState } from 'react';
import { CloseIcon } from '../../Assest/Allsvg';
import { FormControl, FormControlLabel, Popover, Radio, RadioGroup } from '@mui/material';
import { useDispatch } from 'react-redux';
import { CLEAR_FILTERS, FILTER_DATA } from '../../store/club/club.type';

const Clubfilter = ({ anchorEl, handleClose, className }) => {
    const dispatch = useDispatch();
    const [sort, setSort] = useState(0);
    const [startDate, setStartDate] = useState('ALL');
    const [endDate, setEndDate] = useState('ALL');
    let Data = {
        sort, startDate, endDate
    }
    const handleFilter = () => {
        dispatch({ type: FILTER_DATA, payload: Data })
    }
    const handleCloseAndFilter = () => {
        handleFilter()
        handleClose()
    }
    const handleClearFilters = () => {


        const defaultState = {
            sort: 0,
            startDate: 'ALL',
            endDate: 'ALL',

        };
        setSort(defaultState.sort);
        setStartDate(defaultState.startDate);
        setEndDate(defaultState.endDate);


        dispatch({ type: CLEAR_FILTERS, payload: defaultState });
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <>
            <Popover
                className={`filtermodal ${className}`}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className='flex items-center justify-between border-b border-b-amour-gray pt-4 pb-2 px-4'>
                    <h3 className='text-dark-black text-[22px] font-bold leading-[normal]'>Filters</h3>
                    <button onClick={handleClose}><CloseIcon /></button>
                </div>
                <div className='border-b border-b-amour-gray py-5 px-4'>
                    <h4 className='text-dark-black text-xl font-semibold leading-[normal]'>Sort by</h4>
                    <div>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={sort}
                                onChange={(e) => setSort(parseInt(e.target.value))}
                                name="radio-buttons-group"
                                className="gap-3 pt-4"
                            >
                                <FormControlLabel value="0" control={<Radio />} label="Name (A to Z)" />
                                <FormControlLabel value="1" control={<Radio />} label="Name (Z to A)" />
                                <FormControlLabel value="2" control={<Radio />} label="Oldest to Newest" />
                                <FormControlLabel value="3" control={<Radio />} label="Newest to Oldest" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <div className='border-b border-b-amour-gray py-5 px-4'>
                    <h4 className='text-dark-black text-xl font-semibold leading-[normal]'>Date Range :</h4>
                    <div className="grid grid-cols-2 gap-4">
                        <div className='flex flex-col pt-5'>
                            <label className='text-cloud-gray text-lg font-medium pb-[10px] leading-[normal]'>From</label>
                            <input type="date" placeholder='hello' className='border border-moon-gray py-2 px-3 rounded-md 
                            leading-[normal] date_piker' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                        </div>
                        <div className='flex flex-col pt-5'>
                            <label className='text-cloud-gray font-medium text-lg pb-[10px] leading-[normal]'>To</label>
                            <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} placeholder='hello' className='border border-moon-gray py-2 px-3 
                            rounded-md leading-[normal] date_piker' />
                        </div>
                    </div>
                </div>
                <div className='flex items-center justify-between pt-7 pb-4 px-4'>
                    <button className='text-camo-green font-semibold' onClick={handleClearFilters}>Clear</button>
                    <button className='bg-camo-green text-white font-semibold py-2 px-5 rounded-md leading-[normal]' onClick={handleCloseAndFilter}>Apply All Filter</button>
                </div>
            </Popover>
        </>
    )
}
export default Clubfilter;