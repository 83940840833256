import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from '../Components/Header/Header';
import Sidebar from '../Components/Sidebar/Sidebar';
import Feedbackdetails from '../Components/Feedbackdetails/Feedbackdetails';
import Feedback from '../Components/Feedback/Feedback';

const feedbackpage = () => {
    return (
        <>
            <div className='bg-light-gray h-screen'>
                <Sidebar />
                <div className='w-full lg:w-[calc(100%_-_350px)] ml-auto h-full overflow-auto flex flex-col'>
                    <Header title="Support and Feedback" />
                    <Routes>
                        <Route path="/*" element={<Feedback />} />
                        <Route path="/feedbackdetail" element={<Feedbackdetails />} />
                    </Routes>
                </div>
            </div>
        </>
    )
}

export default feedbackpage;
