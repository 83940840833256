import { BrowserRouter } from 'react-router-dom';
import Loginpage from './Page/Loginpage';
import Usermanagementpage from './Page/Usermanagementpage';
import Clubmanagementpage from './Page/Clubmanagementpage';
import Dashboardpage from './Page/Dashboardpage';
import Notificationpage from './Page/Notificationpage';
import DefaultPage from './Page/page404';
import useSetupAxiosInterceptors from './Components/Loader/SetDefault';
import { useLoading } from './Components/Loader/LoaderContext';
import Loader from './Components/Loader/Loader';
import Feedbackpage from './Page/feedbackpage';
import Issuereportpage from './Page/issuereportpage';
import Supportticketpage from './Page/supportticketpage';
import { Navigate, Route, Routes } from 'react-router-dom/dist/umd/react-router-dom.development';
import './App.css';

function App() {
  const isAuthenticated = () => {
    return localStorage.getItem('token') !== null
  }
  useSetupAxiosInterceptors();
  const { isLoading } = useLoading();
  return (
    <>
      {isLoading && <div className='absolute z-30 inset-0 bg-black bg-opacity-50 flex justify-center items-center'><Loader /></div>}
      <BrowserRouter>
        <Routes>
        <Route path="/" element={localStorage.getItem('token') ? <Navigate replace to="/dashboard" /> : <Loginpage />} />
          <Route path="/dashboard" element={isAuthenticated() ? <Dashboardpage /> : <Navigate replace to="/" />} />
          <Route path="/user/*" element={isAuthenticated() ? <Usermanagementpage /> : <Navigate replace to="/" />} />
          <Route path="/club/*" element={isAuthenticated() ? <Clubmanagementpage /> : <Navigate replace to="/" />} />
          <Route path="/notification/*" element={isAuthenticated() ? <Notificationpage /> : <Navigate replace to="/" />} />
          <Route path="/feedback/*" element={isAuthenticated() ? <Feedbackpage /> : <Navigate replace to="/" />} />
          <Route path="/issuereport/*" element={isAuthenticated() ? <Issuereportpage /> : <Navigate replace to="/" />} />
          <Route path="/supportticket/*" element={isAuthenticated() ? <Supportticketpage /> : <Navigate replace to="/" />} />
          <Route path="*" element={<DefaultPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
