import React from 'react';
import Sidebar from '../Components/Sidebar/Sidebar';
import Dashboard from '../Components/Dashboard/Dashboard';

const Dashboardpage = () => {
    return (
        <div>
            <div className='bg-light-gray sm:h-screen'>
                <div>
                    <Sidebar />
                </div>
                <div className='w-full lg:w-[calc(100%_-_350px)] ml-auto h-full sm:overflow-auto flex flex-col'>
                    <Dashboard />
                </div>
            </div>
        </div>
    )
}
export default Dashboardpage;