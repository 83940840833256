import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Admanage, Category, Club, Logoimage, Logout, Marketing, Notification, Privacy, Report, Suport, Tracking, Userimage } from '../../Assest/Allsvg';

const Sidebar = () => {
    const location = useLocation();
    let navigate = useNavigate();
    const handleLogut = async (event) => {
        event.preventDefault()
        await localStorage.removeItem('token');
        navigate('/');
    }
    const userManagementRoutes = [
        '/user',
        '/user/addnewuser',
        '/user/userdetails',
        '/user/edituserdetail',
        '/user/userlistdetails',
    ];
    const clubManagementRoutes = [
        '/club',
        '/club/addclub',
        '/club/clubdetail',
        '/club/clublistgrid',
        '/club/editclubdetails',
    ];
    const notificationRoutes = [
        '/notification',
        '/notification/addnewnotification',
    ];
    const feedbackRoutes = [
        '/feedback',
    ];
    const issuereportRoutes = [
        '/issuereport',
    ];
    const supportRoutes = [
        '/supportticket',
    ];
    const isActiveUserManagement = userManagementRoutes.some(route => location.pathname === route);
    const isActiveClubManagement = clubManagementRoutes.some(route => location.pathname === route);
    const isActiveNotification = notificationRoutes.some(route => location.pathname === route);
    const isActivefeedback = feedbackRoutes.some(route => location.pathname === route);
    const isActiveissuereport = issuereportRoutes.some(route => location.pathname === route);
    const isActivesupport = supportRoutes.some(route => location.pathname === route);
    return (
        <div className="sidebar_main pt-6 pb-2 fixed h-full  md:min-w-[350px] box-border md:bg-white hidden lg:flex flex-col justify-between overflow-auto">
            <div>
                <Link to="#" className='px-5 block'>
                    <Logoimage className="w-52 mx-auto" />
                </Link>
                <ul className="sidebar_menu pt-6 pr-[10px]">
                    <li><Link to="/dashboard" className={`flex items-center  font-medium  gap-2 py-3 px-5 text-sm md:text-base 
                     ${location.pathname === '/dashboard' ? 'rounded-lg md:border-l-4 border-[#47543C] bg-milk-white text-camo-green font-semibold' : 'border-l-4 border-transparent font-medium text-cloud-gray'}`}>
                        <Category className="w-5" /> Dashboard</Link></li>
                    <li>
                        <Link
                            to="/user"
                            className={`flex items-center font-medium gap-2 py-3 px-5 text-sm md:text-base ${isActiveUserManagement
                                ? 'rounded-lg border-l-4 border-[#47543C] bg-milk-white text-camo-green font-semibold'
                                : 'border-l-4 border-transparent text-cloud-gray'
                                }`}
                        >
                            <Userimage className="w-5" />User Management
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/club"
                            className={`flex items-center font-medium gap-2 py-3 px-5 text-sm md:text-base ${isActiveClubManagement
                                ? 'rounded-lg border-l-4 border-[#47543C] bg-milk-white text-camo-green font-semibold'
                                : 'border-l-4 border-transparent text-cloud-gray'
                                }`}
                        >
                            <Club className="w-5" />Club Management
                        </Link>
                    </li>
                    {/* <li><Link className="flex items-center border-l-4 border-transparent font-medium gap-2 py-3 px-5 text-sm md:text-base text-cloud-gray"> <Tracking className="w-5" /> Revenue Tracking</Link></li>
                    <li><Link className="flex items-center border-l-4 border-transparent font-medium gap-2 py-3 px-5 text-sm md:text-base text-cloud-gray"><Admanage className="w-5" /> Ad Management</Link></li> */}
                    {/* <li><Link className="flex items-center border-l-4 border-transparent font-medium gap-2 py-3 px-5 text-sm md:text-base text-cloud-gray"> <Report className="w-5" /> Reporting and Analytics</Link></li> */}
                    <li>
                        <Link
                            to="/notification"
                            className={`flex items-center font-medium gap-2 py-3 px-5 text-sm md:text-base ${isActiveNotification
                                ? 'rounded-lg border-l-4 border-[#47543C] bg-milk-white text-camo-green font-semibold'
                                : 'border-l-4 border-transparent text-cloud-gray'
                                }`}
                        >
                            <Notification className="w-5" /> Notification and Alerts
                        </Link>
                    </li>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<KeyboardArrowDownIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className='feedback_menu'
                        >
                            <Link to="#" className='flex items-center border-l-4 border-transparent font-medium gap-2 py-3 px-5 text-sm md:text-base text-cloud-gray'><Suport className="w-5" /> Support and Feedback</Link>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Link
                                to="/feedback"
                                className={`flex items-center font-medium gap-2 py-3 px-5 text-sm md:text-base ${isActivefeedback
                                    ? 'rounded-lg border-l-4 border-[#47543C] bg-milk-white text-camo-green font-semibold'
                                    : 'border-l-4 border-transparent text-cloud-gray'
                                    }`}
                            > Feedback
                            </Link>
                            <Link
                                to="/issuereport"
                                className={`flex items-center font-medium gap-2 py-3 px-5 text-sm md:text-base ${isActiveissuereport
                                    ? 'rounded-lg border-l-4 border-[#47543C] bg-milk-white text-camo-green font-semibold'
                                    : 'border-l-4 border-transparent text-cloud-gray'
                                    }`}
                            > Issues Report
                            </Link>
                            <Link
                                to="/supportticket"
                                className={`flex items-center font-medium gap-2 py-3 px-5 text-sm md:text-base ${isActivesupport
                                    ? 'rounded-lg border-l-4 border-[#47543C] bg-milk-white text-camo-green font-semibold'
                                    : 'border-l-4 border-transparent text-cloud-gray'
                                    }`}
                            >Support Ticket
                            </Link>
                        </AccordionDetails>
                    </Accordion>
                    {/* <li><Link className="flex items-center text-cloud-gray font-medium gap-2 py-3 px-5 text-sm border-l-4 border-transparent md:text-base"> <Marketing className="w-5" /> Marketing Tools</Link></li>
                    <li><Link className="flex items-center text-cloud-gray border-l-4 border-transparent font-medium gap-2 py-3 px-5 text-sm md:text-base"> <Privacy className="w-5" /> Security and Privacy</Link></li> */}
                </ul>
            </div>
            <ul>
                <li><Link onClick={handleLogut} className={`flex items-center text-cloud-gray font-medium gap-2 py-3 px-5 text-sm md:text-base  ${location.pathname === '/' ? 'rounded-lg border-l-4 border-[#47543C] bg-milk-white font-semibold' : 'border-l-4 border-transparent'
                    }`} > <Logout className="w-5" />Log Out</Link></li>
            </ul>
        </div>
    )
}
export default Sidebar;