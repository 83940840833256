import { useEffect } from 'react';
import axios from 'axios';
import { useLoading } from './LoaderContext';

const useSetupAxiosInterceptors = () => {
    const { setIsLoading } = useLoading();
    useEffect(() => {
        const reqInterceptor = axios.interceptors.request.use(
            (config) => {
                setIsLoading(true);
                return config;
            },
            (error) => {
                setIsLoading(false);
                return Promise.reject(error);
            }
        );
        const resInterceptor = axios.interceptors.response.use(
            (response) => {
                setIsLoading(false);
                return response;
            },
            (error) => {
                setIsLoading(false);
                return Promise.reject(error);
            }
        );
        return () => {
            axios.interceptors.request.eject(reqInterceptor);
            axios.interceptors.response.eject(resInterceptor);
        };
    }, [setIsLoading]);

    return null;
};

export default useSetupAxiosInterceptors;