import { ADD_NEW_CLUB, CLEAR_FILTERS, DELETE_CLUB, DELETE_MULTIPLE_CLUB, FAIL_TO_ADD_CLUB, FILTER_DATA, GET_CLUB_BY_ID, GET_CLUB_CATEGORY, GET_CLUB_CATEGORY_FAIL, GET_CLUB_LIST, GET_CLUB_LIST_FAIL, GET_CLUB_OBJECT, GET_USER_FOR_CLUB, GET_USER_FOR_CLUB_FAIL, LOADER_CLOSE, LOADER_START, UPDATE_CLUB } from "./club.type";



const initialState = {
    club: [],
    totalPage: 0,
    filterData: null,
    clubObject: null,
    category: [],
    user: [],
    isLoading: false,
    addClubError: ''
}


const clubReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CLUB_LIST:
            return {
                ...state,
                club: action.payload.club,
                totalPage: action.payload.total,

            }
        case GET_CLUB_LIST_FAIL:
            return {
                ...state,
                club: [],
                totalPage: 0
            }
        case FILTER_DATA:
            return {
                ...state,
                filterData: action.payload
            }
        case CLEAR_FILTERS:
            return {
                ...state,
                filterData: action.payload
            }
        case GET_CLUB_OBJECT:
            const data = state.club.find(club => club._id === action.payload.id)
            return {
                ...state,
                clubObject: data
            }
        case GET_CLUB_BY_ID:
            return {
                ...state,
                clubObject: action.payload
            }

        case GET_CLUB_CATEGORY:
            return {
                ...state,
                category: action.payload
            }
        case GET_CLUB_CATEGORY_FAIL:
            return {
                ...state,
                category: []
            }

        case GET_USER_FOR_CLUB:
            return {
                ...state,
                user: action.payload
            }

        case UPDATE_CLUB:
            return {
                ...state,
                clubObject: action.payload,
                addClubError: ''
            }

        case GET_USER_FOR_CLUB_FAIL:
            return {
                ...state,
                user: action.payload
            }
        case ADD_NEW_CLUB:
            return {
                ...state,
                club: [action.payload, ...state.club],
                totalPage: state.totalPage + 1,
                addClubError: ''
            }
        case FAIL_TO_ADD_CLUB:
            return {
                ...state,
                addClubError: action.payload.message
            }

        case DELETE_CLUB:
            const newData = state.club.filter((club) => club?._id !== action.payload.id)
            return {
                ...state,
                club: newData,
                totalPage: state.totalPage - 1
            }

        case LOADER_START:

            return {
                ...state,
                isLoading: true
            }
        case LOADER_CLOSE:
            return {
                ...state,
                isLoading: false
            }
        case DELETE_MULTIPLE_CLUB:
            const Alldata = state.club.filter(club => !action.payload.includes(club?._id))
            return {
                ...state,
                club: Alldata,
                totalPage: Alldata.length
            }

        default:
            return state
    }
}

export default clubReducer