import React, { useEffect, useState } from 'react';
import { Checkbox, Dialog, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Leftarrow, Rightlongarrow, Search, Action, CloseIcon, Deleteicon, Plusicon } from '../../Assest/Allsvg';
import { deleteMultipleNotification, getNotificationList } from '../../store/notification/notification.action';
import Notificationaction from '../Notificationaction/Notificationaction';
import { DELETE_MULTIPLE_NOTIFICATION } from '../../store/notification/notification.type';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import Notificationdetails from '../Notificationdetails/Notificationdetails';
import Header from '../Header/Header';

const Notification = () => {
  const [searchParams, setSearchParams] = useState({
    page: 1,
    limit: 10,
    search: '',
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notification = useSelector((state) => state.notification.notification);
  const totalNumber = useSelector((state) => state.notification.totalPage);
  useEffect(() => {
    dispatch(getNotificationList(searchParams));
  }, [searchParams, dispatch]);
  const totalPages = Math.ceil(totalNumber / searchParams.limit)
  const [currentPage, setCurrentPage] = useState(1);
  const paginationItems = () => {
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
      if (number === 1 || number === totalPages || (number >= currentPage - 1 && number <= currentPage + 1)) {
        items.push(
          <a hre="#" key={number}
            className={`p-2 ${number === currentPage ? 'bg-amour-gray' : 'text-cloud-gray'} font-semibold rounded w-8 h-8 flex items-center justify-center`}
            onClick={() => handleSelectPage(number)}>
            {number}
          </a>
        );
      } else if (number === currentPage - 2 || number === currentPage + 2) {
        if (items[items.length - 1].key !== '...') {
          items.push(
            <a href="#" key={'...' + number} className='p-2 text-cloud-gray font-semibold rounded w-8 h-8 flex items-center justify-center'>...</a>
          );
        }
      }
    }
    return items;
  };
  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setSearchParams(prevParams => ({
        ...prevParams,
        page: prevParams.page + 1
      }));
    }
  };
  const handlePriviousClick = () => {
    if (currentPage > 1) {
      setSearchParams(prevParams => ({
        ...prevParams,
        page: prevParams.page - 1
      }));
    }
  };
  const handleSelectPage = (number) => {
    setSearchParams(prevParams => ({
      ...prevParams,
      page: number
    }));
  };
  useEffect(() => {
    setCurrentPage(searchParams.page);
  }, [searchParams.page]);
  const [id, setId] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    const selectedData = checked ? notification.map((data) => data._id) : [];
    setSelectedRows(selectedData);
  };
  const handleSelectRow = (event, id) => {
    const checked = event.target.checked;
    if (checked) {
      setSelectedRows((prevSelected) => [...prevSelected, id]);
    } else {
      setSelectedRows((prevSelected) => prevSelected.filter((rowId) => rowId !== id));
    }
  };
  const handleClearSearch = () => {
    setSearchParams(prevParams => ({
      ...prevParams,
      search: ''
    }))
  }
  const handleDeleteMultiple = () => {
    dispatch({ type: DELETE_MULTIPLE_NOTIFICATION, payload: selectedRows })
    dispatch(deleteMultipleNotification({ id: selectedRows }, navigate))
  }
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [Open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };
  const handleOpenClick = () => {
    handleClickClose()
    handleDeleteMultiple()
  }
  // action popover
  const [anchoractionEl, setactionanchorEl] = React.useState(null);
  const handleactionClick = (event, data) => {
    setactionanchorEl(event.currentTarget);
    setId(data);
  };
  const handleactionClose = () => {
    setactionanchorEl(null);
  };
  return (
    <>
      <Header title="Notification and Alerts" />
      <div className='relative h-[calc(100%_-_65px)]'>
        <div className='p-4 sm:px-5 sm:py-6 h-full md:h-[calc(100%_-_85px)]'>
          <div className='hidden items-center justify-between pb-5 md:flex'>
            <h4 className='text-dark-black text-xl font-bold'>| Alerts Notification</h4>
            <div className='flex items-center'>
              <Link to="/notification/addnewnotification" className='bg-camo-green text-sm text-white font-semibold py-[10px] px-8 rounded-lg'>+ Add New Notification</Link>
            </div>
          </div>
          <div className="flex flex-col flex-1 h-full overflow-x-auto bg-white p-5 rounded-lg">
            <div className='hidden items-center justify-between text-lg pb-5 md:flex'>
              <div className='flex relative'>
                <div className='absolute top-1/2 left-4 -translate-y-1/2'>
                  <Search />
                </div>
                <input type="text" placeholder='Search message...' className='border border-moon-gray text-sm font-medium pl-11 py-[10px] rounded-lg 2xl:min-w-[420px] placeholder:text-cloud-gray' value={searchParams.search} onChange={(e) => setSearchParams({ ...searchParams, search: e.target.value })} />
                {searchParams.search && <div className='absolute top-1/2 -translate-y-1/2 right-3 mt-1'>
                  <button onClick={handleClearSearch}>  <CloseIcon className="w-5 h-5" /></button>
                </div>}
              </div>
              {selectedRows?.length > 0 && <div>
                <React.Fragment>
                  <button className='text-red border border-moon-gray flex items-center py-[10px] px-3 gap-2 rounded-lg leading-[normal] text-base font-medium bg-amour-gray' onClick={handleClickOpen}><Deleteicon />  {selectedRows?.length == 1 ? 'Delete' : 'Delete All'}</button>
                  <Dialog
                    className="deletemodal"
                    fullScreen={fullScreen}
                    open={Open}
                    onClose={handleClickClose}
                    aria-labelledby="responsive-dialog-title"
                  >
                    <button onClick={handleClickClose} className='absolute hidden right-5  md:block'><CloseIcon /></button>
                    <h3 className='text-lg font-bold text-woodsmoke text-center pb-[6px] md:pb-6 md:text-[26px] md:font-semibold'>{selectedRows?.length == 1 ? 'Delete Notification' : 'Delete Notifications'}</h3>
                    <h5 className='text-cloud-gray text-xs text-center font-semibold leading-6 md:text-lg'>Are you sure you want to Delete{selectedRows.length == 1 ? ' this notification' : ' all notifications'} ?</h5>
                    <div className='flex items-center justify-center gap-5 pt-[18px] md:pt-10'>
                      <button className='bg-amour-gray text-camo-green text-sm font-semibold min-w-[143px] py-3 px-10 rounded-lg md:text-lg md:px-12 md:py-4 md:min-w-[177px]' autoFocus onClick={handleClickClose} >
                        Cancel
                      </button>
                      <button onClick={handleOpenClick} autoFocus className='bg-red-orange text-white text-sm  font-semibold min-w-[143px] py-3 
                 rounded-lg md:py-4 md:text-lg md:min-w-[177px]'>
                        Delete
                      </button>
                    </div>
                  </Dialog>
                </React.Fragment>
              </div>}
            </div>
            <div className='hidden flex-1 overflow-auto md:block'>
              <table className="min-w-full">
                <thead>
                  <tr className='text-camo-green'>
                    <th className=" bg-white-lilac text-sm text-start font-semibold py-3 px-4 rounded-tl-lg rounded-l-lg leading-[normal] 
                  whitespace-nowrap" ><Checkbox checked={selectAll} onChange={handleSelectAll} className='!text-[#EDEEEC]' /></th>
                    <th className="bg-white-lilac text-sm text-start font-semibold py-3 px-4 leading-[normal] whitespace-nowrap">Status</th>
                    <th className="bg-white-lilac text-sm text-start font-semibold py-3 px-4 leading-[normal] whitespace-nowrap">Message</th>
                    <th className="bg-white-lilac text-sm text-start font-semibold py-3 px-4 leading-[normal] whitespace-nowrap">Date</th>
                    <th className="bg-white-lilac text-sm text-start font-semibold py-3 px-4 leading-[normal] whitespace-nowrap">Time</th>
                    <th className="bg-white-lilac text-sm text-center font-semibold py-3 px-4 leading-[normal] whitespace-nowrap">Recipients</th>
                    <th className="bg-white-lilac text-sm text-center font-semibold py-3 px-4 rounded-tr-lg leading-[normal] whitespace-nowrap ">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {notification?.length > 0 ? (notification?.map((data, index) => (
                    <tr key={index} className='align-top'>
                      <td className="border-b border-b-amour-gray text-dark-black text-sm font-semibold py-2 px-4 whitespace-nowrap" >
                        <Checkbox checked={selectedRows.includes(data._id)} onChange={(event) => handleSelectRow(event, data._id)} className='!text-[#EDEEEC]' /></td>
                      <td className="border-b border-b-amour-gray text-sm py-2 px-4 whitespace-nowrap">{data?.scheduleStatus == 0 ? (<button className='bg-dark-light-green text-green font-semibold py-[6px] px-5 rounded-[63px]'>Delivered</button>) : (<button className='bg-light-green-gray text-yellow font-semibold py-[6px] px-5 rounded-[63px]'>Pending</button>)}
                      </td>
                      <td className="border-b border-b-amour-gray text-cloud-gray text-sm py-2 px-4 min-w-[200px]">
                        <div className='flex items-center gap-2'>{data.discription}</div>
                      </td>
                      <td className="border-b border-b-amour-gray text-cloud-gray text-sm font-semibold py-2 px-4 whitespace-nowrap">{data.scheduleDate}</td>
                      <td className="border-b border-b-amour-gray text-cloud-gray text-sm font-semibold py-2 px-4 whitespace-nowrap">{data?.scheduleTime}
                      </td>
                      <td className="border-b border-b-amour-gray text-cloud-gray text-sm text-center font-semibold py-2 px-4 whitespace-nowrap">{data?.recipientUser}</td>
                      <td className="border-b border-b-amour-gray text-sm text-center px-4 py-2"><Link href="#" onClick={(e) => handleactionClick(e, data?._id)}><Action className='m-auto w-5' /></Link></td>
                      <Notificationaction anchoractionEl={anchoractionEl} handleactionClose={handleactionClose} data={id} />
                    </tr>
                  ))) : (<td colSpan="8" className='text-center text-sm text-cloud-gray font-semibold pt-4'>
                    Data not found
                  </td>)}
                </tbody>
              </table>
            </div>
            <div className='block h-full md:hidden'>
              <Notificationdetails />
            </div>
            <div className='hidden items-center justify-between pt-4 w-full md:flex'>

              <button className='flex items-center text-cloud-gray text-sm font-semibold gap-2' onClick={currentPage > 1 ? handlePriviousClick : undefined}> <Leftarrow className="w-6" /> PREV</button>

              <ul className='flex items-center'>
                {paginationItems()}
              </ul>

              <button className='flex items-center text-cloud-gray text-sm font-semibold gap-2' onClick={currentPage < totalPages ? handleNextClick : undefined}>NXT <Rightlongarrow className="w-6" /></button>

            </div>
          </div>
        </div>
        <Link to="/notification/addnewnotification" className='w-[50px] h-[50px] bg-camo-green rounded-full flex md:hidden items-center justify-center absolute bottom-20 end-5 addbtnshadow'>
          <Plusicon className="w-5 h-5" />
        </Link>
      </div>
    </>
  )
}
export default Notification;