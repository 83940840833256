import axios from "axios"
import { ADMIN_LOGIN, ADMIN_LOGIN_FAIL, LOADER, } from "./admin.type";


export const adminLogin = (data, navigate) => async (dispatch) => {

    await axios.post(`v1/admin/login`, data)
        .then(async (res) => {
            if (res.data.success) {
                dispatch({ type: ADMIN_LOGIN, payload: res.data.data.user })
                localStorage.setItem('token', res.data.data.token)
                if (data.isCheck) {

                    localStorage.setItem('email', data.email)
                    localStorage.setItem('password', data.password)
                } else {
                    localStorage.setItem('email', '')
                    localStorage.setItem('password', '')
                }
                 navigate("/dashboard")
            } else {

                dispatch({ type: ADMIN_LOGIN_FAIL, payload: res.data.message })
            }

        }).catch((err) => console.log(err))
}