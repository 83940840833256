import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import { Accordion, AccordionSummary } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Admanage, Category, Club, Logoimage, Logout, Marketing, Notification, Privacy, Report, Suport, Tracking, Userimage } from '../../Assest/Allsvg';

const SidebarDrawer = ({ isDrawerOpen, handleDrawerClose }) => {
    const location = useLocation();
    const userManagementRoutes = [
        '/user',
        '/user/addnewuser',
        '/user/userdetails',
        '/user/edituserdetail',
        '/user/userlistdetails',
    ];
    const clubManagementRoutes = [
        '/club',
        '/club/addclub',
        '/club/clubdetail',
        '/club/clublistgrid',
        '/club/editclubdetails',
    ];
    const notificationRoutes = [
        '/notification',
        '/notification/addnewnotification',
    ];
    const feedbackRoutes = [
        '/feedback',
    ];
    const issuereportRoutes = [
        '/issuereport',
    ];
    const supportRoutes = [
        '/supportticket',
    ];
    const isActiveUserManagement = userManagementRoutes.some(route => location.pathname === route);
    const isActiveClubManagement = clubManagementRoutes.some(route => location.pathname === route);
    const isActiveNotification = notificationRoutes.some(route => location.pathname === route);
    const isActivefeedback = feedbackRoutes.some(route => location.pathname === route);
    const isActiveissuereport = issuereportRoutes.some(route => location.pathname === route);
    const isActivesupport = supportRoutes.some(route => location.pathname === route);
    const [isLoading, setLoading] = useState(false);
    let navigate = useNavigate();
    const handleLogut = async (event) => {
        setLoading(true)
        event.preventDefault()
        await localStorage.removeItem('token')
        navigate('/');
        setLoading(false)
    }
    return (
        <>
            <Drawer anchor="left" open={isDrawerOpen} onClose={handleDrawerClose} className='mobile-menu-drawer'>
                <div className="flex flex-col justify-between overflow-auto sidebar_main pt-4 h-full box-border md:min-w-[350px] md:bg-white">
                    <div>
                        <Link to="">
                            <Logoimage className="w-[180px] mx-auto" />
                        </Link>
                        <ul className="sidebar_menu pt-4 pr-[10px]">
                            <li><Link to="/dashboard" className={`flex items-center text-sm font-medium gap-[10px] py-2 pl-5 md:text-lg md:py-3  ${location.pathname === '/dashboard' ? 'border-r-4 border-[#47543C] bg-milk-white font-semibold rounded-tr-lg rounded-br-lg' : 'border-r-4 border-transparent'
                                }`}>
                                <Category className="w-5" /> Dashboard</Link></li>
                            <li><Link to="/user" className={`flex items-center text-sm font-medium py-2 pl-5 gap-[10px] ${isActiveUserManagement ? 'border-r-4 border-[#47543C] bg-milk-white rounded-tr-lg font-semibold rounded-br-lg' : 'border-r-4 border-transparent'
                                }`}> <Userimage className="w-5" /> User Management</Link></li>
                            <li><Link to="/club" className={`flex items-center text-sm font-medium gap-[10px] py-2 pl-5 ${isActiveClubManagement ? 'border-r-4 border-[#47543C] bg-milk-white rounded-tr-lg font-semibold rounded-br-lg' : 'border-r-4 border-transparent'
                                }`}> <Club className="w-5" /> Club Management</Link></li>
                            <li><Link to="/notification" className={`flex items-center text-sm font-medium py-2 pl-5 gap-[10px] 
                    ${isActiveNotification ? 'border-r-4 border-[#47543C] bg-milk-white font-semibold rounded-tr-lg rounded-br-lg' : 'border-r-4 border-transparent'}`}> <Notification className="w-5" />Notification and Alerts</Link></li>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<KeyboardArrowDownIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    className='feedback_menu'
                                >
                                    <Link to="#" className='flex items-center border-l-4 border-transparent font-medium gap-2 py-3 px-5 text-sm md:text-base text-cloud-gray'><Suport className="w-5" /> Support and Feedback</Link>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Link
                                        to="/feedback"
                                        className={`flex items-center font-medium gap-2 py-3 px-5 text-sm md:text-base ${isActivefeedback
                                            ? 'rounded-lg border-l-4 border-[#47543C] bg-milk-white text-camo-green font-semibold'
                                            : 'border-l-4 border-transparent text-cloud-gray'
                                            }`}
                                    >Feedback
                                    </Link>
                                    <Link
                                        to="/issuereport"
                                        className={`flex items-center font-medium gap-2 py-3 px-5 text-sm md:text-base ${isActiveissuereport
                                            ? 'rounded-lg border-l-4 border-[#47543C] bg-milk-white text-camo-green font-semibold'
                                            : 'border-l-4 border-transparent text-cloud-gray'
                                            }`}
                                    > Issues Report
                                    </Link>
                                    <Link
                                        to="/supportticket"
                                        className={`flex items-center font-medium gap-2 py-3 px-5 text-sm md:text-base ${isActivesupport
                                            ? 'rounded-lg border-l-4 border-[#47543C] bg-milk-white text-camo-green font-semibold'
                                            : 'border-l-4 border-transparent text-cloud-gray'
                                            }`}
                                    > Support Ticket
                                    </Link>
                                </AccordionDetails>
                            </Accordion>
                            {/* <li><Link className="flex items-center text-sm font-medium py-2 pl-5 gap-[10px]"> <Marketing className="w-5" /> Marketing Tools</Link></li> */}
                            {/* <li><Link className="flex items-center text-sm font-medium py-2 pl-5 gap-[10px]"> <Privacy className="w-5" /> Security and Privacy</Link></li> */}
                        </ul>
                    </div>
                    <ul>
                        <li><Link onClick={handleLogut} className="flex items-center justify-center border-t border-t-light-dark-green bg-light-green text-sm font-medium py-3 gap-[10px]"> <Logout /> Log Out</Link></li>
                    </ul>
                </div>
            </Drawer>
        </>
    )
}
export default SidebarDrawer;
