import axios from "axios";
import { FIND_DEVELOPER, GET_REPORT_ERROR, GET_REPORT_LIST, GET_REPORT_LIST_FAIL, GET_REPORT_OBJECT, GET_REPORT_OBJECT_BY_ID, REPORT_UPDATE_ERROR } from "./report.type";

// for get list and count of supports
export const getReportList = (data) => async (dispatch) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}v1/issueReport/report_count_forPenal`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            // If the HTTP status code is not successful, throw an error
            throw new Error('Network response was not ok');
        }

        const responseData = await response.json(); // Parse the JSON from the response

        // Check if the operation was successful
        if (responseData.success) {
            if (responseData.data.pageTotal > 0) {
                dispatch({
                    type: GET_REPORT_LIST,
                    payload: {
                        report: responseData.data.report,
                        total: responseData.data.pageTotal,
                        counts: {
                            totalReport: responseData.data.totalReport,
                            totalSolved: responseData.data.totalSolved,
                            totalTechnical: responseData.data.totalTechnical,
                        }
                    }
                });
            } else {
                dispatch({
                    type: GET_REPORT_LIST_FAIL,
                    payload: {
                        report: [],
                        total: 0,
                        counts: {
                            totalReport: responseData.data.totalReport,
                            totalSolved: responseData.data.totalSolved,
                            totalTechnical: responseData.data.totalTechnical,
                        }
                    }
                });
            }
        } else {
            dispatch({ type: GET_REPORT_ERROR });
        }
    } catch (error) {
        console.error("Fetching report count failed:", error);
        dispatch({ type: GET_REPORT_ERROR }); // Dispatch error action if fetching fails
    }

}

// for get report object by id
export const getReportById = (id) => async (dispatch) => {

    await axios.get(`v1/issueReport/specific_report?id=${id}`).then((res) => {
        if (res.data.success) {
            dispatch({ type: GET_REPORT_OBJECT_BY_ID, payload: res.data.data })
        }
    }).catch((error) => console.log(error))
}

// for delete report object
export const deleteReport = (data, navigate) => async (dispatch) => {
    await axios.post(`v1/issueReport/delete_report`, data).then((res) => {
        if (res.data.success) {
            navigate('/issuereport')
        }
    }).catch((err) => console.log(err))
}

// for delete multiple report object
export const deleteMultipleReport = (data, navigate) => async (dispatch) => {
    await axios.post(`v1/issueReport/delete_multiple_report`, data).then((res) => {
        if (res.data.success) {
            navigate('/issuereport')
        }
    }).catch((err) => console.log(err))
}

// find developer for drop down assign task
export const assingDeveloper = () => async (dispatch) => {
    await axios.post(`v1/user/forAssign`).then((res) => {
        if (res.data.success) {
            dispatch({ type: FIND_DEVELOPER, payload: res.data.data })
        }
    }).catch((err) => console.log(err))
}

// for update report by admin 
export const updateReport = (data, id ,navigate) => async (dispatch) => {
    await axios.post(`v1/issueReport/updateReport?id=${id}`, data).then((res) => {
        // debugger
        if (res.data.success) {
            dispatch({ type: GET_REPORT_OBJECT_BY_ID, payload: res.data.data })
            dispatch({ type: REPORT_UPDATE_ERROR, payload: null })
            navigate('/issuereport')
        } else {
            dispatch({ type: REPORT_UPDATE_ERROR, payload: res.data.message })
        }
    }).catch((err) => console.log(err))
}