import React, { useState } from 'react';
import { Togglemenu } from '../../Assest/Allsvg';
import { Link } from 'react-router-dom';
import SidebarDrawer from './SidebarDrawer';
import { useSelector } from 'react-redux';

const Header = ({ title }) => {
    const admin = useSelector((state) => state.admin.admin);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const handleDrawerOpen = () => {
        setIsDrawerOpen(true);
    };
    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    };
    return (
        <div className='flex items-center justify-between border-b border-b-light-gray bg-white p-4 md:p-5'>
            <div className='flex items-center justify-center gap-3' >
                <button className='block lg:hidden' onClick={handleDrawerOpen}><Togglemenu /></button>
                <SidebarDrawer isDrawerOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose} />
                <Link href="" className='text-dark-black text-lg font-bold md:text-xl xl:text-2xl'>{title}</Link>
            </div>
            <div className='flex items-center gap-2 md:ms-auto'>
                <img src={admin?.profile_img} alt="" className='w-8 h-8 rounded-full' />
                <span className='hidden text-black-color font-semibold md:flex'>{admin?.name}</span>
            </div>
        </div>
    )
}
export default Header;