import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Leftangle, Uploadphoto } from '../../Assest/Allsvg';
import { Grid, Switch } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addclub, getCategory, getUser } from '../../store/club/club.action';
import {
    GoogleMap,
    LoadScript,
    Polygon,
    Autocomplete,
} from '@react-google-maps/api';
import Header from '../Header/Header';

const Addclub = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const category = useSelector((state) => state.club.category);
    const user = useSelector((state) => state.club.user);
    const addClubError = useSelector((state) => state.club.addClubError);
    const [name, setName] = useState('');
    const [category_id, setCategory_id] = useState("");
    const [maxCheckInTime, setMaxCheckInTime] = useState('');
    const [about, setAbout] = useState('');
    const [created_by, setCreated_by] = useState("");
    const [club_privacy, setClub_privacy] = useState(false);
    const [global, setGlobal] = useState(true);
    const [imagePreview, setImagePreview] = useState('');
    const [search, setSearch] = useState("");
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const [lat_long, setLat_long] = useState([]);
    const [undoLatLong, setUndoLatLong] = useState([])
    const [image, setImage] = useState(null);
    const [error, setError] = useState({});
    const fileInputRef = useRef(null);
    const [autocomplete, setAutocomplete] = useState(null);
    const [map, setMap] = useState(null);
    useEffect(() => {
        dispatch(getCategory())
        dispatch(getUser({ search }))
    }, [search, dispatch])
    useEffect(() => {
        if (category && category.length > 0) {
            setCategory_id(category[0]._id);
        }
        if (user && user.length > 0) {
            setCreated_by(user[0]._id);
        }
    }, [category, user]);
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file)
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const mapContainerStyle = {
        height: "400px",
        width: "100%"
    };
    const handleClickMap = (e) => {
        const newCoord = [e.latLng.lng(), e.latLng.lat(),];
        setLat_long(prevState => [...prevState, newCoord]);
        if (lat == 0 && lng == 0) {
            setLat(e.latLng.lat())
            setLng(e.latLng.lng())
        }
    }

    const undoCoordinates = () => {
        const lastLatLng = lat_long[lat_long.length - 1];

        // Remove the last coordinate from lat_long
        setLat_long(prevState => prevState.slice(0, -1));

        // Add the last coordinate to undo list if it exists
        if (lastLatLng) {
            setUndoLatLong(pre => [...pre, lastLatLng]);
        }
    };

    const addUndoCoordinates = () => {
        // Check if there is anything in the undoLatLong array
        if (undoLatLong.length > 0) {
            const lastUndoLatLng = undoLatLong[undoLatLong.length - 1];

            // Add the last undoLatLong coordinate back to lat_long
            setLat_long(prevState => [...prevState, lastUndoLatLng]);

            // Remove the last coordinate from undoLatLong
            setUndoLatLong(pre => pre.slice(0, -1));
        }
    };

    const center = useMemo(() => ({ lat: -34.397, lng: 150.644 }), [])
    const handleSubmit = () => {
        const obj = {}
        if (!name) {
            obj.name = 'Please enter name'
        }
        if (!about) {
            obj.about = 'Please enter description'
        }
        if (!image) {
            obj.image = "Please add club profile"
        }
        if (!category_id) {
            obj.category_id = "Please add club category"
        }
        if (!created_by) {
            obj.created_by = "Please add club owner"
        }
        // if (global) {
        if (Object.keys(lat_long).length === 0) {
            obj.lat_long = 'Please select club area';
        }
        // }
        setError(obj)
        if (Object.keys(obj).length == 0) {
            const formdata = new FormData()
            const Global = 0
            const Privacy = club_privacy ? 1 : 0
            const closedPolygon = lat_long.length > 2 && lat_long[0] !== lat_long[lat_long.length - 1]
                ? [...lat_long, lat_long[0]]
                : lat_long;
            formdata.append("name", name)
            formdata.append("category_id", category_id)
            formdata.append("maxCheckInTime", maxCheckInTime)
            formdata.append("about", about)
            formdata.append("created_by", created_by)
            formdata.append("club_privacy", Privacy)
            formdata.append("long", lng)
            formdata.append("lat", lat)
            formdata.append("global", Global)
            formdata.append("lat_long", JSON.stringify(closedPolygon));
            formdata.append("club_profile", image)
            dispatch(addclub(formdata, navigate))
        }
    }

    const onPlaceChanged = () => {
        try {
            if (autocomplete !== null) {
                const place = autocomplete.getPlace();
                const location = place.geometry.location;
                const newCenter = {
                    lat: location.lat(),
                    lng: location.lng(),
                };
                map.panTo(newCenter);
            } else {
                throw new Error('Autocomplete is not loaded yet!');
            }
        } catch (error) {
            setError({ lat_long: error.message })
        }

    };
    return (
        <>
            <Header title="Club Management" />
            <div className='flex flex-col p-4 sm:px-5 sm:py-6 h-[calc(100%_-_65px)] md:h-[calc(100%_-_85px)]'>
                <div className='flex flex-col flex-1 overflow-auto bg-white py-4 sm:py-5  rounded-[10px]  md:py-0 md:bg-transparent'>
                    <h4 className='flex items-center justify-start text-dark-black text-sm font-bold px-5 pb-5 gap-3
                    md:px-0  md:text-xl'> <Link href="" to="/club" className="hidden md:block"><Leftangle className="w-5" />
                        </Link> | Add Club</h4>
                    <div className='flex flex-col overflow-auto h-full rounded-[10px] px-4 md:px-5 md:py-4 md:bg-white'>
                        <div className='flex flex-col items-center bg-light-gray rounded-xl gap-4 py-5 mb-4 md:bg-transparent 
                        md:flex-row md:mb-0 md:pb-6'>
                            <div className='flex items-center justify-center bg-amour-gray rounded-full md:w-[120px] md:h-[120px] ' onClick={() => fileInputRef.current.click()}>
                                {imagePreview && <img src={imagePreview} alt="Preview" className='w-full h-full rounded-full' />}
                                {!imagePreview && <Uploadphoto />}
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleImageChange}
                                />
                            </div>
                            <h5 className='text-sm font-semibold md:text-lg'>Upload Picture</h5>
                        </div>
                        {error && <p className='text-red text-sm'>{error.image}</p>}
                        <div>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={4}>
                                    <div className='flex flex-col gap-2'>
                                        <label className='block text-dark-black text-xs font-semibold md:text-sm'>Name</label>
                                        <input type="text" placeholder='Enter club name' value={name} onChange={(e) => setName(e.target.value)} className='border border-spring-rain 
                                                text-xs py-2 pl-3 rounded-lg leading-none min-h-10 w-full placeholder:text-cloud-gray placeholder:font-medium md:text-sm'/>
                                        {error && <p className='text-red text-sm'>{error.name}</p>}
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <div className='flex flex-col gap-2'>
                                        <label className='block text-dark-black text-xs md:text-sm font-semibold'>Category</label>
                                        <select value={category_id} onChange={(e) => setCategory_id(e.target.value)} className="border border-spring-rain text-xs py-2 pl-3 rounded-lg leading-none   min-h-10 md:text-sm"  >
                                            {
                                                category?.map((data, index) => (
                                                    <option key={index} value={data?._id}>{data?.name} </option>
                                                ))
                                            }
                                        </select>
                                        {error && <p className='text-red text-sm'>{error.category_id}</p>}
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <div className='flex flex-col gap-2'>
                                        <label className='block text-dark-black text-xs font-semibold md:text-sm'>Max Check In Time (optional)</label>
                                        <input type="time" className='border border-spring-rain text-xs py-2 px-4 rounded-lg leading-none md:text-sm' value={maxCheckInTime} onChange={(e) => setMaxCheckInTime(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <div className='flex flex-col gap-2'>
                                        <label className='block text-dark-black text-xs font-semibold md:text-sm'>Description</label>
                                        <textarea placeholder='Enter text here...' value={about} onChange={(e) => setAbout(e.target.value)} className='border border-spring-rain text-xs py-2 pl-3 rounded-lg leading-none placeholder:text-cloud-gray placeholder:font-medium min-h-10 w-full md:text-sm' name="" id="" cols='10' rows='5'></textarea>
                                        {error && <p className='text-red text-sm'>{error.about}</p>}
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <div className='flex  flex-col gap-2'>
                                        <label className='block text-dark-black text-xs font-semibold md:text-sm'>Club owner</label>
                                        <select className="border border-spring-rain text-xs py-2 pl-3 rounded-lg leading-none   min-h-10 md:text-sm" value={created_by} onChange={(e) => setCreated_by(e.target.value)} >
                                            {
                                                user?.map((data, index) => (
                                                    <option key={index} value={data?._id}>{data?.name}</option>
                                                ))
                                            }
                                        </select>
                                        {error && <p className='text-red text-sm'>{error.created_by}</p>}
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <div className='flex justify-between items-center'>
                                        <div className='flex flex-col gap-[10px]'>
                                            <label className='block text-dark-black font-semibold'>Club Privacy</label>
                                            <span className='text-dark-black text-base font-medium leading-[normal]'>Private</span>
                                            <p className=' text-cloud-gray text-sm font-medium leading-[normal]'>Only visible to those you share with</p>
                                        </div>
                                        <Switch checked={club_privacy} value={club_privacy} onChange={() => setClub_privacy(!club_privacy)} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <div className='flex flex-col gap-7 pb-0 xl:pb-7'>
                                        <div className='flex justify-between items-center'>
                                            {/* <div className='flex flex-col gap-[10px]'>
                                                <label className='block text-dark-black font-semibold'>Allowed Property Lines</label>
                                                <span className='text-dark-black text-base font-medium leading-[normal]'>Global</span>
                                                <p className='text-cloud-gray text-sm font-medium leading-[normal]'>Allow members to draw property line within</p>
                                            </div>
                                            <Switch checked={global} value={global} onChange={() => setGlobal(!global)} /> */}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={8}>
                                    <div className='flex flex-col gap-2 xl:mt-[-70px]'>
                                        {global && (
                                            <div style={mapContainerStyle}>
                                                <LoadScript
                                                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                                    libraries={["places"]}
                                                >
                                                    <GoogleMap
                                                        mapContainerStyle={mapContainerStyle}
                                                        center={center}
                                                        zoom={10}
                                                        onClick={handleClickMap}
                                                        onLoad={(mapInstance) => setMap(mapInstance)}
                                                    >
                                                        {/* Search Bar using Autocomplete */}
                                                        <div style={{
                                                            position: 'absolute',
                                                            top: '10px',
                                                            left: '50%',
                                                            transform: 'translateX(-50%)',
                                                            zIndex: '1',
                                                            display: 'flex',
                                                            // flexDirection: 'column',
                                                            alignItems: 'center',
                                                            textAlign: 'center'
                                                        }}>
                                                            {/* Search Bar with Icon */}
                                                            <div style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                                                                borderRadius: '5px',
                                                                backgroundColor: '#fff',
                                                                marginBottom: '10px', // Space between the search bar and the second icon
                                                            }}>
                                                                <svg
                                                                    width="22"
                                                                    height="22"
                                                                    className='ml-2'
                                                                    viewBox="0 0 22 22"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    style={{
                                                                        marginRight: '8px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    <path d="M10.5413 19.2497C15.3508 19.2497 19.2497 15.3508 19.2497 10.5413C19.2497 5.73186 15.3508 1.83301 10.5413 1.83301C5.73186 1.83301 1.83301 5.73186 1.83301 10.5413C1.83301 15.3508 5.73186 19.2497 10.5413 19.2497Z" stroke="#686868" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M20.1663 20.1663L18.333 18.333" stroke="#686868" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                                <Autocomplete
                                                                    onLoad={(autocompleteInstance) => setAutocomplete(autocompleteInstance)}
                                                                    onPlaceChanged={onPlaceChanged}
                                                                >
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Search for a location"
                                                                        style={{
                                                                            boxSizing: 'border-box',
                                                                            border: '1px solid transparent',
                                                                            width: '240px',
                                                                            height: '32px',
                                                                            fontSize: '14px',
                                                                            outline: 'none',
                                                                            textOverflow: 'ellipsis',
                                                                            borderRadius: '3px 0 0 3px',
                                                                        }}
                                                                    />
                                                                </Autocomplete>
                                                            </div>

                                                            {lat_long?.length > 0 &&
                                                                <>
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        width: '30px',
                                                                        height: '32px',
                                                                        borderRadius: '5px',
                                                                        backgroundColor: '#fff',
                                                                        marginBottom: '10px',
                                                                        marginLeft: '10px',
                                                                    }}>
                                                                        <button type='button' onClick={undoCoordinates}>

                                                                            <svg width="18" height="18" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M9.00005 3.58974V0L3.87185 5.12821L9.00005 10.2564V5.64103C12.3847 5.64103 15.1539 8.41026 15.1539 11.7949C15.1539 15.1795 12.3847 17.9487 9.00005 17.9487C5.61543 17.9487 2.8462 15.1795 2.8462 11.7949H0.794922C0.794922 16.3077 4.48723 20 9.00005 20C13.5129 20 17.2052 16.3077 17.2052 11.7949C17.2052 7.28205 13.5129 3.58974 9.00005 3.58974Z" fill="#363636" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        width: '30px',
                                                                        height: '32px',
                                                                        borderRadius: '5px',
                                                                        backgroundColor: '#fff',
                                                                        marginBottom: '10px',
                                                                        marginLeft: '10px',
                                                                    }}>
                                                                        <button type='button' onClick={addUndoCoordinates}>

                                                                            <svg width="18" height="18" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M8.99995 3.58974V0L14.1282 5.12821L8.99995 10.2564V5.64103C5.61533 5.64103 2.8461 8.41026 2.8461 11.7949C2.8461 15.1795 5.61533 17.9487 8.99995 17.9487C12.3846 17.9487 15.1538 15.1795 15.1538 11.7949H17.2051C17.2051 16.3077 13.5128 20 8.99995 20C4.48713 20 0.794821 16.3077 0.794821 11.7949C0.794821 7.28205 4.48713 3.58974 8.99995 3.58974Z" fill="#363636" />
                                                                            </svg>

                                                                        </button>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                        {/* Polygon rendering based on lat_long array */}
                                                        {lat_long.length > 0 && (
                                                            <Polygon
                                                                paths={lat_long.map((coord) => ({ lng: coord[0], lat: coord[1] }))}
                                                                options={{
                                                                    fillColor: 'lightblue',
                                                                    fillOpacity: 0.5,
                                                                    strokeColor: 'red',
                                                                    strokeOpacity: 1,
                                                                    strokeWeight: 2,
                                                                }}
                                                            />
                                                        )}
                                                    </GoogleMap>
                                                </LoadScript>
                                            </div>
                                        )}
                                        {global && error && <p className='text-red text-sm'>{error.lat_long}</p>}
                                        <span className='text-[red]'>{addClubError}</span>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <div className='flex items-center justify-center gap-4 sm:gap-7 pt-4'>
                                        <Link href='#' to='/club' className='border border-camo-green text-camo-green text-sm 
                                    text-center font-semibold py-3 box-border rounded-lg min-w-[120px] sm:min-w-[50%]  leading-[100%]  md:text-base md:min-w-[180px]'>
                                            Cancel</Link>
                                        <Link onClick={handleSubmit} className='border border-transparent bg-camo-green text-white text-sm 
                                    text-center font-semibold py-3 box-border rounded-lg min-w-[120px] sm:min-w-[50%] leading-[100%] md:text-base md:min-w-[180px]'>
                                            Add</Link>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export default Addclub;