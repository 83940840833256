import React, { useEffect, useState } from 'react';
import { Checkbox, Dialog, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { CrossIcon, FilterIcon, Leftarrow, Listmenu, Rightlongarrow, Search, Action, Deleteicon, CloseIcon, Plusicon } from '../../Assest/Allsvg';
import { deleteMultipleClub, getClubList } from '../../store/club/club.action';
import Clubaction from '../Clubaction/Clubaction';
import Clubfilter from '../Clubfilter/Clubfilter';
import clubProfile from '../../Assest/clubDefaultImage.png';
import Clublistgrid from '../Clublistgrid/Clublistgrid';
import { CLEAR_FILTERS, DELETE_MULTIPLE_CLUB } from '../../store/club/club.type';
import Header from '../Header/Header';

const ClubManagement = () => {
  const [Id, setId] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const club = useSelector((state) => state.club.club);
  const total = useSelector((state) => state.club.totalPage);
  const [searchParms, setSearchParams] = useState({
    sort: 0,
    search: '',
    page: 1,
    limit: 10,
    startDate: 'ALL',
    endDate: 'ALL'
  })
  const filterData = useSelector((state) => state.club.filterData)
  useEffect(() => {
    if (location.pathname === '/club') {
      const defaultState = {
        sort: 3,
        startDate: 'ALL',
        endDate: 'ALL',

      };
      dispatch({ type: CLEAR_FILTERS, payload: defaultState });
    }
  }, [location.pathname, dispatch]);
  // filter modal
  useEffect(() => {
    if (filterData) {
      setSearchParams((prevParams) => ({
        ...prevParams,
        sort: filterData.sort,
        startDate: filterData.startDate,
        endDate: filterData.endDate
      }))
    }
  }, [filterData])
  useEffect(() => {
    dispatch(getClubList(searchParms))
  }, [searchParms, dispatch]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // action popover
  const [anchoractionEl, setactionanchorEl] = React.useState(null);
  const handleactionClick = (event, data) => {
    setactionanchorEl(event.currentTarget);
    setId(data)
  };
  const handleactionClose = () => {
    setactionanchorEl(null);
  };
  // pagination
  const totalPages = Math.ceil(total / searchParms.limit);
  const [currentPage, setCurrentPage] = useState(1);
  const paginationItems = () => {
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
      if (number === 1 || number === totalPages || (number >= currentPage - 1 && number <= currentPage + 1)) {
        items.push(
          <li key={number}
            className={`p-2 ${number === currentPage ? 'bg-amour-gray' : 'text-cloud-gray'} font-semibold rounded w-8 h-8 flex items-center justify-center`}
            onClick={() => handleSelectPage(number)}>
            {number}
          </li>
        );
      } else if (number === currentPage - 2 || number === currentPage + 2) {
        if (items[items.length - 1].key !== '...') { // To avoid consecutive "..."
          items.push(
            <li key={'...' + number} className='p-2 text-cloud-gray font-semibold rounded w-8 h-8 flex items-center justify-center'>...</li>
          );
        }
      }
    }
    return items;
  };
  const handleSelectPage = (number) => {
    setSearchParams(prevParams => ({
      ...prevParams,
      page: number
    }));
  };
  // next button
  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setSearchParams(prevParams => ({
        ...prevParams,
        page: prevParams.page + 1
      }));
    }
  };
  // prev button
  const handlePriviousClick = () => {
    if (currentPage > 1) {
      setSearchParams(prevParams => ({
        ...prevParams,
        page: prevParams.page - 1
      }));
    }
  };

  useEffect(() => {
    setCurrentPage(searchParms.page);
  }, [searchParms.page]);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    const selectedData = checked ? club.map((item) => item._id) : [];
    setSelectedRows(selectedData);
  };
  const handleSelectRow = (event, id) => {
    const checked = event.target.checked;
    if (checked) {
      setSelectedRows((prevSelected) => [...prevSelected, id]);
    } else {
      setSelectedRows((prevSelected) => prevSelected.filter((rowId) => rowId !== id));
    }
  };
  const handleClearSearch = () => {
    setSearchParams(prevParams => ({
      ...prevParams,
      search: ''
    }))
  }
  const handleDeleteMultiple = () => {
    const data = {
      club_id: selectedRows
    }
    dispatch({ type: DELETE_MULTIPLE_CLUB, payload: selectedRows })
    dispatch(deleteMultipleClub(data, navigate))
    setSelectedRows([])
  }
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [Open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };
  const handleOpenClick = () => {
    handleClickClose()
    handleDeleteMultiple()
  }
  return (
    <>
      <Header title="Club Management" />
      <div className='relative h-[calc(100%_-_73px)]'>
        <div className='flex flex-col p-4 sm:py-6 sm:px-5 h-full'>
          <div className='hidden items-center justify-between pb-5 md:flex'>
            <h4 className='text-dark-black text-xl font-bold'>| Club List</h4>
            <div className='flex items-center'>
              <Link to="/club/clublistgrid" className={`border border-moon-gray bg-white p-[10px] mr-2 rounded-lg  ${location.pathname === '/club/clublistgrid'}`}><CrossIcon className="w-5 h-5" /></Link>
              <Link to="/club" className={` p-[10px] mr-3 rounded-lg  ${location.pathname === '/club' ? 'bg-camo-green listmenuicon' : ''}`}><Listmenu className="w-5 h-5" /></Link>
              <Link to="/club/addclub" className='bg-camo-green text-white text-sm font-semibold py-[10px] px-8 rounded-lg'>+ Add New Club</Link>
            </div>
          </div>
          <div className="flex flex-col flex-1 overflow-x-auto rounded-lg md:bg-white md:p-5">
            <div className='hidden items-center justify-between text-lg pb-5 md:flex'>
              <div className='relative flex'>
                <div className='absolute top-1/2 left-4 -translate-y-1/2'>
                  <Search className="w-5" />
                </div>
                <input type="text" placeholder='Search name, created by...' value={searchParms?.search} onChange={(e) => setSearchParams({ ...searchParms, search: e.target.value })} className='border border-moon-gray text-sm font-medium py-[10px] pl-11 rounded-lg min-w-[420px] placeholder:text-cloud-gray' />
                {searchParms.search && <div className='absolute top-1/2 -translate-y-1/2 right-3 mt-1'>
                  <button onClick={handleClearSearch}>  <CloseIcon className="w-5 h-5" /></button>
                </div>}
              </div>
              <div className='flex items-center relative gap-2'>
                {selectedRows?.length == 0 ?
                  <div className='relative'>
                    <Link href="#" onClick={handleClick} className='flex  items-center border border-moon-gray text-dark-black text-base font-medium
                     py-[10px] px-3 gap-2 rounded-lg leading-[normal] '> <FilterIcon className="w-5 h-5" /> Filters</Link>
                    <Clubfilter anchorEl={anchorEl} handleClose={handleClose} />
                  </div> :
                  <div>
                    <React.Fragment>
                      <button className='text-red border border-moon-gray flex items-center py-[10px] px-3 gap-2 rounded-lg leading-[normal] text-base font-medium bg-amour-gray' onClick={handleClickOpen}><Deleteicon className="w-5 h-5" /> {selectedRows?.length == 1 ? 'Delete' : 'Delete All'}</button>
                      <Dialog
                        className="deletemodal"
                        fullScreen={fullScreen}
                        open={Open}
                        onClose={handleClickClose}
                        aria-labelledby="responsive-dialog-title"
                      >
                        <button onClick={handleClickClose} className='absolute hidden right-5  md:block'><CloseIcon /></button>
                        <h3 className='text-lg font-bold text-woodsmoke text-center pb-[6px] md:pb-6 md:text-[26px] md:font-semibold'>{selectedRows?.length == 1 ? 'Delete Club' : 'Delete Clubs'}</h3>
                        <h5 className='text-cloud-gray text-xs text-center font-semibold leading-6 md:text-lg'>Are you sure you want to Delete {selectedRows?.length == 1 ? ' this club' : ' all clubs'} ?</h5>
                        <div className='flex items-center justify-center gap-5 pt-[18px] md:pt-10'>
                          <button className='bg-amour-gray text-camo-green text-sm font-semibold min-w-[143px] py-3 px-10 rounded-lg md:text-lg md:px-12 md:py-4 md:min-w-[177px]' autoFocus onClick={handleClickClose} >
                            Cancel
                          </button>
                          <button onClick={handleOpenClick} autoFocus className='bg-red-orange text-white text-sm  font-semibold min-w-[143px] py-3 
                 rounded-lg md:py-4 md:text-lg md:min-w-[177px]'>
                            Delete
                          </button>
                        </div>
                      </Dialog>
                    </React.Fragment>
                  </div>}
              </div>
            </div>
            <div className='flex-1 overflow-auto'>
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="bg-white-lilac text-sm font-semibold text-start py-3 px-4 rounded-lg leading-normal" ><Checkbox checked={selectAll} onChange={handleSelectAll} className='!text-[#EDEEEC]' /></th>
                    <th className="bg-white-lilac text-camo-green text-start text-sm font-semibold py-3 px-4 leading-normal">Name</th>
                    <th className="bg-white-lilac text-camo-green text-start text-sm font-semibold py-3 px-4 leading-normal">Members</th>
                    <th className="bg-white-lilac text-camo-green text-start text-sm font-semibold py-3 px-4 leading-normal">Kills</th>
                    <th className="bg-white-lilac text-camo-green text-start text-sm font-semibold py-3 px-4 leading-normal">Created at</th>
                    <th className="bg-white-lilac text-camo-green text-start text-sm font-semibold py-3 px-4 leading-normal">Created by</th>
                    {/* <th className="bg-white-lilac text-camo-green text-start text-sm font-semibold py-3 px-4 leading-normal">Object</th> */}
                    <th className="bg-white-lilac text-camo-green text-sm font-semibold text-center py-3 px-4 rounded-tr-lg leading-normal">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {club?.length > 0 ? club?.map((item, index) => (
                    <tr key={index}>
                      <td className="border-b border-b-amour-gray text-dark-black font-semibold py-2 px-4" ><Checkbox checked={selectedRows.includes(item._id)} onChange={(event) => handleSelectRow(event, item._id)} className='!text-[#EDEEEC]' /></td>
                      <td className="border-b border-b-amour-gray text-dark-black text-sm font-semibold py-2 px-4"><div className='flex items-center gap-2'><img src={item.club_profile || clubProfile} alt="" className='w-8 h-8 rounded-full object-cover' />{item.name}</div></td>
                      <td className="border-b border-b-amour-gray text-dark-black text-sm font-semibold py-2 px-4">{item.member}</td>
                      <td className="border-b border-b-amour-gray text-dark-black text-sm font-semibold py-2 px-4">{item.kill || 0}</td>
                      <td className="border-b border-b-amour-gray text-cloud-gray text-sm font-semibold py-2 px-4">{moment(item.createdAt).format("DD MMM, YYYY")}</td>
                      <td className="border-b border-b-amour-gray text-cloud-gray text-sm font-semibold py-2 px-4">{item.createdBy}</td>
                      <td className="border-b border-b-amour-gray text-sm text-center px-4 py-2">
                      <Link href="#" onClick={(e) => handleactionClick(e, item?._id)}><Action className='m-auto w-5' /></Link></td>
                      <Clubaction anchoractionEl={anchoractionEl} handleactionClose={handleactionClose} data={Id} />
                    </tr>
                  )) : (<tr>
                    <td colSpan="12" className='text-sm text-cloud-gray font-semibold text-center pt-4'>
                      Data not found
                    </td>
                  </tr>)}
                </tbody>
              </table>
            </div>
            <div className='block h-full md:hidden'>
              <Clublistgrid />
            </div>
            <div className='hidden items-center justify-between pt-4 w-full md:flex'>

              <button className='flex items-center text-cloud-gray text-sm font-semibold gap-2' onClick={currentPage > 1 ? handlePriviousClick : undefined}> <Leftarrow className="w-6" /> PREV</button>

              <ul className='flex items-center'>
                {paginationItems()}
              </ul>

              <button className='flex items-center text-cloud-gray text-sm font-semibold gap-2' onClick={currentPage < totalPages ? handleNextClick : undefined}>NXT <Rightlongarrow className="w-6" /></button>

            </div>
          </div>
        </div>
        <Link to="/club/addclub" className='w-[50px] h-[50px] bg-camo-green rounded-full flex md:hidden items-center justify-center absolute bottom-20 end-5 addbtnshadow'>
          <Plusicon className="w-5 h-5" />
        </Link>
      </div>
    </>
  )
}
export default ClubManagement;