import { CLEAR_FILTERS_USER, DELETE_MULTIPLE_USER, DELETE_USER, FILTER_DATA_USER, GET_USER_BY_ID, GET_USER_LIST, GET_USER_LIST_FAIL, GET_USER_OBJECT, LOADER_CLOSE, LOADER_START, UPDATE_USER } from "./user.type";

const initalState = {
    user: [],
    filterData: null,
    totalPage: 0,
    userObject: null,
    updateUser: null,
    isLoading: false
}

const userReducer = (state = initalState, action) => {
    switch (action.type) {
        case GET_USER_LIST:
            return {
                ...state,
                user: action.payload.user, // Assuming the payload contains the user data
                totalPage: action.payload.total
            };

        case GET_USER_LIST_FAIL:
            return {
                ...state,
                user: action.payload, // Assuming the payload contains the user data
                totalPage: action.payload
            };

        case FILTER_DATA_USER:
            {
                return {
                    ...state,
                    filterData: action.payload
                }
            }
        case CLEAR_FILTERS_USER:
            {
                return {
                    ...state,
                    filterData: action.payload
                }
            }
        case GET_USER_OBJECT:

            const userObject = state.user.find(users => users?._id === action.payload.id);
            return {
                ...state,
                userObject: userObject
            };

        case GET_USER_BY_ID:
            return {
                ...state,
                userObject: action.payload

            }
        case DELETE_USER:
            const user = state.user.filter((user) => user?._id !== action.payload.id)
            return {
                ...state,
                user: user
            }
        case UPDATE_USER:
            const Object = state.user.find(users => users?._id === action.payload.id);
            return {
                ...state,
                updateUser: Object
            };
        case LOADER_START:

            return {
                ...state,
                isLoading: true
            }
        case LOADER_CLOSE:
            return {
                ...state,
                isLoading: false
            }
        case DELETE_MULTIPLE_USER: {
            const AllData = state.user.filter(user => !action.payload.includes(user._id))
            return {
                ...state,
                user: AllData,
                totalPage: AllData.length
            }
        }

        default:
            return state
    }
}

export default userReducer