import React from 'react';
import { Popover, useMediaQuery, useTheme } from '@mui/material';
import { Eyeicon, Deleteicon, CloseIcon } from '../../Assest/Allsvg';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dialog } from '@mui/material';
import { DELETE_REPORT_OBJECT, GET_REPORT_OBJECT } from '../../store/report/report.type';
import { deleteReport } from '../../store/report/report.action';

const Reportaction = ({ anchoractionEl, handleactionClose, data }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const handleView = () => {
    dispatch({ type: GET_REPORT_OBJECT, payload: { id: data } })
    navigate('/issuereport/reportdetail')
  }
  const handleDelete = () => {
    dispatch({ type: DELETE_REPORT_OBJECT, payload: { id: data } })
    dispatch(deleteReport({ id: data }, navigate));
  }
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const handleClickOpen = () => {
    setOpenDeleteDialog(true);
  };
  const handleClickClose = () => {
    setOpenDeleteDialog(false);
    handleactionClose();
  };
  const handleConfirmDelete = () => {
    handleactionClose();
    handleDelete();
  }
  const open = Boolean(anchoractionEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <Popover
        className='action-popover'
        id={id}
        open={Boolean(anchoractionEl)}
        anchorEl={anchoractionEl}
        onClose={handleactionClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className='flex flex-col gap-2'>
          <button className='flex items-center gap-3 text-dark-black font-medium' onClick={handleView}><Eyeicon /> View</button>
          <React.Fragment>
            <button className='flex items-center gap-3 text-dark-black font-medium' onClick={handleClickOpen}><Deleteicon /> Delete</button>
            <Dialog
              className="deletemodal"
              fullScreen={fullScreen}
              open={openDeleteDialog}
              onClose={handleClickClose}
              aria-labelledby="responsive-dialog-title"
            >
              <button onClick={handleClickClose} className='absolute right-5 hidden md:block'><CloseIcon /></button>
              <h3 className='text-lg md:text-[26px] font-bold md:font-semibold text-woodsmoke text-center pb-[6px] md:pb-6'>Delete Report</h3>
              <h5 className='text-cloud-gray text-xs text-center md:text-lg font-semibold leading-6'>Are you sure you want to Delete this report ?</h5>
              <div className='pt-[18px] md:pt-10 flex items-center justify-center gap-5'>
                <button autoFocus onClick={handleClickClose} className='bg-amour-gray text-camo-green text-sm md:text-lg font-semibold min-w-[143px] md:min-w-[177px] py-3 md:py-4 px-10 md:px-12 rounded-lg'>
                  Cancel
                </button>
                <button onClick={handleConfirmDelete} autoFocus className='bg-red-orange text-white text-sm md:text-lg font-semibold min-w-[143px] md:min-w-[177px] py-3 md:py-4 rounded-lg'>
                  Delete
                </button>
              </div>
            </Dialog>
          </React.Fragment>
        </div>
      </Popover>
    </>
  )
}
export default Reportaction;