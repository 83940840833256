import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Action, CrossIcon, FilterIcon, Leftarrow, Listmenu, Rightlongarrow, Search } from '../../Assest/Allsvg';
import { getNotificationList } from '../../store/notification/notification.action';
import Filtermodal from '../Filtermodal/Filtermodal';
import Header from '../Header/Header';
import Notificationaction from '../Notificationaction/Notificationaction';

const Notificationdetails = () => {
    const [searchParams, setSearchParams] = useState({
        page: 1,
        limit: 10,
        search: '',
    });
    const dispatch = useDispatch()
    const notification = useSelector((state) => state.notification.notification)
    const totalNumber = useSelector((state) => state.notification.totalPage)
    useEffect(() => {
        dispatch(getNotificationList(searchParams));
    }, [searchParams, dispatch]);
    const totalPages = Math.ceil(totalNumber / searchParams.limit)
    const [currentPage, setCurrentPage] = useState(1);
    const paginationItems = () => {
        let items = [];
        for (let number = 1; number <= totalPages; number++) {
            if (number === 1 || number === totalPages || (number >= currentPage - 1 && number <= currentPage + 1)) {
                items.push(
                    <li key={number}
                        className={`p-2 ${number === currentPage ? 'bg-amour-gray' : 'text-cloud-gray'} font-semibold rounded w-8 h-8 flex items-center justify-center`}
                        onClick={() => handleSelectPage(number)}>
                        {number}
                    </li>
                );
            } else if (number === currentPage - 2 || number === currentPage + 2) {
                if (items[items.length - 1].key !== '...') { // To avoid consecutive "..."
                    items.push(
                        <li key={'...' + number} className='p-2 text-cloud-gray font-semibold rounded w-8 h-8 flex items-center justify-center'>...</li>
                    );
                }
            }
        }
        return items;
    };
    const handleNextClick = () => {
        if (currentPage < totalPages) {
            setSearchParams(prevParams => ({
                ...prevParams,
                page: prevParams.page + 1
            }));
        }
    };
    const handlePriviousClick = () => {
        if (currentPage > 1) {
            setSearchParams(prevParams => ({
                ...prevParams,
                page: prevParams.page - 1
            }));
        }
    };
    const handleSelectPage = (number) => {
        setSearchParams(prevParams => ({
            ...prevParams,
            page: number
        }));
    };
    useEffect(() => {
        setCurrentPage(searchParams.page);
    }, [searchParams.page]);
    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const handleFilterClick = (event) => {
        setFilterAnchorEl(event.currentTarget);
    };
    const handleFilterClose = () => {
        setFilterAnchorEl(null);
    };
    const location = useLocation();
    // action popover
    const [Id, setId] = useState('');
    const [anchoractionEl, setactionanchorEl] = React.useState(null);
    const handleactionClick = (event, data) => {
        setactionanchorEl(event.currentTarget);
        setId(data)
    };
    const handleactionClose = () => {
        setactionanchorEl(null);
    };
    return (
        <>
            <div className='hidden md:block'>
                <Header title="User Management" />
            </div>
            <div className='flex flex-col h-full md:pb-6 md:px-5 md:h-[calc(100%_-_106px)]'>
                <div className='flex items-center justify-between md:pt-6'>
                    <h4 className='text-dark-black text-base sm:text-xl font-bold'>| Alerts Notification</h4>
                    <div className='hidden items-center md:flex'>
                        <Link href="#" to="/user/userlistdetails" className={`border border-moon-gray p-[10px] mr-2 rounded-lg 
                         ${location.pathname === '/usermanagementpage/userlistdetails' ? 'bg-camo-green listmenuicon' : 'bg-white'}`}><CrossIcon className="w-5 h-5" /></Link>
                        <Link href="#" to="/user" className={`border border-moon-gray p-[10px] mr-3 rounded-lg 
                        bg-white ${location.pathname === 'usermanagementpage/usermanagement' ? 'bg-camo-green' : ''}`}><Listmenu className="w-5 h-5" /></Link>
                        <Link href="#" to="/user/addnewuser" className='bg-camo-green text-sm text-white font-semibold py-[10px] px-8 rounded-lg'>+ Add New User</Link>
                    </div>
                </div>
                <div className="flex flex-col overflow-x-auto bg-white pt-4 rounded-lg md:pt-5 md:pb-6 md:mt-5 md:px-5">
                    <div className='flex items-center justify-between text-lg pb-5'>
                        <div className='flex relative w-full'>
                            <div className='absolute top-1/2 left-4 -translate-y-1/2'>
                                <Search className="w-4 sm:w-5" />
                            </div>
                            <input type="text" placeholder='Search name, mail ...' value={searchParams.search} onChange={(e) => setSearchParams({ ...searchParams, search: e.target.value })} className='border border-moon-gray rounded-lg text-sm font-medium 2xl:min-w-[420px] pl-11 py-[10px] placeholder:text-cloud-gray w-full md:w-auto sm:min-w-[420px]' />
                        </div>
                        <div className='hidden md:block'>
                            <Link href="#" onClick={handleFilterClick} className='flex items-center border border-moon-gray text-dark-black 
                            text-base font-medium py-[10px] px-3 gap-2 rounded-lg leading-[normal]'> <FilterIcon className="w-5 h-5" /> Filters</Link>
                            <Filtermodal filterAnchorEl={filterAnchorEl} handleFilterClose={handleFilterClose} />
                        </div>
                    </div>
                    <div className='overflow-auto'>
                        <div className='grid md:grid-cols-2 md:gap-4 2xl:grid-cols-3' >
                            {notification?.length > 0 ? notification.map((item) => (
                                <div key={item} className='flex justify-between border-b border-amour-gray pb-5 mb-5 gap-[10px] rounded-lg 
                                md:border md:p-4 md:mb-0 md:gap-3'>
                                    <table>
                                        <tbody className='flex flex-col justify-center gap-2'>
                                            <tr className='flex gap-2 sm:flex-row sm:items-center md:gap-3'>
                                                <td className='flex items-center text-cloud-gray text-sm font-medium gap-1 md:justify-between md:text-camo-green md:font-semibold xl:min-w-[150px]'>Status <span>:</span></td>
                                                <td>{item?.scheduleStatus == 0 ? (<button className='bg-dark-light-green text-green text-sm font-semibold py-[3px] px-3
                                            rounded-[63px] md:py-[6px] md:px-5'>Deliverd</button>) : (<button className='bg-dark-light-green text-green text-sm font-semibold py-[3px] px-3
                                            rounded-[63px] md:py-[6px] md:px-5'>Pending</button>)}
                                                </td>
                                            </tr>
                                            <tr className='flex flex-col gap-1 sm:flex-row sm:items-center md:gap-3'>
                                                <td className='flex items-center text-cloud-gray text-sm font-medium gap-1 md:justify-between md:text-camo-green md:font-semibold xl:min-w-[150px]'>Message<span>:</span> </td>
                                                <td className='text-dark-black text-sm'>{item?.discription}</td>
                                            </tr>
                                            <tr className='flex gap-1 sm:flex-row sm:items-center md:gap-3'>
                                                <td className='flex items-center text-cloud-gray text-sm font-medium gap-1 md:justify-between md:text-camo-green md:font-semibold xl:min-w-[150px]'>Date<span>:</span></td>
                                                <td className='text-dark-black text-sm font-semibold '>{item?.scheduleDate}</td>
                                            </tr>
                                            <tr className='flex gap-1 sm:flex-row sm:items-center md:gap-3'>
                                                <td className='flex items-center text-cloud-gray text-sm font-medium gap-1 md:justify-between md:text-camo-green md:font-semibold xl:min-w-[150px]'>Time<span>:</span></td>
                                                <td className='text-dark-black text-sm font-semibold '>{item?.scheduleTime}</td>
                                            </tr>
                                            <tr className='flex gap-1 sm:flex-row sm:items-center md:gap-3'>
                                                <td className='flex items-center text-cloud-gray text-sm font-medium gap-1 md:justify-between md:text-camo-green md:font-semibold xl:min-w-[150px]'>Recipients<span>:</span></td>
                                                <td className='text-dark-black text-sm font-semibold'>{item?.recipientUser}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div>
                                        <Link href="#" onClick={(e) => handleactionClick(e, item?._id)}><Action className='m-auto w-5' /></Link>
                                        <Notificationaction anchoractionEl={anchoractionEl} handleactionClose={handleactionClose} data={Id} />
                                    </div>
                                </div>
                            )) : (<div className='col-span-4'>
                                <p className='text-center pt-4 font-semibold text-sm'>Data not found</p>
                            </div>
                            )}
                        </div>
                    </div>
                    <div className='hidden items-center justify-between pt-6 px-6 w-full md:flex'>
                        <span className='flex items-center text-cloud-gray font-semibold gap-2' onClick={currentPage > 1 ? handlePriviousClick : undefined}> <Leftarrow /> PREV</span>
                        <ul className='flex items-center'>
                            {paginationItems()}
                        </ul>
                        <span className='flex items-center text-cloud-gray font-semibold gap-2' onClick={currentPage < totalPages ? handleNextClick : undefined}>NXT <Rightlongarrow /></span>
                    </div>
                </div>
            </div >
        </>
    )
}
export default Notificationdetails;