import React, { useEffect, useState } from 'react';
import { Closeeye, Eye, Logoimage } from '../Assest/Allsvg';
import { Checkbox } from '@mui/material';
import loginuser from '../Assest/loginuser.png';
import { useDispatch, useSelector } from 'react-redux';
import { adminLogin } from '../store/admin/admin.action';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import { validateEmail } from '../Components/validaiton';
import { Link } from 'react-router-dom';

const Loginpage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const adminError = useSelector((state) => state.admin.error);
  const [error, setError] = useState({});
  const [isCheck, setIsCheck] = useState(localStorage.getItem('isCheck'));
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    const Demail = localStorage.getItem('email');
    const Dpassword = localStorage.getItem('password');
    if (Demail) setEmail(Demail);
    if (Dpassword) setPassword(Dpassword);
  }, []);
  useEffect(() => {
    const storedCheckValue = localStorage.getItem("isCheck") === "true";
    setIsCheck(storedCheckValue);
  }, []);
  const handleChange = (e) => {
    const checked = e.target.checked;
    setIsCheck(checked);
    localStorage.setItem("isCheck", checked);
  };
  const handleLogin = () => {
    const obj = {};
    if (!email) {
      obj.email = 'Please enter email'
    } else {
      if (!validateEmail(email)) {
        obj.email = "Please enter valid email"
      }
    }
    if (!password) {
      obj.password = 'Please enter password'
    }
    setError(obj)
    if (Object.keys(obj).length === 0) {
      dispatch(adminLogin({ email, password, isCheck }, navigate))
    }
  }

  return (
    <>
      <div className='grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 md:h-screen !m-0 !w-full gap-5 md:gap-10 lg:gap-0'>
        <div className='py-4 sm:py-5 flex flex-col justify-center overflow-auto'>
          <div className='px-4 md:px-7 lg:px-16'>
            <Link to="#"><Logoimage className="max-w-[250px] sm:max-w-[300px] mx-auto m-0" /></Link>
            <div className='pt-5 sm:pt-6 md:pt-10 lg:pt-10 text-left flex flex-col gap-2'>
              <h3 className='text-2xl font-bold leading-[normal] text-dark-black'>Log in</h3>
              <h6 className='font-medium text-sm leading-[normal] text-cloud-gray'>Welcome to My G-Spot Outdoors. Please log in.</h6>
            </div>
            <div className='text-left pt-4 sm:pt-5 md:pt-10'>
              <h4 className='font-semibold pb-2 text-dark-black'>Email Address</h4>
              <div>
                <input type="email" placeholder={email ? email : 'Enter email address'} value={email} onChange={(e) => setEmail(e.target.value)} className='border border-spring-rain rounded-lg w-full p-2.5 leading-[normal] text-cloud-gray placeholder:text-cloud-gray text-sm font-semibold' />
                {error && <p className='text-red text-sm pt-1'>{error.email}</p>}
              </div>
            </div>
            <div className='text-left pt-2 sm:pt-5 md:pt-7'>
              <h4 className='font-semibold pb-2 text-dark-black'>Password</h4>
              <div className='relative'>
                <div className='relative'>
                  <input type={showPassword ? 'text' : 'password'} placeholder={password ? password : "Enter password"} value={password} onChange={(e) => setPassword(e.target.value)} className='border border-spring-rain rounded-lg w-full p-2.5 leading-[normal] text-cloud-gray placeholder:text-cloud-gray text-sm font-semibold' />
                  <button onClick={() => setShowPassword(!showPassword)} className='absolute top-1/2 right-4 -translate-y-1/2' >
                    {!showPassword ? <Closeeye className='w-5' /> : <Eye className='w-5' />}
                  </button>
                </div>
                {error && <p className='text-red text-sm pt-1'>{error.password}</p>}
              </div>
              <div className='flex justify-between items-center pt-3'>
                <span className='font-medium flex items-center gap-1 md:gap-2 text-sm md:text-base'> <Checkbox checked={isCheck}
                  onChange={handleChange} /> Remember me</span>
              </div>
              <button className='bg-camo-green text-white w-full rounded-lg p-2 font-semibold mt-4 md:mt-8' onClick={handleLogin}>Log in</button>
            </div>
            {adminError && <p className='text-red text-sm  mt-2'>{adminError}</p>}
          </div>
        </div>
        <div className='w-full h-full hidden md:block 2xl:col-span-2'>
          <img src={loginuser} alt="" className='h-full object-cover' />
        </div>
      </div>
    </>
  )
  {/* // : login === 'forgotPassword' ? (
          //   <div className='pt-5 flex flex-col justify-center'>
          //     <Logoimage className='mx-auto' />
          //     <div className='px-4 md:px-7 lg:px-16'>
          //       <div className='pt-6 md:pt-10 lg:pt-14 text-left flex flex-col gap-2'>
          //         <h3 className='text-2xl font-bold leading-[normal] text-dark-black'>Forgot Password</h3>
          //         <h6 className='font-medium text-sm leading-[normal] text-cloud-gray'>We send a code to your email to verify account.</h6>
          //       </div>
          //       <div className='text-left pt-10'>
          //         <h4 className='font-semibold pb-2 text-dark-black'>Email</h4>
          //         <input type="email" placeholder='Enter email ID/ Mobile Number' className='border border-spring-rain rounded-lg w-full p-2.5 leading-[normal] text-cloud-gray placeholder:text-cloud-gray text-sm' />
          //       </div>
          //       <button onClick={() => setLogin(!verify)} className='bg-camo-green text-white w-full rounded-lg p-2.5 font-semibold mt-16'>Verify Account 01</button>
          //     </div>
          //   </div>
          // )}

          // {/* verify account */}
  // {showVerifyAccountPage && ( */}
  //   <div>
  //     <div className='pt-5 flex flex-col justify-center'>
  //       <Logoimage className='mx-auto' />
  //       <div className='px-4 md:px-7 lg:px-16'>
  //         <div className='pt-10 md:pt-10 lg:pt-14 text-left flex flex-col gap-2'>
  //           <h3 className='text-2xl font-bold leading-[normal] text-dark-black text-center md:text-start'>Let’s verify account</h3>
  //           <h6 className='font-medium text-sm leading-[normal] text-cloud-gray text-center md:text-start'>We send a code to your phone number to verify account</h6>
  //         </div>
  //         <div className="otp_password">
  //           <OTPInput
  //             value={otp}
  //             onChange={setOtp}
  //             numInputs={4}
  //             renderInput={(props) => <input {...props} />}
  //           />
  //         </div>
  //         <h6 className='flex justify-center items-center gap-2 text-lg font-medium'><Clock /> 00:32</h6>
  //         <button onClick={() => setVerify(!submit)} className='bg-camo-green text-white w-full rounded-lg p-2.5 font-semibold mt-8'>Verify Account</button>
  //         <h5 className='text-center pt-5 text-sm text-cloud-gray'>Didn’t received code? <a href="#" className='font-bold text-camo-green'>Resend Code</a></h5>
  //       </div>
  //     </div>
  //   </div>
  // )}
  // {/* reset password */}
  // {showResetPasswordPage && (
  //   <div>
  //     <div className='pt-5 flex flex-col md:justify-center'>
  //       <Logoimage className='mx-auto' />
  //       <div className='px-4 md:px-7 lg:px-16'>
  //         <div className='pt-8 md:pt-10 lg:pt-14 text-left flex flex-col gap-2'>
  //           <h3 className='text-2xl font-bold leading-[normal] text-dark-black'>Reset Password</h3>
  //         </div>
  //         <div className='text-left pt-5 md:pt-7'>
  //           <h4 className='font-semibold pb-2 text-dark-black'>New Password</h4>
  //           <div className='relative'>
  //             <input type="password" placeholder='Enter new password' className='border border-spring-rain rounded-lg w-full p-2.5 leading-[normal] text-cloud-gray placeholder:text-cloud-gray text-sm' />
  //             <button className='absolute top-1/2 right-4 -translate-y-1/2'>
  //               <Eye />
  //             </button>
  //           </div>
  //           <h4 className='font-semibold pb-2 text-dark-black pt-5'>Confirm New Password</h4>
  //           <div className='relative'>
  //             <input type="password" placeholder='Enter confirm new password' className='border border-spring-rain rounded-lg w-full p-2.5 leading-[normal] text-cloud-gray placeholder:text-cloud-gray text-sm' />
  //             <button className='absolute top-1/2 right-4 -translate-y-1/2'>
  //               <Eye />
  //             </button>
  //           </div>
  //           <button className='bg-camo-green text-white w-full rounded-lg p-2 font-semibold mt-8'>Submit</button>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )}
  // {/* change password */}
  // {showPasswordChangedPage && (
  //   <div>
  //     <div className='pt-5 flex flex-col justify-center'>
  //       <Logoimage className='mx-auto' />
  //       <div className='px-4 md:px-7 lg:px-16'>
  //         <div className='pt-6 md:pt-10 lg:pt-14 text-left flex flex-col gap-2'>
  //           <h3 className='text-2xl font-bold leading-[normal] text-dark-black'>Password Changed</h3>
  //           <h6 className='font-medium text-sm leading-[normal] text-cloud-gray'>Your password has been successfully changed.</h6>
  //         </div>
  //         <button onClick={() => setLogin(false)} className='bg-camo-green text-white w-full rounded-lg p-2 font-semibold mt-40'>Log in</button>
  //       </div>
  //     </div>
  //   </div>
  // )}
  <div className='w-full h-full 2xl:col-span-2'>
    <img src={loginuser} alt="" className='h-full object-cover' />
  </div>
  //     </div>
  //   </>
  // )
}

export default Loginpage