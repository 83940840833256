import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Dialog } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { CloseIcon, Deleteicon, Leftangle, Search } from '../../Assest/Allsvg';
import { assingDeveloper, deleteReport, getReportById, updateReport } from '../../store/report/report.action';
import { DELETE_REPORT_OBJECT } from '../../store/report/report.type';
import userProfile from '../../Assest/user.png';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import { isValidTime } from '../validaiton';

const Reportdetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const item = useSelector((state) => state.report.reportObject);
    const developer = useSelector((state) => state.report.developer);
    const error = useSelector((state) => state.report.error);
    const [repairTime, setRepairTime] = useState('');
    const [assignee, setAssignee] = useState('');
    const [status, setStatus] = useState(0);
    const [validError, SetValidError] = useState(null)
    const [id, setId] = useState('');

    useEffect(() => {
        if (item && item._id) {
            localStorage.setItem('reportId', item._id);
            setAssignee(item?.assignee?._id || developer[0]?._id)
            setRepairTime(item?.repairTime)
            setStatus(item.status)
            setId(item._id)
        }
    }, [item]);
    useEffect(() => {
        const reportId = localStorage.getItem('reportId');
        if (reportId) {
            dispatch(getReportById(reportId));
            dispatch(assingDeveloper())
        }
    }, [dispatch]);
    const handleDelete = () => {
        dispatch({ type: DELETE_REPORT_OBJECT, payload: { id: item._id } })
        dispatch(deleteReport({ id: item._id }, navigate));
    }

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleClickDelete = () => {
        handleClose()
        handleDelete()
    }

    const handleUpdate = () => {
        let obj = {}
        if (!isValidTime(repairTime)) {
            obj.time = 'Please enter valid time'
        }
        SetValidError(obj)

        if (Object.keys(obj).length == 0) {
            dispatch(updateReport({ repairTime, status, assignee }, id, navigate))
        }
    }
    const handleButtonClick = () => {
        const parameterValue = ":parameter";
        navigate(`/feedback/${parameterValue}`);
    };
    return (
        <>
            <div className='p-4 sm:px-5 sm:py-6 flex-1 flex flex-col '>
                <div className='bg-white md:bg-transparent flex-1 p-4 sm:p-5 md:p-0 rounded-[10px] flex flex-col'>
                    <div className='flex items-center justify-between gap-3' >
                        <h4 className='text-base md:text-xl text-dark-black font-bold flex items-center gap-2'> <Link to="/issuereport">
                            <Leftangle className='w-5' /></Link>| Issues Details</h4>
                        <div className='flex items-center gap-2 md:hidden'>
                            <Link href="#" onClick={handleClickOpen} className='bg-light-red border border-coral-red-light p-[8px] rounded-lg'><Deleteicon /></Link>
                            <React.Fragment>
                                <Dialog
                                    className="deletemodal"
                                    fullScreen={fullScreen}
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="responsive-dialog-title"
                                >
                                    <button onClick={handleClose} className='absolute right-5 hidden md:block'><CloseIcon /></button>
                                    <h3 className='text-lg md:text-[26px] font-bold md:font-semibold text-woodsmoke text-center pb-[6px] md:pb-6'>Delete Feedback</h3>
                                    <h5 className='text-cloud-gray text-xs text-center md:text-lg font-semibold leading-6'>Are you sure you want to Delete this feedback ?</h5>
                                    <div className='pt-[18px] md:pt-10 flex items-center justify-center gap-5'>
                                        <button className='bg-amour-gray text-camo-green text-sm md:text-lg font-semibold min-w-[143px] md:min-w-[177px] py-3 md:py-4 px-10 md:px-12 rounded-lg' autoFocus onClick={handleClose} >
                                            Cancel
                                        </button>
                                        <button onClick={handleClickDelete} autoFocus className='bg-red-orange text-white text-sm md:text-lg font-semibold min-w-[143px] md:min-w-[177px] py-3 md:py-4 rounded-lg'>
                                            Delete
                                        </button>
                                    </div>
                                </Dialog>
                            </React.Fragment>
                        </div>
                    </div>
                    <div className='md:bg-white rounded-[10px] md:mt-5 md:px-5 pt-4 md:py-5 flex-1'>
                        <div className='md:flex items-start justify-between'>
                            <div className='border-t md:border-none border-t-amour-gray pt-4 md:pt-0 pb-[18px] gap-4 lg:gap-6 flex justify-center flex-col rounded-lg'>
                                <div className='flex items-center gap-2 text-dark-black capitalize font-semibold text-sm md:hidden'><img src={item?.user_id?.profile_img} alt="" className='w-10 h-10 object-cover rounded-full' />{item?.user_id?.username}</div>
                                <table>
                                    <tbody className='flex justify-center flex-col gap-3'>
                                        <tr className='flex items-start flex-col sm:flex-row gap-1 md:gap-6'>
                                            <td className='md:min-w-[150px] flex justify-between items-center text-sm font-medium md:font-semibold text-cloud-gray gap-1 md:leading-[normal]'>Issues <span>:</span></td>
                                            <td className='text-dark-black text-sm font-semibold md:leading-[normal] max-w-[550px]'>{item?.issues}</td>
                                        </tr>
                                        <tr className='flex items-center gap-1 md:gap-6'>
                                            <td className='md:min-w-[150px] flex justify-between items-center text-sm font-medium md:font-semibold text-cloud-gray gap-1 md:leading-[normal]'>Date <span>:</span></td>
                                            <td className='text-dark-black text-sm font-semibold md:leading-[normal]'>{moment(item?.createdAt).format("DD MMM, YYYY")}</td>
                                        </tr>
                                        <tr className='flex items-start gap-1 md:gap-6'>
                                            <td className='md:min-w-[150px] flex justify-between items-center text-sm font-medium md:font-semibold text-cloud-gray gap-1 md:text-camo-green'>Repair Time <span>:</span></td>
                                            <td className='text-dark-black font-semibold text-sm'>
                                                <input type="text" placeholder='00:00:00' value={repairTime}
                                                    onChange={(e) => setRepairTime(e.target.value)}
                                                />
                                                {validError && <p className='text-red text-sm pt-1'>{validError.time}</p>}
                                            </td>
                                        </tr>
                                        <tr className='flex items-center gap-1 md:gap-6'>
                                            <td className='md:min-w-[150px] flex justify-between items-center text-sm font-medium md:font-semibold text-cloud-gray gap-1 md:leading-[normal]'>Status<span>:</span></td>
                                            <td className="text-dark-black font-semibold text-sm">
                                                <FormControl size="small" className='border-amour-gray !min-w-[187px] status_dropdown'>
                                                    <Select
                                                        labelId="demo-select-small-label"
                                                        id="demo-select-small"
                                                        value={status}
                                                        onChange={(e) => setStatus(e.target.value)}
                                                    >
                                                        <MenuItem value={0}>
                                                            <button className='text-[#686868] font-semibold text-sm w-full text-start'>Not Resolved</button>
                                                        </MenuItem>
                                                        <MenuItem value={1}>
                                                            <button className='text-[#219653] font-semibold text-sm w-full text-start'>Resolved</button>
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </td>
                                        </tr>
                                        <tr className='flex items-center gap-1 md:gap-6'>
                                            <td className='md:min-w-[150px] flex justify-between items-center text-sm font-medium md:font-semibold text-cloud-gray gap-1 md:leading-[normal]'>Priority<span>:</span></td>
                                            <td className="text-dark-black font-semibold text-sm priority_dropdown">
                                                {
                                                    item?.priority == 0 ? <button className='bg-dark-light-green py-[6px] px-4 rounded-[63px] text-red font-semibold text-sm'>High</button>
                                                        : item?.priority == 1 ? <button className='bg-dark-light-yellow py-[6px] px-4 rounded-[63px] text-dark-orange font-semibold text-sm'>Medium</button> :
                                                            <button className='bg-dark-light-yellow py-[6px] px-5 text-sm rounded-[63px] text-light-yellow font-semibold'>Low</button>
                                                }

                                            </td>
                                        </tr>
                                        <tr className='flex items-center gap-1 md:gap-6'>
                                            <td className='md:min-w-[150px] flex justify-between items-center text-sm font-medium md:font-semibold text-cloud-gray gap-1 md:leading-[normal]'>Assignee<span>:</span></td>
                                            <td className="text-dark-black font-semibold text-sm">
                                                <FormControl size="small" className='border-amour-gray !min-w-[187px] status_dropdown'>
                                                    <Select
                                                        labelId="demo-select-small-label"
                                                        id="demo-select-small"
                                                        className='assigness_select min-w-[150px]'
                                                        displayEmpty
                                                        value={assignee}
                                                        onChange={(e) => setAssignee(e.target.value)}
                                                    >
                                                        {developer.map((data) => (
                                                            <MenuItem key={data?._id} value={data?._id}>
                                                                <div className='flex items-center gap-3'>
                                                                    <img src={data?.profile_img || userProfile} alt='' style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                                                                    <h6>{data?.name}</h6>
                                                                </div>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='md:flex hidden items-center justify-end gap-2 pb-5'>
                                <Link href="#" onClick={handleClickOpen} className='bg-light-red border border-coral-red-light p-[10px] rounded-lg'><Deleteicon /></Link>
                                <React.Fragment>
                                    <Dialog
                                        className="deletemodal"
                                        fullScreen={fullScreen}
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="responsive-dialog-title"
                                    >
                                        <button onClick={handleClose} className='absolute right-5 hidden md:block'><CloseIcon /></button>
                                        <h3 className='text-lg md:text-[26px] font-bold md:font-semibold text-woodsmoke text-center pb-[6px] md:pb-6'>Delete Report</h3>
                                        <h5 className='text-cloud-gray text-xs text-center md:text-lg font-semibold leading-6'>Are you sure you want to Delete this report ?</h5>
                                        <div className='pt-[18px] md:pt-10 flex items-center justify-center gap-5'>
                                            <button className='bg-amour-gray text-camo-green text-sm md:text-lg font-semibold min-w-[143px] md:min-w-[177px] py-3 md:py-4 px-10 md:px-12 rounded-lg' autoFocus onClick={handleClose} >
                                                Cancel
                                            </button>
                                            <button onClick={handleClickDelete} autoFocus className='bg-red-orange text-white text-sm md:text-lg font-semibold min-w-[143px] md:min-w-[177px] py-3 md:py-4 rounded-lg'>
                                                Delete
                                            </button>
                                        </div>
                                    </Dialog>
                                </React.Fragment>
                            </div>
                        </div>
                        <div className='flex items-center justify-center gap-4 pt-3'>
                            <Link to="/issuereport" className='border border-camo-green text-camo-green text-sm font-semibold py-3 px-12 box-border rounded-lg  md:text-base sm:min-w-[150px]'>
                                Cancel
                            </Link>
                            <div className='text-center'>
                                {error && <p className='text-red text-sm pt-1'>{error}</p>}
                                <Link className='border border-transparent bg-camo-green text-white text-sm font-semibold py-3 px-12 
                             box-border inline-block rounded-lg md:text-base sm:min-w-[150px]' onClick={handleUpdate}>Save</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Reportdetails;