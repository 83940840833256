import axios from "axios";
import { GET_USER_BY_ID, GET_USER_LIST, GET_USER_LIST_FAIL } from "./user.type";

export const getUserList = (data) => async (dispatch) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}v1/user/user_list_for_penal`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        const responseData = await response.json();

        if (responseData.success) {
            dispatch({
                type: GET_USER_LIST,
                payload: {
                    user: responseData.data.user,
                    total: responseData.data.totalUser
                }
            });
        } else {
            dispatch({
                type: GET_USER_LIST_FAIL,
                payload: []
            });
        }
    } catch (error) {
        console.error("Fetching user list failed:", error);
        dispatch({
            type: GET_USER_LIST_FAIL,
            payload: []
        });
    }
}


// for add new user
export const getUserById = (data) => async (dispatch) => {
    await axios.post(`v1/user/get_profile_by_admin`, data).then((res) => {
        if (res.data.success) {
            dispatch({ type: GET_USER_BY_ID, payload: res.data.data.user })

        } else {

            dispatch({ type: GET_USER_BY_ID, payload: {} })
        }
    }).catch((error) => console.log(error))
}

// for delete user
export const deleteUserById = (data, navigate) => async (dispatch) => {

    await axios.post(`v1/user/delete_user_by_admin`, data).then((res) => {
        if (res.data?.success) {
            navigate('/user')
        }
    }).catch((error) => console.log(error))
}

//for update user
export const updateUser = (data, id, navigate) => async (dispatch) => {

    await axios.post(`v1/user/update_profile_by_admin?id=${id}`, data).then((res) => {
        if (res.data.success) {
            dispatch({ type: GET_USER_BY_ID, payload: res.data.data.users })
            navigate('/user/userdetails')
        }
    }).catch((error) => console.log(error))
}

//for delete multiple user
export const deleteMultipleUser = (data, navigate) => async (dispatch) => {
    await axios.post(`v1/user/delete_mulipleUser_by_admin`, data).then((res) => {
        if (res.data.success) {
            // dispatch({ type: GET_USER_BY_ID, payload: res.data.data.users })
            navigate('/user')
        }
    }).catch((error) => console.log(error))
}

