import { ADMIN_LOGIN, ADMIN_LOGIN_FAIL } from "./admin.type"


const intialState = {
    admin: [],
    error: null
}


const adminReducer = (state = intialState, action) => {
    switch (action.type) {
        case ADMIN_LOGIN:
            return {
                ...state,
                admin: action.payload,
                error: null
            }
        case ADMIN_LOGIN_FAIL:
            return {
                ...state,
                error: action.payload
            }


        default:
            return state
    }
}


export default adminReducer