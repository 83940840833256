
import { DELETE_MULTIPLE_REPORT, DELETE_REPORT_OBJECT, FIND_DEVELOPER, GET_REPORT_ERROR, GET_REPORT_LIST, GET_REPORT_LIST_FAIL, GET_REPORT_OBJECT, GET_REPORT_OBJECT_BY_ID, REPORT_UPDATE_ERROR } from "./report.type"



const initialState = {
    reportList: [],
    developer: [],
    reportCounts: null,
    totalPage: 0,
    reportObject: null,
    error: null
}

const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REPORT_LIST:
            return {
                ...state,
                reportList: action.payload.report,
                totalPage: action.payload.total,
                reportCounts: action.payload.counts,
                reportObject: null
            }
        case GET_REPORT_LIST_FAIL:
            return {
                ...state,
                reportList: [],
                totalPage: 0,
                reportCounts: action.payload.counts
            }
        case GET_REPORT_ERROR:
            return {
                ...state,
                reportList: [],
                totalPage: 0,
                reportCounts: null
            }

        case GET_REPORT_OBJECT:

            const data = state.reportList.find(report => report._id == action.payload?.id)
            return {
                ...state,
                reportObject: data
            }
        case GET_REPORT_OBJECT_BY_ID:
            return {
                ...state,
                reportObject: action.payload
            }

        case DELETE_REPORT_OBJECT:
            const report = state.reportList.filter(report => report._id !== action.payload.id)
            return {
                ...state,
                reportList: report,
                totalPage: state.totalPage - 1
            }

        case DELETE_MULTIPLE_REPORT:
            const Alldata = state.reportList.filter(report => !action.payload.includes(report._id))
            return {
                ...state,
                reportList: Alldata,
                totalPage: Alldata?.length
            }
        case FIND_DEVELOPER:
            return {
                ...state,
                developer: action.payload
            }
        case REPORT_UPDATE_ERROR:
            return {
                ...state,
                error: action.payload
            }

        default:
            return state
    }
}

export default reportReducer