import React, { useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { deleteSupport, getSupportById, updateSupport } from '../../store/support/support.action';
import { DELETE_SUPPORT_ID } from '../../store/support/support.type';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import { assingDeveloper } from '../../store/report/report.action';
import userProfile from '../../Assest/user.png';
import { CloseIcon, Deleteicon, Leftangle } from '../../Assest/Allsvg';

const Supportticket = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const item = useSelector((state) => state.support.supportObject);
    const developer = useSelector((state) => state.report.developer);
    const error = useSelector((state) => state.support.error);
    const [status, setStatus] = useState(0);
    const [support, setSupport] = useState('');
    const [id, setId] = useState('');
    useEffect(() => {
        if (item && item._id) {
            localStorage.setItem('supportId', item._id);
            setStatus(item?.status);
            setSupport(item?.support?._id || developer[0]?._id);
            setId(item?._id);
        }
    }, [item]);
    useEffect(() => {
        const supportId = localStorage.getItem('supportId');
        if (supportId) {
            dispatch(getSupportById(supportId));
            dispatch(assingDeveloper());
        }
    }, [dispatch]);
    const handleDelete = () => {
        dispatch({ type: DELETE_SUPPORT_ID, payload: { id: item._id } });
        dispatch(deleteSupport(item._id, navigate));
    }
    const handleupdate = () => {
        dispatch(updateSupport({ status, support }, id));
    }
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleClickDelete = () => {
        handleClose()
        handleDelete()
    }
    return (
        <>
            <div className='p-4 sm:px-5 sm:py-6 flex-1 flex flex-col '>
                {/* <div className='relative md:hidden flex mb-4 sm:mb-5'>
                    <div className='absolute top-1/2 -translate-y-1/2 left-4'>
                        <Search className="w-5" />
                    </div>
                    <input type="text" placeholder='Search here...' className='border border-cloud-light-gray rounded-lg w-full py-[14px] text-cloud-gray placeholder:text-cloud-gray placeholder:font-medium pl-11 text-sm' />
                </div> */}
                <div className='bg-white md:bg-transparent flex-1 p-4 sm:p-5 md:p-0 rounded-[10px] flex flex-col'>
                    <div className='flex justify-between items-center'>
                        <h4 className='text-base md:text-xl text-dark-black font-bold flex items-center gap-2'> <Link to="/supportticket" >
                            <Leftangle className='w-5' /></Link >| Support Details</h4>
                        <Link href="#" onClick={handleClickOpen} className='bg-light-red border border-coral-red-light p-2 rounded-lg block md:hidden'><Deleteicon /></Link>
                    </div>
                    <div className='md:bg-white rounded-[10px] md:mt-5 md:px-5 pt-4 md:py-5 flex-1'>
                        <div className='flex items-start justify-between'>
                            <div className='border-t md:border-none w-full border-t-amour-gray pt-4 md:pt-0 pb-[18px] gap-4 md:gap-6 flex justify-center flex-col rounded-lg'>
                                {/* <div className='flex items-center gap-2 text-dark-black font-semibold text-sm md:hidden'><img src={item?.user_id?.profile_img || userProfile} alt="" className='w-10 h-10 rounded-full' />{item?.user_id?.username}</div> */}
                                <table>
                                    <tbody className='flex justify-center flex-col gap-3 sm:gap-2 lg:gap-3'>
                                        <tr className='flex items-center gap-4 md:gap-6'>
                                            <td className='md:min-w-[150px] flex justify-between items-center text-sm font-medium md:font-semibold text-cloud-gray md:leading-[normal]'>Ticket ID <span>:</span></td>
                                            <td className='text-dark-black text-sm font-semibold md:leading-[normal]'>{item?.ticketId}</td>
                                        </tr>
                                        <tr className='flex flex-col md:flex-row items-start gap-6'>
                                            <td className='md:min-w-[150px] flex justify-between items-center text-sm font-medium md:font-semibold text-cloud-gray md:leading-[normal]'>Subject <span>:</span></td>
                                            <td className='text-dark-black text-sm max-w-[450px] lg:max-w-[700px] font-semibold md:leading-[normal]'>{item?.subject}</td>
                                        </tr>
                                        <tr className='flex items-center gap-2 md:gap-6'>
                                            <td className='md:min-w-[150px] flex justify-between gap-1 items-center text-sm font-medium md:font-semibold text-cloud-gray md:text-camo-green'>Last Update <span>:</span></td>
                                            <td className='text-dark-black font-semibold text-sm'>{moment(item?.updatedAt).format("DD MMM, YYYY")}</td>
                                        </tr>
                                        <tr className='flex items-center gap-2 md:gap-6'>
                                            <td className='md:min-w-[150px] flex justify-between gap-1 items-center text-sm font-medium md:font-semibold text-cloud-gray md:leading-[normal]'>Status<span>:</span></td>
                                            <td className="text-dark-black font-semibold text-sm">
                                                <FormControl size="small" className='border-amour-gray !min-w-[150px] status_dropdown'>
                                                    <Select
                                                        labelId="demo-select-small-label"
                                                        id="demo-select-small"
                                                        value={status}
                                                        onChange={(e) => setStatus(e.target.value)}

                                                    >
                                                        <MenuItem value={0}>Hold</MenuItem>
                                                        <MenuItem value={1}>Inprogress</MenuItem>
                                                        <MenuItem value={2}>Completed</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </td>
                                        </tr>
                                        <tr className='flex items-center gap-2 md:gap-6'>
                                            <td className='md:min-w-[150px] flex justify-between gap-1 items-center text-sm font-medium md:font-semibold text-cloud-gray md:leading-[normal]'>Support<span>:</span></td>
                                            <td className="text-dark-black font-semibold text-sm">
                                                <FormControl size="small" className='!min-w-[150px] border-amour-gray status_dropdown'>
                                                    <Select
                                                        labelId="demo-select-small-label"
                                                        id="demo-select-small"
                                                        className='assigness_select'
                                                        value={support}
                                                        onChange={(e) => setSupport(e.target.value)}
                                                    >
                                                        {developer.map((data) => (
                                                            <MenuItem key={data?._id} value={data?._id}>
                                                                <div className='flex items-center gap-3'>
                                                                    <img src={data?.profile_img || userProfile} alt='' style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                                                                    <h6>{data?.name}</h6>
                                                                </div>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='md:flex hidden items-center justify-end gap-2 pb-5'>
                                <Link href="#" onClick={handleClickOpen} className='bg-light-red border border-coral-red-light p-[10px] rounded-lg'><Deleteicon /></Link>
                                <React.Fragment>
                                    <Dialog
                                        className="deletemodal"
                                        fullScreen={fullScreen}
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="responsive-dialog-title"
                                    >
                                        <button onClick={handleClose} className='absolute right-5 hidden md:block'><CloseIcon /></button>
                                        <h3 className='text-lg md:text-[26px] font-bold md:font-semibold text-woodsmoke text-center pb-[6px] md:pb-6'>Delete Support</h3>
                                        <h5 className='text-cloud-gray text-xs text-center md:text-lg font-semibold leading-6'>Are you sure you want to Delete this Support ?</h5>
                                        <div className='pt-[18px] md:pt-10 flex items-center justify-center gap-5'>
                                            <button className='bg-amour-gray text-camo-green text-sm md:text-lg font-semibold min-w-[143px] md:min-w-[177px] py-3 md:py-4 px-10 md:px-12 rounded-lg' autoFocus onClick={handleClose} >
                                                Cancel
                                            </button>
                                            <button onClick={handleClickDelete} autoFocus className='bg-red-orange text-white text-sm md:text-lg font-semibold min-w-[143px] md:min-w-[177px] py-3 md:py-4 rounded-lg'>
                                                Delete
                                            </button>
                                        </div>
                                    </Dialog>
                                </React.Fragment>
                            </div>
                        </div>
                        <div className='text-center flex items-center pt-2 justify-center gap-5'>
                            <Link to="/supportticket" className='border border-camo-green text-camo-green text-sm font-semibold py-2 px-5 sm:py-3 sm:px-12 box-border rounded-lg md:text-base min-w-[120px] sm:min-w-[150px]'>
                                Cancel
                            </Link>
                            {error && <p className='text-red text-sm pt-1'>{error}</p>}
                            <Link to="/supportticket" className='border border-transparent bg-camo-green text-white font-semibold py-2 px-5 sm:py-3 sm:px-12 box-border inline-block rounded-lg text-base min-w-[120px] sm:min-w-[150px]' onClick={handleupdate}>Save</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Supportticket;