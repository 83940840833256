import React from 'react';
import { Eyeicon, Editicon, Deleteicon, CloseIcon } from '../../Assest/Allsvg';
import { DELETE_CLUB, GET_CLUB_OBJECT } from '../../store/club/club.type';
import { Popover, useMediaQuery, useTheme, Dialog } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteclub } from '../../store/club/club.action';
const Clubaction = ({ anchoractionEl, handleactionClose, data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleView = () => {
    dispatch({ type: GET_CLUB_OBJECT, payload: { id: data } });
    navigate('/club/clubdetail')
  }
  const handleUpdate = () => {
    dispatch({ type: GET_CLUB_OBJECT, payload: { id: data } });
    navigate('/club/editclubdetails')
  }
  const handleDelete = () => {
    dispatch({ type: DELETE_CLUB, payload: { id: data } });
    dispatch(deleteclub({ club_id: data }, navigate));
  }
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const handleClickOpen = () => {
    setOpenDeleteDialog(true);
    handleactionClose(false);
  };
  const handleClickClose = () => {
    setOpenDeleteDialog(false);
  };
  const handleConfirmDelete = () => {
    handleDelete();
    handleactionClose();
  }
  const open = Boolean(anchoractionEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <Popover
        className='action-popover'
        id={id}
        open={open}
        anchorEl={anchoractionEl}
        onClose={handleactionClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className='flex flex-col gap-2'>
          <button className='flex items-center text-dark-black font-medium gap-3' onClick={handleView}><Eyeicon /> View</button>
          <button className='flex items-center text-dark-black font-medium gap-3' onClick={handleUpdate}><Editicon /> Edit</button>
          <button className='flex items-center text-dark-black font-medium gap-3' onClick={handleClickOpen}><Deleteicon /> Delete</button>
        </div>
      </Popover>
      <React.Fragment>
        <Dialog
          className="deletemodal"
          fullScreen={fullScreen}
          open={openDeleteDialog}
          onClose={handleClickClose}
          aria-labelledby="responsive-dialog-title"
        >
          <button onClick={handleClickClose} className='hidden absolute right-5 md:block'><CloseIcon /></button>
          <h3 className='text-woodsmoke text-center text-lg font-bold pb-[6px] md:text-[26px] md:font-semibold md:pb-6'>Delete club</h3>
          <h5 className='text-cloud-gray text-xs text-center font-semibold leading-6 md:text-lg'>Are you sure you want to Delete this club ?</h5>
          <div className='flex items-center justify-center pt-[18px] gap-5 md:pt-10'>
            <button className='bg-amour-gray text-camo-green text-sm font-semibold py-3 min-w-[143px]
                px-10 rounded-lg md:text-lg md:py-4 md:px-12 md:min-w-[177px]' autoFocus onClick={handleClickClose} >
              Cancel
            </button>
            <button onClick={handleConfirmDelete} autoFocus className='bg-red-orange text-white text-sm font-semibold py-3 min-w-[143px] rounded-lg md:text-lg md:py-4 md:min-w-[177px]'>
              Delete
            </button>
          </div>
        </Dialog>
      </React.Fragment>
    </>
  )
}
export default Clubaction;