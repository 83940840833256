import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox, Dialog, useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';
import { Action, CloseIcon, Deleteicon, Leftarrow, Rightarrow, Rightlongarrow, Search } from '../../Assest/Allsvg';
import shapeimg from '../../Assest/shapeimage.png';
import issuereport from '../../Assest/issuereport.png';
import tcsupport from '../../Assest/tcsupport.png';
import { deleteMultipleReport, getReportList } from '../../store/report/report.action';
import Reportaction from '../Reportaction/Reportaction';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import { DELETE_MULTIPLE_REPORT } from '../../store/report/report.type';
import userProfile from '../../Assest/user.png';

const Issuereport = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState("")
    const reportCount = useSelector((state) => state.report.reportCounts)
    const report = useSelector((state) => state.report.reportList)
    const total = useSelector((state) => state.report.totalPage)
    let data = { page: page, limit: limit, search: search }
    useEffect(() => {
        dispatch(getReportList(data))
    }, [page, limit, search, dispatch])
    function formatNumber(num) {
        if (num >= 1000000000) {
            return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
        }
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
        }
        if (num >= 1000) {
            return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
        }
        return num;
    }
    const items = [
        {
            heading: "Total Technical Issue Reports", technicalicon: issuereport, subheading: reportCount?.totalReport ? formatNumber(reportCount.totalReport) : 0
        },
        {
            heading: "Total Technical Support", technicalicon: tcsupport, subheading: reportCount?.totalTechnical ? formatNumber(reportCount.totalTechnical) : 0
        },
        {
            heading: "Total Issues Solved", technicalicon: issuereport, subheading: reportCount?.totalSolved ? formatNumber(reportCount.totalSolved) : 0
        }
    ];
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event, data) => {
        setAnchorEl(event.currentTarget);
        setId(data)
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [Id, setId] = useState('');
    const handlePaginationView = () => {
        setPage(page + 1)
    }
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const handleSelectAll = (event) => {
        const checked = event.target.checked;
        setSelectAll(checked);
        const selectedData = checked ? report.map((data) => data._id) : [];
        setSelectedRows(selectedData);
    };
    const handleSelectRow = (event, id) => {
        const checked = event.target.checked;
        if (checked) {
            setSelectedRows((prevSelected) => [...prevSelected, id]);
        } else {
            setSelectedRows((prevSelected) => prevSelected.filter((rowId) => rowId !== id));
        }
    };
    const handleClearSearch = () => {
        setSearch('')
    }
    const handleDeleteMultiple = () => {
        dispatch({ type: DELETE_MULTIPLE_REPORT, payload: selectedRows })
        dispatch(deleteMultipleReport({ id: selectedRows }, navigate))
        setSelectedRows([])
    }
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [Open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClickClose = () => {
        setOpen(false);
    };
    const handleOpenClick = () => {
        handleClickClose()
        handleDeleteMultiple()
    }
    const totalPages = Math.ceil(total / limit)
    const [currentPage, setCurrentPage] = useState(1);
    const paginationItems = () => {
        let items = [];
        for (let number = 1; number <= totalPages; number++) {
            if (number === 1 || number === totalPages || (number >= currentPage - 1 && number <= currentPage + 1)) {
                items.push(
                    <li key={number}
                        className={`p-2 ${number === currentPage ? 'bg-amour-gray' : 'text-cloud-gray'} font-semibold rounded w-8 h-8 flex items-center justify-center`}
                        onClick={() => handleSelectPage(number)}>
                        {number}
                    </li>
                );
            } else if (number === currentPage - 2 || number === currentPage + 2) {
                if (items[items.length - 1].key !== '...') { // To avoid consecutive "..."
                    items.push(
                        <li key={'...' + number} className='p-2 text-cloud-gray font-semibold rounded w-8 h-8 flex items-center justify-center'>...</li>
                    );
                }
            }
        }
        return items;
    };
    const handleNextClick = () => {
        if (currentPage < totalPages) {
            setPage(page + 1)
        }
    };
    const handlePriviousClick = () => {
        if (currentPage > 1) {
            setPage(page - 1)
        }
    };
    const handleSelectPage = (number) => {
        setPage(number);
    };
    useEffect(() => {
        setCurrentPage(page);
    }, [page]);
    const [searchParams, setSearchParams] = useState({
        page: 1,
        limit: 12,
        search: '',
        sort: 0,
        startDate: 'ALL',
        endDate: 'ALL',
        status: 3,
        plan: 3,
    });

    // action popover
    const [anchoractionEl, setactionanchorEl] = React.useState(null);
    const handleactionClick = (event, data) => {
        setactionanchorEl(event.currentTarget);
        setId(data);
    };
    const handleactionClose = () => {
        setactionanchorEl(null);
    };
    return (
        <>
            <div className='px-4 py-5 sm:px-5 sm:py-6 flex flex-col overflow-auto'>
                <div className='pb-3 sm:pb-4'>
                    <h3 className='text-dark-black font-bold text-lg sm:text-xl'>Technical Issue Report</h3>
                </div>
                <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-4 lg:gap-6">
                    {items.map((item, index) => (
                        <div className="col-span-1" key={index}>
                            <div className='bg-white p-4 md:pl-5 pr-4 pt-4 rounded-lg border-b-4 md:border-b-0 md:border-l-4 border-camo-green relative'>
                                <div className='relative z-10'>
                                    <div className='flex items-start md:items-center justify-between flex-col md:flex-row'>
                                        <h4 className='text-sm lg:text-base font-medium text-cloud-gray'>{item.heading}</h4>
                                        <div className='border border-camo-green p-2 rounded-lg bg-amour-gray hidden md:flex'>
                                            <img src={item.technicalicon} alt="" className='w-6' />
                                        </div>
                                    </div>
                                    <div className='flex items-end justify-between'>
                                        <div className='flex items-center justify-between pt-4 gap-2'>
                                            <h3 className='text-lg lg:text-xl font-bold text-dark-black'>{item.subheading}</h3>
                                            <span className='flex items-center gap-2 text-sm font-bold text-cloud-gray'>
                                                <Rightarrow />{item.name}</span>
                                        </div>
                                        <div className='border border-camo-green p-[6px] md:p-2 rounded-lg bg-amour-gray flex md:hidden'>
                                            <img src={item.technicalicon} alt="" className='w-6' />
                                        </div>
                                    </div>
                                </div>
                                <div className='absolute bottom-0 right-0 top-0 left-0'>
                                    <img src={shapeimg} alt="" className='h-full w-full' />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="mt-7 bg-white p-5 rounded-lg">
                    {/* <div className='pb-5 flex items-center justify-between text-lg'>
                        <h4 className='text-sm md:text-xl text-dark-black font-bold'>| User List</h4>
                    </div> */}
                    <div className='relative flex mb-5'>
                        <div className='relative w-full sm:max-w-[300px] 2xl:max-w-[420px]'>
                            <div className='absolute top-1/2 -translate-y-1/2 left-4'>
                                <Search className="w-5" />
                            </div>
                            <input type="text" placeholder='Search issues...' className='border border-moon-gray rounded-lg w-full text-sm font-medium 2xl:min-w-[420px] pl-11 py-[10px] placeholder:text-cloud-gray' value={search} onChange={(e) => setSearch(e.target.value)} />
                            {search && <div className='absolute top-3 end-2 '>
                                <button onClick={handleClearSearch}>  <CloseIcon className="w-5 h-5" /></button>
                            </div>}
                        </div>
                        {selectedRows?.length > 0 && <div className='absolute top-1/2 -translate-y-1/2 right-1'>
                            <React.Fragment>
                                <button className='text-red border border-moon-gray flex items-center py-[10px] px-3 gap-2 rounded-lg leading-[normal] text-base font-medium bg-amour-gray' onClick={handleClickOpen}><Deleteicon className="w-5 h-5" />  {selectedRows?.length == 1 ? 'Delete' : 'Delete All'}</button>
                                <Dialog
                                    className="deletemodal"
                                    fullScreen={fullScreen}
                                    open={Open}
                                    onClose={handleClickClose}
                                    aria-labelledby="responsive-dialog-title"
                                >
                                    <button onClick={handleClickClose} className='absolute hidden right-5  md:block'><CloseIcon /></button>
                                    <h3 className='text-lg font-bold text-woodsmoke text-center pb-[6px] md:pb-6 md:text-[26px] md:font-semibold'>{selectedRows?.length == 1 ? 'Delete Report' : 'Delete Reports'}</h3>
                                    <h5 className='text-cloud-gray text-xs text-center font-semibold leading-6 md:text-lg'>Are you sure you want to Delete{selectedRows?.length == 1 ? ' this report' : ' all reports'} ?</h5>
                                    <div className='flex items-center justify-center gap-5 pt-[18px] md:pt-10'>
                                        <button className='bg-amour-gray text-camo-green text-sm font-semibold min-w-[143px] py-3 px-10 rounded-lg md:text-lg md:px-12 md:py-4 md:min-w-[177px]' autoFocus onClick={handleClickClose} >
                                            Cancel
                                        </button>
                                        <button onClick={handleOpenClick} autoFocus className='bg-red-orange text-white text-sm  font-semibold min-w-[143px] py-3 rounded-lg md:py-4 md:text-lg md:min-w-[177px]'>
                                            Delete
                                        </button>
                                    </div>
                                </Dialog>
                            </React.Fragment>
                        </div>}
                    </div>
                    <div className='overflow-auto hidden md:block'>
                        <table className="min-w-full text-camo-green">
                            <thead>
                                <tr>
                                    <th className="py-3 px-4 bg-white-lilac rounded-tl-lg rounded-l-lg text-start text-sm font-semibold 
                                    leading-[normal] whitespace-nowrap" ><Checkbox checked={selectAll} onChange={handleSelectAll} className='!text-[#EDEEEC]' /></th>
                                    <th className="py-3 px-4 bg-white-lilac text-start text-sm font-semibold leading-[normal] whitespace-nowrap">User Name</th>
                                    <th className="py-3 px-4 bg-white-lilac text-start text-sm font-semibold leading-[normal] whitespace-nowrap">Issues</th>
                                    <th className="py-3 px-4 bg-white-lilac text-start text-sm font-semibold leading-[normal] whitespace-nowrap">Date</th>
                                    <th className="py-3 px-4 bg-white-lilac text-start text-sm font-semibold leading-[normal] whitespace-nowrap">Repair time</th>
                                    <th className="py-3 px-4 bg-white-lilac text-start text-sm font-semibold leading-[normal] whitespace-nowrap">Status</th>
                                    <th className="py-3 px-4 bg-white-lilac text-start text-sm font-semibold leading-[normal] whitespace-nowrap">Priority</th>
                                    <th className="py-3 px-4 bg-white-lilac rounded-tr-lg ro text-sm font-semibold leading-[normal] whitespace-nowrap text-start">Assignee</th>
                                    <th className="py-3 px-4 bg-white-lilac rounded-tr-lg ro text-sm font-semibold leading-[normal] whitespace-nowrap text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {report?.length > 0 ? (report?.map((data, index) => (
                                    <tr key={index} className='align-top'>
                                        <td className="px-4 py-2 text-sm text-dark-black font-semibold border-b border-b-amour-gray whitespace-nowrap"><Checkbox checked={selectedRows.includes(data._id)} onChange={(event) => handleSelectRow(event, data._id)} className='!text-[#EDEEEC]' /></td>
                                        <td className="px-4 py-2 text-sm text-dark-black font-semibold border-b border-b-amour-gray whitespace-nowrap min-w-[200px]"><div className='flex items-center gap-2'><img className='w-8 h-8 rounded-full object-cover' src={data.user_id?.profile_img || userProfile} alt="" />{data.user_id?.username}</div></td>
                                        <td className="px-4 py-2 text-sm text-dark-black font-semibold border-b border-b-amour-gray min-w-[400px]">{data?.issues}</td>
                                        <td className="px-4 py-2 text-sm text-cloud-gray font-semibold border-b border-b-amour-gray 
                                        whitespace-nowrap">{moment(data?.createdAt).format("DD MMM, YYYY")}</td>
                                        <td className="px-4 py-2 text-sm text-cloud-gray font-semibold border-b border-b-amour-gray whitespace-nowrap">{data?.repairTime ? data?.repairTime : '00:00:00'}</td>
                                        <td className="px-4 py-2 text-sm text-cloud-gray font-semibold border-b border-b-amour-gray whitespace-nowrap">{data.status == 0 ? <button className='bg-light-green-gray py-[6px] px-4 rounded-[63px] text-cloud-gray  font-semibold'>Not Resolved</button> : <button className='bg-dark-light-green py-[6px] px-4 rounded-[63px] text-green-grey font-semibold'>Resolved</button>}</td>
                                        <td className="px-4 py-2 text-sm border-b border-b-amour-gray whitespace-nowrap">{data?.priority == 0 ? <button className='bg-dark-light-green py-[6px] px-4 rounded-[63px] text-red font-semibold'>Heigh</button> : data?.priority == 1 ? <button className='bg-light-green-gray py-[6px] px-5 rounded-[63px] text-yellow font-semibold'>Medium</button> : <button className='bg-dark-light-yellow text-sm py-[6px] px-5 rounded-[63px] text-light-yellow font-semibold'>Low</button>}</td>
                                        <td className="px-4 py-2 text-sm text-dark-black font-semibold border-b border-b-amour-gray whitespace-nowrap min-w-[200px]"><div className='flex items-center gap-2'><img className='w-8 h-8 rounded-full object-cover' src={data.assignee ? data.assignee?.profile_img || userProfile : ''} alt="" />{data.assignee?.username}</div>
                                        </td>
                                        <td className="px-4 py-2 text-sm text-center border-b border-b-amour-gray">
                                            <Link href="#" onClick={(e) => handleactionClick(e, data?._id)}><Action className='m-auto w-5' /></Link></td>
                                        <Reportaction anchoractionEl={anchoractionEl} handleactionClose={handleactionClose} data={Id} />
                                    </tr>
                                ))) : (<td colSpan="12" className='text-center text-sm text-cloud-gray font-semibold pt-3'>
                                    Data not found
                                </td>)}
                            </tbody>
                        </table>
                    </div>
                    <div className='grid grid-cols-1 md:hidden'>
                        {report?.length > 0 ? report.map((item) => (
                            <div className='pb-[18px] gap-4 md:gap-6 flex justify-center flex-col rounded-lg'>
                                <div className='flex justify-between items-center gap-3 sm:gap-2 text-dark-black font-semibold text-sm'>
                                    <div className='flex items-center gap-3'>
                                        <img src={item.user_id?.profile_img || userProfile} alt="" className='w-8 h-8 rounded-full' />{item.user_id?.username}
                                    </div>
                                    <Link href="#" onClick={(e) => handleactionClick(e, item?._id)}><Action className='m-auto w-5 m-right' /></Link>
                                    <Reportaction anchoractionEl={anchoractionEl} handleactionClose={handleactionClose} data={Id} />
                                </div>
                                <table>
                                    <tbody className='flex justify-center flex-col gap-3 sm:gap-[6px]'>
                                        <tr className='flex sm:items-center flex-col sm:flex-row gap-1'>
                                            <td className='flex justify-between items-center text-xs font-medium text-cloud-gray'>Issue :</td>
                                            <td className='text-dark-black text-xs font-semibold'>{item.issues}</td>
                                        </tr>
                                        <tr className='flex items-center gap-1'>
                                            <td className='flex justify-between items-center text-xs font-medium text-cloud-gray'>Created Date :</td>
                                            <td className='text-dark-black text-xs font-semibold'>{moment(item?.createdAt).format("DD MMM, YYYY")}</td>
                                        </tr>
                                        <tr className='flex items-center gap-1'>
                                            <td className='flex justify-between items-center text-xs font-medium text-cloud-gray'>Repair time :</td>
                                            <td className='text-dark-black text-xs font-semibold'>{item?.repairTime ? item?.repairTime : '00:00:00'}</td>
                                        </tr>
                                        <tr className='flex items-center gap-1'>
                                            <td className='flex justify-between items-center text-xs font-medium text-cloud-gray'> Status :</td>
                                            <td className="">{item.status == 0 ? <button className='bg-light-green-gray py-2 px-4 rounded-[63px] text-cloud-gray text-sm sm:text-base font-semibold'>Not Resolved</button> : <button className='bg-dark-light-green text-sm sm:text-base py-2 px-4 rounded-[63px] text-green-grey font-semibold'>Resolved</button>}</td>
                                        </tr>
                                        <tr className='flex items-center gap-1'>
                                            <td className='flex justify-between items-center text-xs font-medium text-cloud-gray'> Priority :</td>
                                            <td>{item?.priority == 0 ? <button className='bg-dark-light-green text-sm sm:text-base py-2 px-4 rounded-[63px] text-red font-semibold'>Heigh</button> : data?.priority == 1 ? <button className='bg-light-green-gray py-[3px] px-[10px] rounded-[63px] text-yellow font-semibold'>Medium</button> : <button className='bg-dark-light-yellow text-sm sm:text-base py-2 px-4 rounded-[63px] text-light-yellow font-semibold'>Low</button>}</td>
                                        </tr>
                                        <tr className='flex items-center gap-1'>
                                            <td className='flex justify-between items-center text-xs font-medium text-cloud-gray'> Assignee :</td>
                                            <td className="text-sm text-dark-black font-semibold whitespace-nowrap min-w-[200px]"><div className='flex items-center gap-2'><img className='w-9 h-9 rounded-full object-cover' src={item.assignee ? item.assignee?.profile_img || userProfile : ''} alt="" />{item.assignee?.username}</div>
                                            </td>
                                          
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )) : <td colSpan="12" className='text-center text-sm text-cloud-gray font-semibold pb-3'>Data not found</td>}
                        <button className='text-white bg-camo-green py-[14px] rounded-lg text-center text-sm font-semibold' onClick={handlePaginationView} >View More</button>
                    </div>
                </div>
                {/* <div className='p-5 mt-5 bg-white rounded-[10px] block md:hidden'>

                    <div className='pb-5 flex items-center justify-between text-lg'>
                        <div className='relative flex md:block  w-full md:w-auto'>
                            <div className='absolute top-1/2 -translate-y-1/2 left-4'>
                                <Search className="w-4 sm:w-5" />
                            </div>
                            <input type="text" placeholder='Search name, mail ...' value={searchParams.search} onChange={(e) => setSearchParams({ ...searchParams, search: e.target.value })} className='border border-moon-gray rounded-lg text-sm font-medium 2xl:min-w-[420px] pl-11 py-[10px] placeholder:text-cloud-gray w-full md:w-auto sm:min-w-[420px]' />
                        </div>
                    </div>

                </div> */}
                <div className='hidden items-center justify-between pt-4 w-full md:flex'>
                    <button className='flex items-center text-cloud-gray text-sm font-semibold gap-2' onClick={currentPage > 1 ? handlePriviousClick : undefined}> <Leftarrow className="w-6" /> PREV</button>
                    <ul className='flex items-center'>
                        {paginationItems()}
                    </ul>
                    <button className='flex items-center text-cloud-gray text-sm font-semibold gap-2' onClick={currentPage < totalPages ? handleNextClick : undefined}>NXT <Rightlongarrow className="w-6" /></button>
                </div>
            </div>
        </>
    )
}
export default Issuereport;