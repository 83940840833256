import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Sidebar from '../Components/Sidebar/Sidebar';
import Support from '../Components/Support/Support';
import Supportticket from '../Components/Supportticket/Supportticket';
import Header from '../Components/Header/Header';

const supportticketpage = () => {
  return (
    <>
      <div className='bg-light-gray h-screen'>
        <Sidebar />
        <div className='w-full lg:w-[calc(100%_-_350px)] ml-auto h-full overflow-auto flex flex-col'>
          <Header title="Support and Feedback" />
          <Routes>
            <Route path="/*" element={<Support />} />
            <Route path="/supportdetail" element={<Supportticket />} />
          </Routes>
        </div>
      </div>
    </>
  )
}

export default supportticketpage;
