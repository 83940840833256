import React, { useEffect } from 'react';
import { Dialog } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUserById, getUserById } from '../../store/user/user.action';
import { DELETE_USER, GET_USER_OBJECT } from '../../store/user/user.type';
import moment from 'moment';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import { CloseIcon, Deleteicon, Editicon, Leftangle, Search } from '../../Assest/Allsvg';
import Header from '../Header/Header';

const Userdetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const item = useSelector((state) => state.user.userObject);
    useEffect(() => {
        if (item && item._id) {
            localStorage.setItem('userId', item._id);
        }
    }, [item]);
    useEffect(() => {
        const userId = localStorage.getItem('userId');
        if (userId) {
            dispatch(getUserById({ user_id: userId }));
        }
    }, [dispatch]);
    const handleDelete = () => {
        dispatch({ type: DELETE_USER, payload: { id: item._id } })
        dispatch(deleteUserById({ user_id: item._id }, navigate));
    }
    const handleUpdate = () => {
        dispatch({ type: GET_USER_OBJECT, payload: { id: item._id } })
    }
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleClickDelete = () => {
        handleClose()
        handleDelete()
    }
    return (
        <>
            <Header title="User Management" />
            <div className='px-4 py-5 sm:px-5 sm:py-6 flex-1 flex flex-col '>
                {/* <div className='relative md:hidden flex mb-5'>
                    <div className='absolute top-1/2 -translate-y-1/2 left-4'>
                        <Search className="w-5 h-5" />
                    </div>
                    <input type="text" placeholder='Search here...' className='border border-cloud-light-gray rounded-lg w-full py-[14px] text-cloud-gray placeholder:text-cloud-gray placeholder:font-medium pl-11 text-xs' />
                </div> */}
                <div className='bg-white md:bg-transparent flex-1 p-4 sm:p-5 md:p-0 rounded-[10px] flex flex-col'>
                    <h4 className='text-sm md:text-xl text-dark-black font-bold flex items-center gap-2 sm:gap-3'> <Link href="#" to="/user" ><Leftangle className="w-5 sm:w-6" /></Link > | User Detail</h4>
                    <div className='md:bg-white rounded-[10px] md:mt-5 md:px-5 pt-2 sm:pt-4 md:py-5 flex-1'>
                        <div className='md:flex hidden justify-between items-start pb-5'>
                            <div className='flex items-center gap-4 bg-light-gray text-dark-black md:bg-transparent rounded-lg flex-col md:flex-row'>
                                <img src={item?.profile_img} alt="" className='w-[80px] h-[80px] rounded-full object-cover' />
                                <h5 className='text-sm capitalize font-bold md:text-lg lg:text-xl'>{item?.username}</h5>
                            </div>
                            <div className='flex items-center gap-2'>
                                <Link href="#" to="/user/edituserdetail" className='border border-moon-gray bg-white-lilac p-[10px] rounded-lg' onClick={handleUpdate}><Editicon /></Link>
                                <Link href="#" onClick={handleClickOpen} className='bg-light-red border border-coral-red-light p-[10px] rounded-lg'><Deleteicon /></Link>
                                <React.Fragment>
                                    <Dialog
                                        className="deletemodal"
                                        fullScreen={fullScreen}
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="responsive-dialog-title"
                                    >
                                        <button onClick={handleClose} className='absolute right-5 hidden md:block'><CloseIcon /></button>
                                        <h3 className='text-lg md:text-[26px] font-bold md:font-semibold text-wood-smoke text-center pb-[6px] md:pb-6'>Delete User</h3>
                                        <h5 className='text-cloud-gray text-xs text-center md:text-lg font-semibold leading-6'>Are you sure you want to Delete this user ?</h5>
                                        <div className='pt-[18px] md:pt-10 flex items-center justify-center gap-5'>
                                            <button className='bg-amour-gray text-camo-green text-sm md:text-lg font-semibold min-w-[143px] md:min-w-[154px] py-3 px-10 md:px-12 rounded-lg' autoFocus onClick={handleClose} >
                                                Cancel
                                            </button>
                                            <button onClick={handleClickDelete} autoFocus className='bg-red-orange text-white text-sm md:text-lg font-semibold min-w-[143px] md:min-w-[154px] py-3 rounded-lg'>
                                                Delete
                                            </button>
                                        </div>
                                    </Dialog>
                                </React.Fragment>
                            </div>
                        </div>
                        <div className='border-t md:border-none border-t-amour-gray pt-4 md:pt-0 pb-[18px] gap-4 md:gap-6 flex justify-center flex-col rounded-lg'>
                            <div className='flex items-center gap-2 text-dark-black font-semibold text-sm md:hidden'>
                                <img className='w-10 h-10 rounded-full' src={item?.profile_img} alt="" />
                                <div className='flex flex-col'>
                                    {item?.username}
                                    {item?.isOnline == 1 ?
                                        <button className='flex items-center gap-1 text-green font-semibold text-xs'>
                                            <span className='block w-[6px] h-[6px] rounded-full bg-green' ></span> Active</button> : <button className='flex items-center gap-1 rounded-[63px] text-green-grey font-semibold text-xs'><span className='block w-[6px] h-[6px] bg-green-grey rounded-full' ></span> InActive</button>}
                                </div>
                            </div>
                            <table>
                                <tbody className='flex justify-center flex-col gap-[10px] md:gap-3'>
                                    <tr className='flex items-center gap-1 md:gap-6'>
                                        <td className='md:min-w-[180px] flex justify-between items-center text-xs md:text-sm font-medium md:font-semibold text-cloud-gray md:leading-[normal]'>Name <span>:</span></td>
                                        <td className='text-dark-black text-xs md:text-sm font-semibold leading-[normal]'>{item?.name}</td>
                                    </tr>
                                    <tr className='flex items-center gap-1 md:gap-6'>
                                        <td className='md:min-w-[180px] flex justify-between items-center text-xs md:text-sm font-medium md:font-semibold text-cloud-gray md:leading-[normal]'>Email <span>:</span></td>
                                        <td className='text-dark-black text-xs md:text-sm font-semibold md:leading-[normal]'>{item?.email}</td>
                                    </tr>
                                    <tr className='flex items-center gap-1 md:gap-6'>
                                        <td className='md:min-w-[180px] flex justify-between items-center text-xs md:text-sm font-medium md:font-semibold text-cloud-gray md:leading-[normal]'>Phone Number <span>:</span></td>
                                        <td className='text-dark-black text-xs md:text-sm font-semibold md:leading-[normal]'>{item?.contact_no || ''}</td>
                                    </tr>
                                    <tr className='md:flex items-center gap-1 md:gap-6 hidden'>
                                        <td className='md:min-w-[180px] flex justify-between items-center text-xs md:text-sm font-medium md:font-semibold text-cloud-gray md:text-camo-green'>Status <span>:</span></td>
                                        <td>{item?.isOnline == 1 ? <button className='bg-dark-light-green py-[6px] px-4 rounded-[63px] text-green font-semibold text-sm'>Active</button> : <button className='bg-dark-light-green py-[6px] px-4 rounded-[63px] text-green-grey font-semibold text-sm'>InActive</button>}</td>
                                    </tr>
                                    <tr className='flex items-center gap-1 md:gap-6'>
                                        <td className='md:min-w-[180px] flex justify-between items-center text-xs md:text-sm font-medium md:font-semibold text-cloud-gray md:leading-[normal]'>Created Date <span>:</span></td>
                                        <td className='text-dark-black text-xs md:text-sm font-semibold md:leading-[normal]'>{moment(item?.createdAt).format("DD MMM, YYYY")}</td>
                                    </tr>
                                    <tr className='flex items-center gap-1 md:gap-6'>
                                        <td className='md:min-w-[180px] flex justify-between items-center text-xs md:text-sm font-medium md:font-semibold text-cloud-gray md:leading-[normal]'>Plan Status <span>:</span></td>
                                        <td className="">{item?.plan == null ? <button className='bg-light-green-gray py-[4px] px-3 rounded-[63px] text-green-grey font-semibold text-xs md:text-sm md:eading-[normal]'>UnPaid</button> : <button className='bg-light-green-gray py-[6px] px-4 rounded-[63px] text-green-grey font-semibold text-xs md:text-sm md:eading-[normal]'>Paid</button>}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='m-auto gap-[10px] flex items-center md:hidden'>
                            <Link href="#" to="/user/edituserdetail" className='bg-amour-gray min-w-28 sm:min-w-[144px] flex items-center justify-center text-camo-green text-sm font-semibold py-3 rounded-md' autoFocus onClick={handleUpdate} >
                                Edit
                            </Link>
                            <Link onClick={handleClickOpen} className='bg-red-orange min-w-28 sm:min-w-[144px] flex items-center justify-center text-white text-sm font-semibold py-3 rounded-md'>
                                Delete
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Userdetails;