import axios from 'axios';

// Set the base URL for all Axios requests
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

// Use Axios interceptors to append the Authorization header dynamically
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Export the axios instance for use throughout your app
export default axios;