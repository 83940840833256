import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    GoogleMap,
    LoadScript,
    Polygon,
    Marker,
    Autocomplete,
} from '@react-google-maps/api';
import { Grid, Switch } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Leftangle, PhotosIcon, Editicon } from '../../Assest/Allsvg';
import MapComponent from '../mapComponent/mapCompnent';
import { editClub, getCategory, getClubObject } from '../../store/club/club.action';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import { validateName } from '../validaiton';
import clubProfile from '../../Assest/clubDefaultImage.png';
import Header from '../Header/Header';

const Editclubdetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const club = useSelector((state) => state.club.clubObject);
    const category = useSelector((state) => state.club.category);
    const addClubError = useSelector((state) => state.club.addClubError);
    const [name, setName] = useState('');
    const [category_id, setCategory_id] = useState('');
    const [about, setAbout] = useState(club?.about);
    const [maxCheckInTime, setMaxCheckInTime] = useState('');
    const [club_id, setClub_id] = useState('');
    const [club_privacy, setClub_privacy] = useState(club?.club_privacy ? true : false);
    const [global, setGlobal] = useState(club?.global ? true : false);
    const [lat_long, setLat_long] = useState([]);
    const [undoLatLong, setUndoLatLong] = useState([])
    const [lat, setLat] = useState(0);
    const [lng, setLong] = useState(0);
    const [imagePreview, setImagePreview] = useState();
    const [error, setError] = useState({});
    const [image, setImage] = useState(null);
    const [autocomplete, setAutocomplete] = useState(null);
    const [map, setMap] = useState(null);
    useEffect(() => {
        if (club) {
            localStorage.setItem('club_id', club?._id)
            setName(club?.name);
            setCategory_id(club?.category_id?._id);
            setAbout(club?.about);
            setMaxCheckInTime(club?.maxCheckInTime);
            setClub_privacy(club?.club_privacy ? true : false);
            setGlobal(club?.global ? true : false);
            setImagePreview(club?.club_profile);
            setClub_id(club?._id)
        }
    }, [club])
    useEffect(() => {
        const data = {
            club_id: localStorage.getItem('club_id')
        }
        dispatch(getClubObject(data))
        dispatch(getCategory())
    }, [dispatch])
    const handleSubmit = () => {
        const obj = {}
        if (!name) {
            obj.name = 'Please enter name'
        }
        if (name && !validateName(name)) {
            obj.name = 'Please enter valid name'
        }
        if (!about) {
            obj.description = 'Please enter description'
        }
        setError(obj)
        const formdata = new FormData()
        const privt = club_privacy ? 1 : 0
        const Global = global ? 1 : 0
        formdata.append('name', name)
        formdata.append('category_id', category_id)
        formdata.append('about', about)
        formdata.append('maxCheckInTime', maxCheckInTime)
        formdata.append('club_privacy', privt)
        formdata.append('club_id', club_id)
        if (lat_long.length > 0) {
            formdata.append('lat_long1', JSON.stringify([...lat_long, lat_long[0]]))
            formdata.append('lng', lng)
            formdata.append('lat', lat)
        }
        if (image) {
            formdata.append('club_profile', image)
        }
        if (Object.keys(obj).length == 0) {
            dispatch(editClub(formdata, navigate))
        }
    }
    const fileInputRef = useRef(null);

    const handleImageChange = e => {
        const file = e.target.files[0];
        setImage(file)
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const [editMap, setEditMap] = useState(false);
    const mapContainerStyle = {
        height: "400px",
        width: "100%"
    };
    const center = useMemo(() => ({ lat: club?.lat_long?.coordinates[1], lng: club?.lat_long?.coordinates[0] }), [club?.lat_long])
    const handleClickMap = (e) => {
        var newArray = [e.latLng.lng(), e.latLng.lat()];
        setLat_long([...lat_long, newArray])
        setLong(e.latLng.lng())
        setLat(e.latLng.lat())

    }

    const onPlaceChanged = () => {
        try {
            if (autocomplete !== null) {
                const place = autocomplete.getPlace();
                const location = place.geometry.location;
                const newCenter = {
                    lat: location.lat(),
                    lng: location.lng(),
                };
                map.panTo(newCenter);
            } else {
                throw new Error('Autocomplete is not loaded yet!');
            }
        } catch (error) {
            setError({ lat_long: error.message })
        }

    };

    const undoCoordinates = () => {
        const lastLatLng = lat_long[lat_long.length - 1];

        setLat_long(prevState => prevState.slice(0, -1));

        if (lastLatLng) {
            setUndoLatLong(pre => [...pre, lastLatLng]);
        }
    };

    const addUndoCoordinates = () => {
        if (undoLatLong.length > 0) {
            const lastUndoLatLng = undoLatLong[undoLatLong.length - 1];

            setLat_long(prevState => [...prevState, lastUndoLatLng]);

            setUndoLatLong(pre => pre.slice(0, -1));
        }
    };
    return (
        <>
            <Header title="Club Management" />
            <div className='flex flex-col py-6 px-5 h-[calc(100%_-_65px)] md:h-[calc(100%_-_85px)]'>
                <div className='flex flex-col flex-1 overflow-auto bg-white py-5 rounded-[10px] md:py-0 md:bg-transparent'>
                    <h4 className='flex items-center justify-start text-dark-black text-sm font-bold pb-5 px-5 gap-3 md:text-xl md:px-0'>
                        <Link to="/club" className="hidden md:block"><Leftangle className="w-6" /></Link> | Edit Club Detail</h4>
                    <div className='flex flex-col overflow-auto px-4 md:!px-5 h-full rounded-[10px] md:bg-white md:py-5'>
                        <div className='gap-4 flex md:pb-4 md:mb-0'>
                            <div className='relative w-[84px] h-[84px] bottom-0 mb-4'>
                                <img src={imagePreview || clubProfile} alt="" className='h-full w-full object-cover rounded-full' />
                                <div className='flex  items-center justify-center absolute bottom-0 end-0 border border-pele-light-gray bg-white rounded-full w-8 h-8' onClick={() => fileInputRef.current.click()}>
                                    <PhotosIcon />
                                </div>
                            </div>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleImageChange}
                            />
                        </div>
                        <div className='flex flex-col flex-1 justify-between'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={4}>
                                    <div className='flex flex-col gap-[6px]'>
                                        <label className='block text-dark-black text-[13px] font-semibold md:text-sm'>Name</label>
                                        <input type="text" placeholder='Please Enter Name' className='border border-spring-rain text-xs 
                                             py-2 pl-3 rounded-lg leading-none min-h-10 w-full placeholder:text-dark-black
                                            placeholder:font-medium md:text-sm' value={name} onChange={(e) => setName(e.target.value)} />
                                        {error && <p className='text-red text-sm pt-1'>{error.name}</p>}
                                    </div>
                                </Grid>
                                {/* <Grid item xs={12} lg={4}>
                                    <div className='flex  flex-col gap-2'>
                                        <label className='block text-dark-black text-[13px] font-semibold md:text-sm'>Select Setup</label>
                                        <select className="border border-spring-rain text-xs py-2 pl-3 rounded-lg leading-none   min-h-10 md:text-sm">
                                            <option value="option1">Easy setup</option>
                                            <option value="option2">Option 2</option>
                                            <option value="option3">Option 3</option>
                                        </select>
                                    </div>
                                </Grid> */}
                                <Grid item xs={12} lg={4}>
                                    <div className='flex  flex-col gap-[6px] '>
                                        <label className='block text-dark-black text-[13px] font-semibold md:text-sm'>Category</label>
                                        <select className="border border-spring-rain text-dark-black text-xs py-2 pl-3 rounded-lg leading-none  
                                                min-h-10 font-medium md:text-sm" value={category_id} onChange={(e) => setCategory_id(e.target.value)}>
                                            {
                                                category?.map((data, index) => (
                                                    <option key={index} value={data?._id}>{data?.name} </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <div className='flex flex-col gap-[6px]'>
                                        <label className='block text-dark-black text-[13px] font-semibold md:text-sm'>Max Check In Time (optional)</label>
                                        <input type="time" id="timeInput" name="time" placeholder="HH:MM" className='border border-spring-rain text-xs py-2 px-5 rounded-lg leading-none min-h-10 w-full placeholder:text-dark-black placeholder:font-medium md:text-sm' value={maxCheckInTime || ''} onChange={(e) => setMaxCheckInTime(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <div className='flex flex-col gap-[6px]'>
                                        <label className='block text-dark-black text-[13px] font-semibold md:text-sm'>Description</label>
                                        <textarea className='border border-spring-rain text-xs py-2 pl-3 rounded-lg min-h-10 w-full placeholder:text-dark-black placeholder:font-medium md:text-sm' rows='5' value={about} onChange={(e) => setAbout(e.target.value)} placeholder="Please Enter Description"></textarea>
                                        {error && <p className='text-red text-sm pt-1'>{error.description}</p>}
                                    </div>
                                </Grid>

                                <Grid item xs={12} lg={4}>
                                    <div>
                                        <label className='block text-dark-black font-semibold'>Club Privacy</label>
                                        <div className='flex items-center justify-between'>
                                            <span className='text-dark-black text-sm font-medium leading-[normal]'>Private</span>
                                            <Switch checked={club_privacy} value={club_privacy} onChange={() => setClub_privacy(!club_privacy)} />
                                        </div>
                                        <p className='text-cloud-gray text-xs font-medium leading-[normal] md:text-sm'>Only visible to those you share with</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <div>
                                        {/* <label className='block text-dark-black font-semibold'>Allowed Property Lines</label>
                                        <div className='flex items-center justify-between'>
                                            <span className='text-dark-black text-sm font-medium leading-[normal]'>Global</span>
                                            <Switch checked={global} value={global} onChange={() => setGlobal(!global)} />
                                        </div>
                                        <p className='text-cloud-gray text-xs font-medium leading-[normal] md:text-sm'>Allow members to draw property line within</p> */}
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className=' flex-col gap-[6px] md:flex lg:col-span-2'>
                                        <button
                                            onClick={() => setEditMap(!editMap)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            {editMap ? (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Leftangle className="w-6" />
                                                    <h6>Show Old Area</h6>
                                                </div>
                                            ) : (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Editicon className="float-left" style={{ marginRight: '5px' }} />
                                                    <h6>Edit map</h6>
                                                </div>
                                            )}
                                        </button>
                                        {editMap ? (
                                            <div style={mapContainerStyle}>
                                                <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={['places']}>
                                                    <GoogleMap
                                                        mapContainerStyle={mapContainerStyle}
                                                        center={center}
                                                        zoom={10}
                                                        onClick={handleClickMap}
                                                        onLoad={(mapInstance) => setMap(mapInstance)}
                                                    >
                                                        <Marker position={center} />

                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                top: '10px',
                                                                left: '50%',
                                                                transform: 'translateX(-50%)',
                                                                zIndex: '1',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            {/* Search Bar with Icon */}
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                                                                    borderRadius: '5px',
                                                                    backgroundColor: '#fff',
                                                                    marginBottom: '10px', // Space between the search bar and the second icon
                                                                }}
                                                            >
                                                                <svg
                                                                    width="22"
                                                                    height="22"
                                                                    viewBox="0 0 22 22"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    style={{
                                                                        marginRight: '8px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    <path
                                                                        d="M10.5413 19.2497C15.3508 19.2497 19.2497 15.3508 19.2497 10.5413C19.2497 5.73186 15.3508 1.83301 10.5413 1.83301C5.73186 1.83301 1.83301 5.73186 1.83301 10.5413C1.83301 15.3508 5.73186 19.2497 10.5413 19.2497Z"
                                                                        stroke="#686868"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                    <path
                                                                        d="M20.1663 20.1663L18.333 18.333"
                                                                        stroke="#686868"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                                <Autocomplete
                                                                    onLoad={(autocompleteInstance) => setAutocomplete(autocompleteInstance)}
                                                                    onPlaceChanged={onPlaceChanged}
                                                                >
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Search for a location"
                                                                        style={{
                                                                            boxSizing: 'border-box',
                                                                            border: '1px solid transparent',
                                                                            width: '240px',
                                                                            height: '32px',
                                                                            fontSize: '14px',
                                                                            outline: 'none',
                                                                            textOverflow: 'ellipsis',
                                                                            borderRadius: '3px 0 0 3px',
                                                                        }}
                                                                    />
                                                                </Autocomplete>
                                                            </div>
                                                            {
                                                                lat_long?.length > 0 &&
                                                                <>
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            width: '30px',
                                                                            height: '32px',
                                                                            borderRadius: '5px',
                                                                            backgroundColor: '#fff',
                                                                            marginBottom: '10px',
                                                                            marginLeft: '10px',
                                                                        }}
                                                                    >
                                                                        <button type="button" onClick={undoCoordinates}>
                                                                            <svg
                                                                                width="18"
                                                                                height="18"
                                                                                viewBox="0 0 18 20"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M9.00005 3.58974V0L3.87185 5.12821L9.00005 10.2564V5.64103C12.3847 5.64103 15.1539 8.41026 15.1539 11.7949C15.1539 15.1795 12.3847 17.9487 9.00005 17.9487C5.61543 17.9487 2.8462 15.1795 2.8462 11.7949H0.794922C0.794922 16.3077 4.48723 20 9.00005 20C13.5129 20 17.2052 16.3077 17.2052 11.7949C17.2052 7.28205 13.5129 3.58974 9.00005 3.58974Z"
                                                                                    fill="#363636"
                                                                                />
                                                                            </svg>
                                                                        </button>
                                                                    </div>

                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            width: '30px',
                                                                            height: '32px',
                                                                            borderRadius: '5px',
                                                                            backgroundColor: '#fff',
                                                                            marginBottom: '10px',
                                                                            marginLeft: '10px',
                                                                        }}
                                                                    >
                                                                        <button type="button" onClick={addUndoCoordinates}>
                                                                            <svg
                                                                                width="18"
                                                                                height="18"
                                                                                viewBox="0 0 18 20"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.99995 3.58974V0L14.1282 5.12821L8.99995 10.2564V5.64103C5.61533 5.64103 2.8461 8.41026 2.8461 11.7949C2.8461 15.1795 5.61533 17.9487 8.99995 17.9487C12.3846 17.9487 15.1538 15.1795 15.1538 11.7949H17.2051C17.2051 16.3077 13.5128 20 8.99995 20C4.48713 20 0.794821 16.3077 0.794821 11.7949C0.794821 7.28205 4.48713 3.58974 8.99995 3.58974Z"
                                                                                    fill="#363636"
                                                                                />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            }

                                                        </div>

                                                        {lat_long.length > 0 && (
                                                            <Polygon
                                                                paths={lat_long.map((coord) => ({ lng: coord[0], lat: coord[1] }))}
                                                                options={{
                                                                    fillColor: 'lightblue',
                                                                    fillOpacity: 0.5,
                                                                    strokeColor: 'red',
                                                                    strokeOpacity: 1,
                                                                    strokeWeight: 2,
                                                                }}
                                                            />
                                                        )}
                                                    </GoogleMap>
                                                </LoadScript>
                                            </div>
                                        ) :
                                            <MapComponent marker={club?.lat_long} polyog={club?.lat_long1} mapSize={mapContainerStyle} />
                                        }
                                    </div>
                                    <span className='text-[red]'>{addClubError}</span>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className='grid grid-cols-2 items-center justify-center text-center pt-5 gap-4 md:flex md:gap-7'>
                                        <Link href="" to="/club" className='border border-camo-green text-camo-green text-sm 
                                    text-center font-semibold py-3 box-border rounded-lg min-w-[50%] leading-[100%]  md:text-base md:min-w-[150px]'>Cancel</Link>
                                        <Link onClick={handleSubmit} className='border border-transparent bg-camo-green text-white text-sm 
                                    text-center font-semibold py-3 box-border rounded-lg min-w-[50%] leading-[100%] md:text-base md:min-w-[150px]'>Save</Link>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Editclubdetails;