import React, { useState } from 'react';
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { addNotification } from '../../store/notification/notification.action';
import Header from '../Header/Header';

const Addnewnotification = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [scheduleStatus, setSchedualeStatus] = useState(0);
    const [discription, setDiscription] = useState('');
    const [scheduleTime, setScheduleTime] = useState('');
    const [scheduleDate, setScheduleDate] = useState('');
    const [error, setError] = useState({});
    const handleSubmit = () => {
        let newErrors = {};
        if (scheduleStatus == 1) {
            if (!scheduleTime) {
                newErrors.scheduleTime = 'Schedule time must be required for type schedule';
            }
            if (!scheduleDate) {
                newErrors.scheduleDate = 'Date must be required';
            }
        }
        if (!discription) {
            newErrors.discription = 'Description message is required';
        }
        const displayTime = scheduleTime ? moment(scheduleTime, 'HH:mm').format('hh:mm A') : '';
        const data = {
            scheduleStatus, discription, scheduleTime: displayTime, scheduleDate
        }
        setError(newErrors);
        if (Object.keys(newErrors).length == 0) {
            dispatch(addNotification(data, navigate))
        }
    }
    return (
        <>
            <Header title="Notification and Alerts" />
            <div className='flex flex-col p-4 sm:px-5 sm:py-6 h-[calc(100%_-_65px)] md:h-[calc(100%_-_85px)]'>
                <div className='flex flex-col flex-1 overflow-auto bg-white py-5 rounded-[10px] md:bg-transparent md:py-0'>
                    <h4 className='px-5 md:px-0 text-sm md:text-xl  text-dark-black font-bold flex items-center justify-start gap-3 pb-5'> <Link href="" to="/usermanagement" className="hidden md:block"></Link>| Add New Notification</h4>
                    <div className='flex flex-col overflow-auto h-full rounded-[10px] !px-5 md:bg-white md:py-6'>
                        <div className='flex flex-col h-full md:justify-between'>
                            <div className=''>
                                <Grid container spacing={2} className='flex-1'>
                                    <Grid item xs={12} xl={8}>
                                        <Grid container spacing={2} className='flex-1'>
                                            <Grid item xs={12} md={6} >
                                                <div className='flex flex-col gap-2 sm:gap-1'>
                                                    <label className='block text-dark-black font-semibold text-sm lg:text-base'>Created Date</label>
                                                    <input type="date" value={scheduleDate} onChange={(e) => setScheduleDate(e.target.value)} className="border border-spring-rain w-full text-xs py-2 px-4 rounded-lg leading-none md:text-sm" />
                                                    <p className='text-red text-sm'>{error.scheduleDate}</p>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={6} >
                                                <div className='flex flex-col gap-2 sm:gap-1'>
                                                    <label className='block text-dark-black font-semibold text-sm lg:text-base'>Alert Time (optional)</label>
                                                    <input type="time" placeholder="Select a time" value={scheduleTime} onChange={(e) => setScheduleTime(e.target.value)} className='border border-spring-rain w-full text-xs py-2 px-4 rounded-lg leading-none md:text-sm' />
                                                    <p className='text-red text-sm'>{error.scheduleTime}</p>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={6} >
                                                <div className='flex flex-col gap-2'>
                                                    <label className='block text-dark-black font-semibold text-sm lg:text-base'>Description</label>
                                                    <textarea className='border border-spring-rain text-cloud-gray text-xs font-medium py-2 pl-3 rounded-lg min-h-10 w-full md:text-sm' value={discription} onChange={(e) => setDiscription(e.target.value)} name="" id="" cols='10' rows='7' placeholder="Please enter description..."></textarea>
                                                    <p className='text-red text-sm'>{error.discription}</p>
                                                </div>
                                            </Grid>             
                                            <Grid item xs={12} md={6} >
                                                <div className='flex flex-col gap-2'>
                                                    <label className='block text-dark-black font-semibold text-sm lg:text-base'>Notification</label>
                                                    <FormControl>
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                            value={scheduleStatus} onChange={(e) => setSchedualeStatus(parseInt(e.target.value))}
                                                            defaultValue="Send Now"
                                                            className='radio_button'
                                                        >
                                                            <FormControlLabel value="0" control={<Radio className='mr-3' />} label="Send Now" />
                                                            <FormControlLabel value="1" control={<Radio />} label="Schedule Now" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className='grid grid-cols-2 items-center justify-center text-center pt-7 gap-4 md:flex md:pt-0 md:gap-7'>
                                <Link href="" to="/notification" className='border border-camo-green text-camo-green text-sm font-semibold py-3 px-12 box-border rounded-lg min-w-[50%] leading-[100%] md:text-base md:min-w-[180px]'>Cancel</Link>
                                <Link className='border border-transparent bg-camo-green text-white text-sm font-semibold py-3 px-12 box-border rounded-lg min-w-[50%] leading-[100%] md:text-base md:min-w-[180px]' onClick={handleSubmit}>Add</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Addnewnotification;