import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Action, CloseIcon, CrossIcon, FilterIcon, Leftarrow, Listmenu, Rightlongarrow, Search } from '../../Assest/Allsvg';
import Clubfilter from '../Clubfilter/Clubfilter';
import Clubaction from '../Clubaction/Clubaction';
import clubProfile from '../../Assest/clubDefaultImage.png';
import { getClubList } from '../../store/club/club.action';
import { CLEAR_FILTERS } from '../../store/club/club.type';
import Header from '../Header/Header';

const Clublistgrid = () => {
    const [Id, setId] = useState('');
    const dispatch = useDispatch();
    const location = useLocation();
    const club = useSelector((state) => state.club.club);
    const total = useSelector((state) => state.club.totalPage);
    // param
    const [searchParms, setSearchParams] = useState({
        sort: 0,
        search: '',
        page: 1,
        limit: 12,
        startDate: 'ALL',
        endDate: 'ALL'
    })
    // filter data
    const filterData = useSelector((state) => state.club.filterData);
    useEffect(() => {
        if (location.pathname === '/club/clublistgrid') {
            const defaultState = {
                sort: 3,
                startDate: 'ALL',
                endDate: 'ALL',
    
            };
            dispatch({ type: CLEAR_FILTERS, payload: defaultState });

        }
    }, [location.pathname, dispatch]);
    useEffect(() => {
        if (filterData) {
            setSearchParams((prevParams) => ({
                ...prevParams,
                sort: filterData.sort,
                startDate: filterData.startDate,
                endDate: filterData.endDate
            }))
        }
    }, [filterData])
    useEffect(() => {
        dispatch(getClubList(searchParms))
    }, [searchParms, dispatch])
    const totalPages = Math.ceil(total / searchParms.limit);
    const [currentPage, setCurrentPage] = useState(1);

    // pagination
    const paginationItems = () => {
        let items = [];
        for (let number = 1; number <= totalPages; number++) {
            if (number === 1 || number === totalPages || (number >= currentPage - 1 && number <= currentPage + 1)) {
                items.push(
                    <li key={number}
                        className={`p-2 ${number === currentPage ? 'bg-amour-gray' : 'text-cloud-gray'} font-semibold rounded w-8 h-8 flex items-center justify-center`}
                        onClick={() => handleSelectPage(number)}>
                        {number}
                    </li>
                );
            } else if (number === currentPage - 2 || number === currentPage + 2) {
                if (items[items.length - 1].key !== '...') { // To avoid consecutive "..."
                    items.push(
                        <li key={'...' + number} className='p-2 text-cloud-gray font-semibold rounded w-8 h-8 flex items-center justify-center'>...</li>
                    );
                }
            }
        }
        return items;
    };
    // next button
    const handleNextClick = () => {
        if (currentPage < totalPages) {
            setSearchParams(prevParams => ({
                ...prevParams,
                page: prevParams.page + 1
            }));
        }
    };
    // prev button
    const handlePriviousClick = () => {
        if (currentPage > 1) {
            setSearchParams(prevParams => ({
                ...prevParams,
                page: prevParams.page - 1
            }));
        }
    };
    const handleSelectPage = (number) => {
        setSearchParams(prevParams => ({
            ...prevParams,
            page: number
        }));
    };
    useEffect(() => {
        setCurrentPage(searchParms.page);
    }, [searchParms.page]);
    // filter modal
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClearSearch = () => {
        setSearchParams(prevParams => ({
            ...prevParams,
            search: ''
        }))
    }
    // action popover
    const [anchoractionEl, setactionanchorEl] = React.useState(null);
    const handleactionClick = (event, data) => {
        setactionanchorEl(event.currentTarget);
        setId(data)
    };
    const handleactionClose = () => {
        setactionanchorEl(null);
    };
    return (
        <>
            <div className='hidden md:block'>
                <Header title="Club Management" />
            </div>
            <div className='flex flex-col h-full md:px-5 md:pb-6 md:h-[calc(100%_-_73px)]'>
                <div className='flex flex-col h-full bg-white p-4 sm:p-5 rounded-[10px] md:bg-transparent md:p-0'>
                    <div className='flex items-center justify-between md:pt-6'>
                        <h4 className='text-dark-black text-base font-bold sm:text-xl'>| Club List</h4>
                        <div className='hidden items-center md:flex'>
                            <Link href="#" to="/club/clublistgrid" className={`border border-moon-gray p-[10px] mr-2 rounded-lg 
                         ${location.pathname === '/club/clublistgrid' ? 'bg-camo-green listmenuicon' : 'bg-white'}`}><CrossIcon className="w-5 h-5" /></Link>
                            <Link href="#" to="/club" className={`border border-moon-gray bg-white p-[10px] mr-3 rounded-lg   
                            ${location.pathname === '/club' ? 'bg-camo-green' : ''}`}><Listmenu className="w-5 h-5" /></Link>
                            <Link href="#" to="/club/addclub" className='bg-camo-green text-white text-sm font-semibold py-[10px] 
                            px-8 rounded-lg'>+ Add New Club</Link>
                        </div>
                    </div>
                    <div className="flex flex-col flex-1 overflow-x-auto p-4 rounded-lg md:bg-white md:p-5 md:mt-5">
                        <div className='flex items-center justify-between text-lg pb-4 sm:pb-5'>
                            <div className='relative flex md:block  w-full md:w-auto'>
                                <div className='absolute top-1/2 left-4 -translate-y-1/2'>
                                    <Search className="w-4 sm:w-5" />
                                </div>
                                <input type="text" placeholder='Search name, mail ...' value={searchParms?.search} onChange={(e) => setSearchParams({ ...searchParms, search: e.target.value })} className='border border-moon-gray text-xs font-medium rounded-lg 
                                  py-[10px] pl-11 w-full placeholder:text-cloud-gray  sm:text-sm sm:min-w-[420px] md:w-auto 2xl:min-w-[420px]' />
                                <div className='block absolute top-1/2 right-4 -translate-y-1/2 md:hidden'>
                                    {searchParms?.search ?
                                        <button onClick={handleClearSearch} className='mt-2'>  <CloseIcon className="w-5 h-5" /></button> :
                                        <>
                                            <Link href="#" onClick={handleClick} className='w-[18px]'>
                                                <FilterIcon className="w-5 h-5" />
                                            </Link>
                                            <Clubfilter anchorEl={anchorEl} handleClose={handleClose} />
                                        </>
                                    }
                                </div>
                                {searchParms?.search && <div className='absolute top-1/2 -translate-y-1/2 right-3 mt-1 hidden md:block'>
                                    <button onClick={handleClearSearch}>  <CloseIcon className="w-5 h-5" /></button>
                                </div>}
                            </div>
                            <div className='hidden md:block'>
                                <Link href="#" onClick={handleClick} className='flex items-center border border-moon-gray text-dark-black text-base font-medium py-[10px] px-3 gap-2 rounded-lg leading-[normal] '> <FilterIcon className="w-5 h-5" /> Filters</Link>
                                <Clubfilter className="desktop_filter_modal" anchorEl={anchorEl} handleClose={handleClose} />
                            </div>
                        </div>
                        <div className='overflow-auto flex-1'>
                            <div className={` ${total > 0 ? 'grid md:grid-cols-2 2xl:grid-cols-3 md:gap-4' : 'hello'}`}>
                                {total > 0 ? club.map((item) => (
                                    <div className='flex flex-col justify-center border-b border-amour-gray pb-3 mb-3 gap-[10px] md:border sm:pb-5 sm:mb-5 md:p-4 md:mb-0 md:gap-3'>
                                        <div className='flex items-center text-dark-black text-sm font-semibold gap-2 md:text-base'>
                                            <img className='w-[30px] h-[30px] md:h-9 md:w-9 object-cover rounded-full' src={item?.club_profile || clubProfile} alt="" />{item?.name}
                                            <Link href="#" onClick={(e) => handleactionClick(e, item?._id)} className="ms-auto "><Action className='m-auto w-5' /></Link>
                                            <Clubaction anchoractionEl={anchoractionEl} handleactionClose={handleactionClose} data={Id} />
                                        </div>
                                        <table className='flex flex-col justify-center gap-[6px] md:gap-2'>
                                            <tbody>
                                                <tr className='flex flex-col justify-between gap-2 sm:flex-row sm:items-center md:gap-3'>
                                                    <div className='flex items-center gap-1 sm:gap-4'>
                                                        <td className='flex justify-between items-center text-cloud-gray text-sm font-medium gap-1 md:text-camo-green md:font-semibold xl:min-w-[100px]'>Members <span>:</span> </td>
                                                        <td className='text-dark-black text-sm font-semibold '>{item?.member}</td>
                                                    </div>
                                                    <div className='flex items-center gap-1 sm:gap-3'>
                                                        <td className='flex items-center justify-between text-cloud-gray text-sm font-medium gap-1 md:text-camo-green md:font-semibold xl:min-w-[50px]'>Kills <span>:</span> </td>
                                                        <td className='text-dark-black text-sm font-semibold'>{item?.kill || 0}</td>
                                                    </div>
                                                </tr>
                                                <tr className='flex gap-2 sm:items-center md:gap-3'>
                                                    <td className='flex items-center text-cloud-gray text-sm font-medium gap-1 sm:justify-between md:text-camo-green md:font-semibold xl:min-w-[100px]'>Created at <span>:</span></td>
                                                    <td className='text-dark-black text-sm font-semibold '>{moment(item.createdAt).format("DD MMM, YYYY")}</td>
                                                </tr>
                                                <tr className='flex ap-2 sm:items-center md:gap-3'>
                                                    <td className='flex items-center text-sm font-medium text-cloud-gray  gap-1 sm:justify-between md:text-camo-green md:font-semibold xl:min-w-[100px]'>Created by <span>:</span></td>
                                                    <td className='text-dark-black text-sm font-semibold '>{item?.createdBy}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )) : (<ul className='flex items-center justify-center px-4 md:px-6 w-full '>
                                    <li className='text-sm text-cloud-gray font-semibold'>
                                        Data not found
                                    </li>
                                </ul>)}
                            </div>
                        </div>
                        <div className='hidden items-center justify-between pt-4 w-full md:flex'>

                            <button className='flex items-center text-cloud-gray text-sm font-semibold gap-2' onClick={currentPage > 1 ? handlePriviousClick : undefined}> <Leftarrow className="w-6" /> PREV</button>

                            <ul className='flex items-center'>
                                {paginationItems()}
                            </ul>

                            <button className='flex items-center text-cloud-gray text-sm font-semibold gap-2' onClick={currentPage < totalPages ? handleNextClick : undefined}>NXT <Rightlongarrow className="w-6" /></button>

                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export default Clublistgrid;
