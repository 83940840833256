import React from 'react';
import UserManagement from '../Components/UserManagement/UserManagement';
import { Route, Routes } from 'react-router-dom/dist/umd/react-router-dom.development';
import Addnewuser from '../Components/Addnewuser/Addnewuser';
import Userdetails from '../Components/Userdetails/Userdetails';
import Edituserdetail from '../Components/Edituserdetail/Edituserdetail';
import Userlistdetails from '../Components/Userlistdetails/Userlistdetails';
import Sidebar from '../Components/Sidebar/Sidebar';

const Usermanagementpage = () => {
  return (
    <>
      <div className='bg-light-gray h-screen'>
        <div>
          <Sidebar />
        </div>
        <div className='w-full lg:w-[calc(100%_-_350px)] ml-auto h-full overflow-auto flex flex-col'>
          <Routes>
            <Route path="/*" element={<UserManagement />} />
            <Route path="/userlistdetails" element={<Userlistdetails />} />
            <Route path="/addnewuser" element={<Addnewuser />} />
            <Route path="/userdetails" element={<Userdetails />} />
            <Route path="/edituserdetail" element={<Edituserdetail />} />
          </Routes>
        </div>
      </div>
    </>
  )
}
export default Usermanagementpage;