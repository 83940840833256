import React from 'react'
import { GET_DASHBOARD_DATA, GET_DASHBOARD_DATA_FAIL, GET_USERS_COUNT, LOADER_CLOSE, LOADER_START, SET_ERROR, SET_LOADING } from './dashboard.type'
let inititalState = {
    user: [],
    dashBoardCount: null,
    isLoading: false,
    error: null,
    isLoading: false

}
const dashboardReducer = (state = inititalState, action) => {
    switch (action.type) {
        case GET_DASHBOARD_DATA:
            return {
                ...state,
                user: action.payload
            }

        case GET_DASHBOARD_DATA_FAIL:
            return {
                ...state,
                user: []
            }
        case GET_USERS_COUNT:
            return {
                ...state,
                dashBoardCount: action.payload
            }
        case SET_LOADING:
            return { ...state, isLoading: action.payload };
        case SET_ERROR:
            return { ...state, error: action.payload };


      

        default:
            return state
    }
}

export default dashboardReducer