import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_FILTERS_USER, FILTER_DATA_USER } from '../../store/user/user.type';
import { FormControl, FormControlLabel, Popover, Radio, RadioGroup } from '@mui/material';
import { CloseIcon } from '../../Assest/Allsvg';

const Filtermodal = ({ anchorEl, handleClose, className }) => {
    const dispatch = useDispatch();
    const [sort, setSort] = useState(0);
    const [startDate, setStartDate] = useState('ALL');
    const [endDate, setEndDate] = useState('ALL');
    const [status, setStatus] = useState(2);
    const [plan, setPlan] = useState(1);

    let Data = {
        sort, startDate, endDate, status, plan
    }
    const handleFilter = () => {
        dispatch({ type: FILTER_DATA_USER, payload: Data })
    }
    const handleFilterAnsClose = () => {
        handleFilter()
        handleClose()
    }
    const handleClearFilters = () => {
        const defaultState = {
            sort: 0,
            startDate: 'ALL',
            endDate: 'ALL',
            status: 3,
            plan: 3
        };

        setSort(defaultState.sort);
        setStartDate(defaultState.startDate);
        setEndDate(defaultState.endDate);
        setStatus(defaultState.status);
        setPlan(defaultState.plan);

        dispatch({ type: CLEAR_FILTERS_USER, payload: defaultState });
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <>
            <Popover
                className={`filtermodal ${className}`}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <div className='flex items-center justify-between border-b border-b-amour-gray pt-4 pb-2 px-4'>
                    <h3 className='text-dark-black text-[22px] font-bold leading-[normal]'>Filters</h3>
                    <button onClick={handleClose}><CloseIcon /></button>
                </div>
                <div className='border-b border-b-amour-gray py-5 px-4'>
                    <h4 className='text-dark-black text-xl font-semibold leading-[normal]'>Sort by</h4>
                    <div>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={sort}
                                onChange={(e) => setSort(parseInt(e.target.value))}
                                name="radio-buttons-group"
                                className="gap-3 pt-4"
                            >
                                <FormControlLabel value="0" control={<Radio />} label="Name (A to Z)" />
                                <FormControlLabel value="1" control={<Radio />} label="Name (Z to A)" />
                                <FormControlLabel value="2" control={<Radio />} label="Oldest to Newest" />
                                <FormControlLabel value="3" control={<Radio />} label="Newest to Oldest" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <div className='border-b border-b-amour-gray py-5 px-4 '>
                    <h4 className='text-dark-black text-xl font-semibold leading-[normal]'>Date Range :</h4>
                    <div className="grid grid-cols-2 gap-4">
                        <div className='flex flex-col pt-5'>
                            <label className='text-cloud-gray text-base font-medium pb-[10px] leading-[normal]'>From</label>
                            <input type="date" className='border border-moon-gray py-2 px-3 rounded-md leading-[normal] date_piker' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                        </div>
                        <div className='flex flex-col pt-5'>
                            <label className='text-cloud-gray text-base font-medium pb-[10px] leading-[normal]'>To</label>
                            <input type="date" className='border border-moon-gray py-2 px-3 rounded-md leading-[normal] date_piker' value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className='p-4 border-b border-b-amour-gray'>
                    <h4 className='text-dark-black text-base font-semibold pb-3 leading-[normal]'>Status :</h4>
                    <select className="border border-moon-gray font-medium px-3 py-[10px] text-sm pl-4 rounded-lg leading-[normal] w-full" value={status} onChange={(e) => setStatus(parseInt(e.target.value))}>
                        <option value="2" >Active</option>
                        <option value="1" >InActive</option>
                    </select>
                </div>
                <div className='p-4 border-b border-b-amour-gray'>
                    <h4 className='text-dark-black text-base font-semibold pb-3 leading-[normal]'>Plan Status :</h4>
                    <select className="border border-moon-gray font-medium py-[10px] text-sm pl-4 px-3 rounded-lg leading-[normal] w-full" value={plan} onChange={(e) => setPlan(parseInt(e.target.value))}>
                        <option value="1">Paid</option>
                        <option value="2">UnPaid</option>
                    </select>
                </div>
                <div className='flex items-center justify-between pt-7 px-4 pb-4'>
                    <button className='text-camo-green font-semibold ' onClick={handleClearFilters}>Clear</button>
                    <button className='bg-camo-green text-white font-semibold py-2 px-5 rounded-md leading-[normal]' onClick={handleFilterAnsClose}>Apply All Filter</button>
                </div>
            </Popover>
        </>
    )
}
export default Filtermodal;