import React, { useEffect, useState } from 'react';
import { Checkbox, Dialog, useMediaQuery, useTheme } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMultipleUser, getUserList } from '../../store/user/user.action';
import { CLEAR_FILTERS_USER, DELETE_MULTIPLE_USER } from '../../store/user/user.type';
import moment from 'moment';
import userProfile from '../../Assest/user.png';
import { CrossIcon, FilterIcon, Leftarrow, Listmenu, Rightlongarrow, Search, Action, Deleteicon, CloseIcon } from '../../Assest/Allsvg';
import Filtermodal from '../Filtermodal/Filtermodal';
import Actionpopup from '../Actionpopup/Actionpopup';
import Userlistdetails from '../Userlistdetails/Userlistdetails';
import Header from '../Header/Header';

const UserManagement = () => {
  const [Id, setId] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user.user);
  const totalNumber = useSelector((state) => state.user.totalPage);
  const filterData = useSelector((state) => state.user.filterData);
  const [searchParams, setSearchParams] = useState({
    page: 1,
    limit: 10,
    search: '',
    sort: 0,
    startDate: 'ALL',
    endDate: 'ALL',
    status: 3,
    plan: 3,
  });
  useEffect(() => {
    if (location.pathname === '/user') {
      const defaultState = {
        sort: 0,
        startDate: 'ALL',
        endDate: 'ALL',
        status: 3,
        plan: 3
      };
      dispatch({ type: CLEAR_FILTERS_USER, payload: defaultState });
    }
  }, [location.pathname, dispatch]);
  useEffect(() => {
    if (filterData) {
      setSearchParams((prevParams) => ({
        ...prevParams,
        sort: filterData.sort,
        endDate: filterData?.endDate || prevParams.endDate,
        startDate: filterData?.startDate || prevParams.startDate,
        status: filterData.status,
        plan: filterData.plan,
      }));
    }
  }, [filterData]);
  useEffect(() => {
    dispatch(getUserList(searchParams));
  }, [searchParams, dispatch]);
  const totalPages = Math.ceil(totalNumber / searchParams.limit)
  const [currentPage, setCurrentPage] = useState(1);
  const paginationItems = () => {
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
      if (number === 1 || number === totalPages || (number >= currentPage - 1 && number <= currentPage + 1)) {
        items.push(
          <Link to="#" key={number}
            className={`p-2 ${number === currentPage ? 'bg-amour-gray' : 'text-cloud-gray'} font-semibold rounded w-8 h-8 text-cloud-gray flex items-center justify-center`}
            onClick={() => handleSelectPage(number)}>
            {number}
          </Link>
        );
      } else if (number === currentPage - 2 || number === currentPage + 2) {
        if (items[items.length - 1].key !== '...') {
          items.push(
            <li key={'...' + number} className='p-2 text-cloud-gray font-semibold rounded w-8 h-8 flex items-center justify-center'>...</li>
          );
        }
      }
    }
    return items;
  };
  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setSearchParams(prevParams => ({
        ...prevParams,
        page: prevParams.page + 1
      }));
    }
  };
  const handlePriviousClick = () => {
    if (currentPage > 1) {
      setSearchParams(prevParams => ({
        ...prevParams,
        page: prevParams.page - 1
      }));
    }
  };
  const handleSelectPage = (number) => {
    setSearchParams(prevParams => ({
      ...prevParams,
      page: number
    }));

  };
  const handleClearSearch = () => {
    setSearchParams(prevParams => ({
      ...prevParams,
      search: ''
    }))
  }
  useEffect(() => {
    setCurrentPage(searchParams.page);
  }, [searchParams.page]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchoractionEl, setactionanchorEl] = React.useState(null);
  const handleactionClick = (event, data) => {
    setactionanchorEl(event.currentTarget);
    setId(data)
  };
  const handleactionClose = () => {
    setactionanchorEl(null);
  };
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    const selectedData = checked ? user.map((data) => data._id) : [];
    setSelectedRows(selectedData);
  };
  const handleSelectRow = (event, id) => {
    const checked = event.target.checked;
    if (checked) {
      setSelectedRows((prevSelected) => [...prevSelected, id]);
    } else {
      setSelectedRows((prevSelected) => prevSelected.filter((rowId) => rowId !== id));
    }
  };
  const handleMultipleDelete = () => {
    const data = {
      user_id: selectedRows
    }
    dispatch({ type: DELETE_MULTIPLE_USER, payload: selectedRows })
    dispatch(deleteMultipleUser(data, navigate))
    setSelectedRows([])
  }
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [Open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };
  const handleOpenClick = () => {
    handleClickClose()
    handleMultipleDelete()
  }
  return (
    <>
      <Header title="User Management" />
      <div className='relative h-[calc(100%_-_65px)] md:h-[calc(100%_-_73px)]'>
        <div className='px-4 py-4 sm:py-6 flex flex-col h-full'>
          <div className='items-center justify-between pb-5 hidden md:flex'>
            <h4 className='text-xl text-dark-black font-bold'>| Users List</h4>
            <div className='flex items-center'>
              <Link to="/user/userlistdetails" className={`border border-moon-gray bg-white p-[10px] rounded-lg mr-2 
            ${location.pathname === '/user/userlistdetails'}`}><CrossIcon className="w-5 h-5" /></Link>
              <Link to="/user" className={` p-[10px] rounded-lg mr-3 ${location.pathname === '/user' ? 'bg-camo-green listmenuicon' : 'hello'}`}><Listmenu className="w-5 h-5" /></Link>
            </div>
          </div>
          <div className="overflow-x-auto bg-white p-4 sm:p-5 rounded-lg flex flex-col flex-1">
            <div className='pb-5 md:flex hidden items-center justify-between text-lg'>
              <div className='relative flex '>
                <div className='absolute top-1/2 -translate-y-1/2 left-4'>
                  <Search className="w-4" />
                </div>
                <input type="text" placeholder='Search name, mail ...' className='border border-moon-gray rounded-lg text-sm font-medium 2xl:min-w-[420px] pl-11 py-[10px] placeholder:text-cloud-gray' value={searchParams.search} onChange={(e) => setSearchParams({ ...searchParams, search: e.target.value })} />
                {searchParams.search && <div className='absolute top-1/2 -translate-y-1/2 right-3 mt-1'>
                  <button onClick={handleClearSearch}>  <CloseIcon className="w-5 h-5" /></button>
                </div>}
              </div>
              <div className='flex items-center gap-2'>
                {
                  selectedRows?.length == 0 ?
                    <div className='relative'>
                      <Link href="#" onClick={handleClick} className='text-dark-black border border-moon-gray flex items-center py-[10px] px-3 gap-2 rounded-lg leading-[normal] text-base font-medium'> <FilterIcon className="fill-[red] w-5 h-5" />Filters</Link>
                      <Filtermodal anchorEl={anchorEl} handleClose={handleClose} />
                    </div> :
                    <div>
                      <React.Fragment>
                        <button className='text-red border border-moon-gray flex items-center py-[10px] px-3 gap-2 rounded-lg leading-[normal] text-base font-medium bg-amour-gray' onClick={handleClickOpen}><Deleteicon /> {selectedRows?.length == 1 ? 'Delete' : 'Delete All'}</button>
                        <Dialog
                          className="deletemodal"
                          fullScreen={fullScreen}
                          open={Open}
                          onClose={handleClickClose}
                          aria-labelledby="responsive-dialog-title"
                        >
                          <button onClick={handleClickClose} className='absolute hidden right-5  md:block'><CloseIcon /></button>
                          <h3 className='text-lg font-bold text-woodsmoke text-center pb-[6px] md:pb-6 md:text-[26px] md:font-semibold'>{selectedRows?.length == 1 ? ' Delete User' : 'Delete Users'}</h3>
                          <h5 className='text-cloud-gray text-xs text-center font-semibold leading-6 md:text-lg'>Are you sure you want to Delete {selectedRows.length == 1 ? 'this user' : 'all users'} ?</h5>
                          <div className='flex items-center justify-center gap-5 pt-[18px] md:pt-10'>
                            <button className='bg-amour-gray text-camo-green text-sm font-semibold min-w-[143px] py-3 px-10 rounded-lg md:text-lg md:px-12 md:py-4 md:min-w-[177px]' autoFocus onClick={handleClickClose} >
                              Cancel
                            </button>
                            <button onClick={handleOpenClick} autoFocus className='bg-red-orange text-white text-sm  font-semibold min-w-[143px] py-3 
                              rounded-lg md:py-4 md:text-lg md:min-w-[177px]'>
                              Delete
                            </button>
                          </div>
                        </Dialog>
                      </React.Fragment>
                    </div>
                }
              </div>
            </div>
            <div className='overflow-auto hidden md:block flex-1'>
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="py-3 px-4 bg-white-lilac rounded-tl-lg rounded-l-lg text-start text-sm t-semibold leading-[normal] 
                  whitespace-nowrap" ><Checkbox checked={selectAll} onChange={handleSelectAll} className='!text-[#EDEEEC] select all checkbox' /></th>
                    <th className="py-3 px-4 bg-white-lilac text-camo-green text-start text-sm t-semibold leading-[normal] whitespace-nowrap">User Name</th>
                    <th className="py-3 px-4 bg-white-lilac text-camo-green text-start text-sm t-semibold leading-[normal] whitespace-nowrap">Email ID</th>
                    <th className="py-3 px-4 bg-white-lilac text-camo-green text-start text-sm t-semibold leading-[normal] whitespace-nowrap">Status</th>
                    <th className="py-3 px-4 bg-white-lilac text-camo-green text-start text-sm t-semibold leading-[normal] whitespace-nowrap">Created Date</th>
                    <th className="py-3 px-4 bg-white-lilac text-camo-green text-start text-sm t-semibold leading-[normal] whitespace-nowrap">Plan Status</th>
                    <th className="py-3 px-4 bg-white-lilac text-camo-green rounded-tr-lg ro text-sm t-semibold leading-[normal] whitespace-nowrap text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {user?.length > 0 ? (user?.map((data, index) => (
                    <tr key={index}>
                      <td className="px-4 py-3 text-sm text-dark-black font-semibold border-b border-b-amour-gray whitespace-nowrap" >
                        <Checkbox checked={selectedRows.includes(data._id)} onChange={(event) => handleSelectRow(event, data._id)} className='!text-[#EDEEEC]' /></td>
                      <td className="px-4 py-3 text-sm text-dark-black font-semibold border-b border-b-amour-gray whitespace-nowrap 
                    min-w-[200px]"><div className='flex items-center gap-2'><img className='w-8 h-8 rounded-full object-cover' src={data.profile_img || userProfile} alt="" />{data.username}</div></td>
                      <td className="px-4 py-3 text-sm text-dark-black font-semibold border-b border-b-amour-gray whitespace-nowrap">{data.email}</td>
                      <td className="px-4 py-3 text-sm border-b border-b-amour-gray whitespace-nowrap">{data?.isOnline == 1 ? <button className='bg-dark-light-green py-[6px] px-4 rounded-[63px] text-green font-semibold'>Active</button> : <button className='bg-light-green-gray py-[6px] px-5 rounded-[63px] text-green-grey font-semibold'>In-Active</button>}</td>
                      <td className="px-4 py-3 text-sm text-cloud-gray font-semibold border-b border-b-amour-gray whitespace-nowrap">{moment(data?.analytic).format("DD MMM, YYYY")}</td>
                      <td className="px-4 py-3 text-sm border-b border-b-amour-gray whitespace-nowrap"><button className='bg-light-green-gray py-[6px] px-5 rounded-[63px] text-green-grey font-semibold '>{data?.plan == null ? "Unpaid" : "Paid"}</button></td>
                      <td className="px-4 py-3 text-sm text-center border-b border-b-amour-gray">
                      <Link href="#" onClick={(e) => handleactionClick(e, data?._id)}><Action className='m-auto w-5' /></Link></td>
                      <Actionpopup anchoractionEl={anchoractionEl} handleactionClose={handleactionClose} data={Id} />
                    </tr>
                  ))) : (<td colSpan="7" className='text-center pt-4 text-sm text-cloud-gray font-semibold'>
                    Data not found
                  </td>)}
                </tbody>
              </table>
            </div>
            <div className='block md:hidden h-full'>
              <Userlistdetails />
            </div>
            <div className='hidden items-center justify-between pt-4 w-full md:flex'>


              <button className='flex items-center text-cloud-gray text-sm font-semibold gap-2' onClick={currentPage > 1 ? handlePriviousClick : undefined}> <Leftarrow className="w-6" /> PREV</button>

              <ul className='flex items-center'>
                {paginationItems()}
              </ul>

              <button className='flex items-center text-cloud-gray text-sm font-semibold gap-2' onClick={currentPage < totalPages ? handleNextClick : undefined}>NXT <Rightlongarrow className="w-6" /></button>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserManagement;