import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Dialog } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MapComponent from '../mapComponent/mapCompnent';
import { CloseIcon, Deleteicon, Editicon, Leftangle, Search } from '../../Assest/Allsvg';
import { deleteclub, getClubObject } from '../../store/club/club.action';
import { DELETE_CLUB } from '../../store/club/club.type';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import Header from '../Header/Header';

const Clubdetail = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const club = useSelector(state => state.club.clubObject);
    const [lat_long, setLat_long] = useState(club?.lat_long);
    const [lat_long1, setLat_long1] = useState(club?.lat_long1);
    useEffect(() => {
        if (club && club._id) {
            localStorage.setItem('club_id', club._id);
        }
        setLat_long(club?.lat_long)
        setLat_long1(club?.lat_long1)
    }, [club?.lat_long, club?.lat_long1, club]);
    useEffect(() => {
        const storedClubId = localStorage.getItem('club_id');
        if (storedClubId) {
            dispatch(getClubObject({ club_id: storedClubId }));
        }
    }, [dispatch]);
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);

    };
    const handleCloseDelete = () => {
        setOpen(false);
        handleDelete()
    };
    const closepopup = () => {
        setOpen(false);
    }
    const mapContainerStyle = {
        height: '400px',
        width: '70%'
    }
    const mediaQuery ={
        width: '100%',
        height:'400px'
    }
    const handleDelete = () => {
        dispatch({ type: DELETE_CLUB, payload: { id: club?._id } })
        dispatch(deleteclub({ club_id: club?._id }, navigate))
    }
    return (
        <>
            <Header title="Club Management" />
            <div className='flex flex-col flex-1 p-4 sm:py-6 sm:px-5  h-[calc(100%_-_85px)]'>
                {/* <div className='flex relative mb-4 sm:mb-5 md:hidden'>
                    <div className='absolute top-1/2 left-4 -translate-y-1/2'>
                        <Search />
                    </div>
                    <input type="text" placeholder='Search here...' className='border border-cloud-light-gray text-cloud-gray text-xs
                     py-[14px] pl-11 rounded-lg w-full placeholder:text-cloud-gray placeholder:font-medium' />
                </div> */}
                <div className='flex flex-col bg-white overflow-auto p-4 sm:p-5 rounded-[10px] md:flex-1 md:bg-transparent md:p-0'>
                    <h4 className='text-sm md:text-xl text-dark-black font-bold flex items-center gap-2 sm:gap-5 pb-2 sm:pb-4'> <Link href="#" to="/club" ><Leftangle className="w-5 sm:w-6" /></Link > | Club Detail</h4>
                    <div className='flex flex-col overflow-auto rounded-[10px] pt-0 md:flex-1 md:bg-white md:p-5'>
                        <div className='hidden justify-between items-start pb-5 md:flex'>
                            <div className='flex flex-col items-center bg-light-gray gap-4 rounded-lg md:flex-row md:bg-transparent'>
                                <img src={club?.club_profile} alt="" className='w-[100px] h-[100px] rounded-full object-cover' />
                                <h5 className='text-sm font-semibold md:text-lg lg:text-xl'>{club?.name}</h5>
                            </div>
                            <div className='flex items-center gap-2'>
                                <Link href="#" to="/club/editclubdetails" className='border border-moon-gray bg-white-lilac p-[10px] rounded-lg'><Editicon /></Link>
                                <Link href="#" to='' onClick={handleClickOpen} className='bg-light-red border border-coral-red-light p-[10px] rounded-lg'><Deleteicon /></Link>
                                <Dialog
                                    className="deletemodal"
                                    fullScreen={fullScreen}
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="responsive-dialog-title"
                                >
                                    <button onClick={handleClose} className='hidden absolute right-5 md:block'><CloseIcon /></button>
                                    <h3 className='text-woodsmoke text-lg text-center font-bold pb-[6px] md:text-2xl md:font-semibold md:pb-6'>Delete Club</h3>
                                    <h5 className='text-cloud-gray text-xs text-center font-semibold leading-6 max-w-[450px] md:text-base'>Are you sure you want to Delete this {club?.name} Club ?</h5>
                                    <div className='flex items-center justify-center pt-[18px] gap-5 md:pt-7'>
                                        <button className='bg-amour-gray text-camo-green text-sm font-semibold py-3 px-10 min-w-[143px]    
                                        rounded-lg md:text-base md:px-12 md:min-w-[177px]' autoFocus onClick={closepopup} >
                                            Cancel
                                        </button>
                                        <button onClick={handleCloseDelete} autoFocus className='bg-red-orange text-white text-sm font-semibold 
                                        py-3 min-w-[143px] rounded-lg md:text-base md:min-w-[177px]'>
                                            Delete
                                        </button>
                                    </div>
                                </Dialog>
                            </div>
                        </div>
                        <div className='flex flex-col border-t border-t-amour-gray pt-4 rounded-lg gap-4 md:border-none md:flex-1 md:pt-0 md:gap-6'>
                            <div className='flex items-center text-dark-black text-sm font-semibold gap-2 md:hidden'>
                                <img className='w-9 h-9 rounded-full object-cover' src={club?.club_profile} alt="" />{club?.name}
                            </div>
                            <table className='flex flex-col gap-3'>
                                <tbody className='flex flex-col justify-center gap-[6px] md:gap-3 '>
                                    <tr className='flex flex-wrap items-center gap-1 md:gap-3'>
                                        <td className='flex justify-between items-center text-cloud-gray font-semibold
                                    md:font-semibold md:min-w-[160px] text-sm md:leading-[normal] '>Category <span>:</span></td>
                                        <td className='text-dark-black text-sm md:leading-[normal]'>{club?.category_id?.name}</td>
                                    </tr>
                                    <tr className='flex flex-wrap items-start gap-1 md:flex-nowrap md:gap-3'>
                                        <td className='flex justify-between text-sm items-center text-cloud-gray md:font-semibold md:min-w-[160px] font-semibold md:leading-[normal]'>Description <span>:</span></td>
                                        <td className='text-dark-black max-w-[450px] text-sm md:leading-[normal]'>{club?.about}</td>
                                    </tr>
                                    <tr className='flex flex-wrap items-center gap-1 md:gap-3'>
                                        <td className='flex justify-between items-center text-cloud-gray text-sm font-semibold
                                     md:font-semibold md:min-w-[160px] md:leading-[normal]'>Max check in time <span>:</span></td>
                                        <td className='text-dark-black text-xs md:text-sm md:leading-[normal]'>{club?.maxCheckInTime}</td>
                                    </tr>
                                </tbody>
                                <MapComponent  marker={lat_long} polyog={lat_long1} mapSize={mapContainerStyle} small={mediaQuery} />
                            </table>
                        </div>
                        <div className='flex items-center md:justify-center pt-5 md:mx-auto gap-[10px] md:hidden'>
                            <Link href="#" to="/club/editclubdetails" className='flex items-center justify-center bg-amour-gray text-camo-green text-sm font-semibold py-3 px-10 rounded-md w-1/2 md:min-w-[144px]'>
                                Edit
                            </Link>
                            <Link onClick={handleCloseDelete} className='flex items-center justify-center bg-red-orange text-white 
                            text-sm font-semibold py-3 px-10 rounded-md w-1/2 md:min-w-[144px]'>
                                Delete
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Clubdetail;