import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCounts, getUser } from '../../store/dashboard/dashboard.action';
import moment from 'moment';
import { Action, Rightarrow } from '../../Assest/Allsvg';
import shapeimg from '../../Assest/shapeimage.png';
import totalmember from '../../Assest/people.png';
import activeuser from '../../Assest/activeuser.png';
import paidimage from '../../Assest/paid.png';
import bootimage from '../../Assest/kill.png';
import Actionpopup from '../Actionpopup/Actionpopup';
import userProfile from '../../Assest/user.png'
import MyAreaChart from './SalaryChart';
import Header from '../Header/Header';

const Dashboard = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(2);
  const dashboardCount = useSelector((state) => state.dashboard.dashBoardCount);
  const user = useSelector((state) => state.dashboard.user);
  useEffect(() => {
    let data = { page: page, limit: limit }
    dispatch(getCounts())
    dispatch(getUser(data));
  }, [page, limit, dispatch]);
  function formatNumber(num) {
    if (num >= 1000000000) {
      return (num / 1000000000)?.toFixed(1).replace(/\.0$/, '') + 'G';
    }
    if (num >= 1000000) {
      return (num / 1000000)?.toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
      return (num / 1000)?.toFixed(1).replace(/\.0$/, '') + 'k';
    }
    return num;
  }
  const items = [
    {
      heading: "Total Members", totaluser: totalmember, subheading: dashboardCount?.totalMember > 0 ? formatNumber(dashboardCount.totalMember) : 0
    },
    {
      heading: "Active Users", totaluser: activeuser, subheading: dashboardCount?.activeUser > 0 ? formatNumber(dashboardCount.activeUser) : 0
    },
    {
      heading: "Paid Users", totaluser: paidimage, subheading: dashboardCount?.paidUser > 0 ? formatNumber(dashboardCount.paidUser) : 0
    },
    {
      heading: "Total Kills", totaluser: bootimage, subheading: dashboardCount?.kill > 0 ? formatNumber(dashboardCount.kill) : 0
    }
  ];
  const [Id, setId] = useState('')
  const handleAddLimit = () => {
    setPage(limit == 2 ? 1 : page + 1)
    setLimit(limit + 20)
  }
  const [anchoractionEl, setactionanchorEl] = React.useState(null);
  const handleactionClick = (event, data) => {
    setactionanchorEl(event.currentTarget);
    setId(data)
  };
  const handleactionClose = () => {
    setactionanchorEl(null);
  };
  return (
    <>
      <Header title="Dashboard" />
      <div className='flex flex-col overflow-auto h-[calc(100vh_-_65px)] p-4 sm:px-5 sm:py-6 sm:h-full'>
        <div className="grid grid-cols-2 gap-4 lg:gap-6 xl:grid-cols-4">
          {items.map((item, index) => (
            <div className="col-span-1" key={index}>
              <div className='border-b-4 border-camo-green bg-white relative pt-4 p-4 pr-4 rounded-lg md:border-b-0 
                 md:border-l-4 md:pl-5'>
                <div className='relative z-10'>
                  <div className='flex flex-col items-start justify-between md:flex-row md:items-center'>
                    <h4 className='text-cloud-gray text-sm font-medium lg:text-base'>{item.heading}</h4>
                    <div className='border border-camo-green w-[42px] h-[42px] items-center justify-center rounded-lg bg-amour-gray hidden md:flex'>
                      <img src={item.totaluser} alt="" className='w-6' /></div>
                  </div>
                  <div className='flex items-end justify-between pt-2'>
                    <div className='flex items-center justify-between pt-4 gap-2'>
                      <h3 className='text-dark-black text-lg font-bold lg:text-xl'>{item.subheading}</h3>
                      <span className='flex items-center text-cloud-gray text-sm font-bold gap-2'><Rightarrow className="w-5" />{item.name}</span>
                    </div>
                    <div className='flex border border-camo-green bg-amour-gray p-[6px] rounded-lg md:hidden md:p-2'>
                      <img src={item.totaluser} alt="" className='w-6' />
                    </div>
                  </div>
                </div>
                <div className='absolute bottom-0 right-0 top-0 left-0'>
                  <img src={shapeimg} alt="" className='h-full w-full' />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="grid mt-5 gap-4 sm:gap-6 md:mt-7">
          <div className='flex flex-col bg-white p-4 md:pt-5  md:pb-9 md:px-5 rounded-lg md:p-5'>
            <h4 className='text-dark-black text-sm font-bold pb-6 sm:pb-12 md:text-xl'>| Total Users</h4>
            <div className='relative mt-4 w-[136px] h-[136px] m-auto md:w-[180px] lg:h-[200px] lg:w-[200px] 2xl:h-[200px] xl:ms-0'>
              <div className='flex items-center justify-center bg-camo-green text-white text-base font-bold w-full h-full rounded-full 
              lg:text-2xl'>
                {dashboardCount ? dashboardCount.activeUserPecentage?.toFixed(0) : 0}%
              </div>
              <div className='flex items-center justify-center border border-cloud-gray bg-light-white text-base font-bold absolute top-[-26px] 
              right-[-34px] rounded-full z-1 w-[93px] h-[93px] md:right-[-40px] md:top-[-40px]  lg:text-2xl lg:top-[-50px] lg:right-[-60px] 
              lg:w-[130px] lg:h-[130px] xl:w-[150px] xl:h-[150px] backdrop-filter'>
                {dashboardCount ? dashboardCount.paidUserPercentage?.toFixed(0) : 0}%
              </div>
            </div>
            <div className='flex justify-center items-center pt-5 gap-2 md:flex-col md:pt-7 lg:items-start'>
              <div className='flex items-center gap-[10px] radio-btn'>
                <span className='border-2 border-white bg-camo-green w-3 h-3 rounded-full shadow-[0_0_8px_rgba(13_10__44_0.8)]'></span>
                <span className='block text-dark-black text-sm font-semibold md:text-lg'>Active User</span>
              </div>
              <div className='flex items-center gap-[10px] radio-btn'>
                <span className='border-2 border-camo-green w-3 h-3 rounded-full'></span>
                <span className='block text-dark-black text-sm font-semibold md:text-lg'>Paid User</span>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden bg-white p-5 mt-7 rounded-lg md:block">
          <div className='flex items-center justify-between text-lg pb-3 sm:pb-5'>
            <h4 className='text-dark-black text-sm font-bold md:text-xl'>| User List</h4>
            <button className='text-cloud-gray text-sm font-semibold md:text-base' onClick={handleAddLimit}>View More</button>
          </div>
          <div className='overflow-auto'>
            <table className="min-w-full ">
              <thead>
                <tr>
                  <th className="bg-white-lilac text-camo-green text-start text-sm font-semibold py-2 px-3 md:px-4 md:py-3 whitespace-nowrap rounded-tl-lg rounded-l-lg leading-normal">User Name</th>
                  <th className="bg-white-lilac text-camo-green text-start text-sm font-semibold py-2 px-3 md:px-4 md:py-3 whitespace-nowrap leading-normal">Email ID</th>
                  <th className="bg-white-lilac text-camo-green text-start text-sm font-semibold py-2 px-3 md:px-4 md:py-3 whitespace-nowrap leading-normal">Status</th>
                  <th className="bg-white-lilac text-camo-green text-start text-sm font-semibold py-2 px-3 md:px-4 md:py-3 whitespace-nowrap leading-normal">Created Date</th>
                  <th className="bg-white-lilac text-camo-green text-start text-sm font-semibold py-2 px-3 md:px-4 md:py-3 whitespace-nowrap leading-normal">Plan Status</th>
                  <th className="bg-white-lilac text-camo-green text-sm text-center font-semibold py-2 px-3 md:px-4 md:py-3 whitespace-nowrap rounded-tr-lg rounded-br-lg leading-normal">Action</th>
                </tr>
              </thead>
              <tbody>
                {user?.length > 0 ? user?.map((data, index) => (
                  <tr key={index}>
                    <td className="text-dark-black text-sm font-semibold py-2 px-3 whitespace-nowrap md:py-3 md:px-4">
                      <div className='flex items-center gap-2'>
                        <img className='w-10 h-10 rounded-full object-cover' src={data.profile_img || userProfile} alt="" />{data.username}
                      </div>
                    </td>
                    <td className="text-dark-black text-sm font-semibold py-2 px-3 whitespace-nowrap md:py-3 md:px-4">{data?.email}</td>
                    <td className="py-2 px-3 md:px-4 md:py-3 text-sm whitespace-nowrap">{data?.isOnline == 1 ? <button className='bg-dark-light-green text-green font-semibold py-[6px] px-4 rounded-[63px]'>Active</button> : <button className='bg-light-green-gray text-green-grey font-semibold py-[6px] px-5 rounded-[63px]'>In-Active</button>}</td>
                    <td className="text-cloud-gray text-sm font-semibold py-2 px-3 whitespace-nowrap md:py-3 md:px-4">{moment(data?.createdAt).format("DD MMM, YYYY")}</td>
                    <td className="text-sm py-2 px-3 whitespace-nowrap md:py-3 md:px-4"><button className='bg-light-green-gray text-green-grey font-semibold py-[6px] px-5 rounded-[63px]'>{data?.plan == null ? "Unpaid" : "Paid"}</button></td>
                    <td className="py-2 px-3 md:py-3 md:px-4">
                      <Link href="#" onClick={(e) => handleactionClick(e, data?._id)}><Action className='m-auto w-5' /></Link>
                    </td>
                    {/* <Actionpopup anchorEl={anchorEl} handleClose={handleClose} data={Id} /> */}
                    {/* <Actionpopup anchoractionEl={anchoractionEl} handleactionClose={handleactionClose} data={Id} /> */}
                    <Actionpopup anchoractionEl={anchoractionEl} handleactionClose={handleactionClose} data={Id} />
                  </tr>
                )) : (<tr>
                  <td colSpan="12" className='text-center p-[10px] font-semibold text-cloud-gray text-sm'>
                    Data not found
                  </td>
                </tr>)}
              </tbody>
            </table>
          </div>
        </div>
        <div className='block bg-white p-4 sm:p-5 mt-4 sm:mt-5 rounded-[10px] md:hidden'>
          <div className='flex items-center justify-between text-lg pb-3 sm:pb-5'>
            <h4 className='text-dark-black text-sm font-bold md:text-xl'>| User List</h4>
          </div>
          <div className='grid grid-cols-1' >
            {user?.length > 0 ? user.map((data, item) => (
              <div key={data}>
                <div className='flex flex-col justify-center  border-t border-t-amour-gray pt-4 pb-[18px] gap-4 sm:gap-6'>
                  <div className='flex items-start justify-between'>
                    <div className='flex items-center text-dark-black text-sm font-semibold gap-2'>
                      <img src={data.profile_img || userProfile} alt="" className='w-[30px] h-[30px] object-cover rounded-full' />
                      <div>{data.username} {data?.isOnline == 1 ? <button className=' text-green text-xs font-semibold rounded-[63px] flex items-center gap-1'> <span className='block w-[6px] h-[6px] bg-green rounded-full'></span> Active</button> : <button className=' text-green-grey font-semibold text-xs rounded-[63px] flex items-center gap-1'><span className='block w-[6px] h-[6px] bg-green-grey rounded-full'></span> In-Active</button>}
                      </div>
                    </div>
                    <div>
                      <Link href="#" onClick={(e) => handleactionClick(e, data?._id)}><Action className='m-auto w-5' /></Link>
                      <Actionpopup anchoractionEl={anchoractionEl} handleactionClose={handleactionClose} data={Id} />
                    </div>
                  </div>
                  <table>
                    <tbody className='flex flex-col justify-center gap-2'>
                      <tr className='flex sm:items-center flex-col sm:flex-row sm:gap-1'>
                        <td className='flex items-center justify-between text-cloud-gray text-sm font-semibold'>Email :</td>
                        <td className='text-dark-black break-all text-xs font-semibold'>{data.email}</td>
                      </tr>
                      <tr className='flex items-center gap-1'>
                        <td className='flex items-center justify-between text-cloud-gray text-sm font-semibold'>Created Date :</td>
                        <td className='text-dark-black text-xs font-semibold'>{moment(data?.createdAt).format("DD MMM, YYYY")}</td>
                      </tr>
                      <tr className='flex items-center'>
                        <td className='flex items-center justify-between text-cloud-gray text-sm font-semibold'>Plan Status :</td>
                        <td className="text-sm px-2 whitespace-nowrap md:py-3 md:px-4">
                          <button className='bg-light-green-gray text-green-grey font-semibold py-[3px] px-[14px] rounded-[63px] md:py-[6px] md:px-5'>{data?.plan == null ? "Unpaid" : "Paid"}</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )) : <p className='flex justify-center text-sm font-semibold pb-3'>Data not found</p>}
            <button className='bg-camo-green text-white text-sm text-center font-semibold py-[14px] rounded-lg' onClick={handleAddLimit}>View More</button>
          </div>
        </div>
      </div>
    </>
  )
}
export default Dashboard;