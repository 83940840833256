
import { thunk } from 'redux-thunk';

import { createStore, applyMiddleware, combineReducers } from 'redux';


import dashboardReducer from './dashboard/dashboard.reducer'
import userReducer from './user/user.reducer';
import clubReducer from './club/club.reducer'
import notificationReducer from './notification/notification.reducer'
import feedbackReducer from './feedback/feedback.reducer';
import supportReducer from './support/support.reducer'
import reportReducer from './report/report.reducer';
import adminReducer from './admin/admin.reducer';


const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  user: userReducer,
  club: clubReducer,
  admin: adminReducer,
  notification: notificationReducer,
  feedback: feedbackReducer,
  support: supportReducer,
  report: reportReducer
});


const store = createStore(rootReducer, applyMiddleware(thunk));

export default store