export const GET_CLUB_LIST = "GET_CLUB_LIST"
export const GET_CLUB_LIST_FAIL = "GET_CLUB_LIST_FAIL"
export const CLEAR_FILTERS = "CLEAR_FILTERS"
export const FILTER_DATA = "FILTER_DATA"
export const GET_CLUB_OBJECT = "GET_CLUB_OBJECT"
export const GET_CLUB_BY_ID = "GET_CLUB_BY_ID"
export const GET_CLUB_CATEGORY = "GET_CLUB_CATEGORY"
export const GET_CLUB_CATEGORY_FAIL = "GET_CLUB_CATEGORY_FAIL"
export const GET_USER_FOR_CLUB = "GET_USER_FOR_CLUB"
export const GET_USER_FOR_CLUB_FAIL = "GET_USER_FOR_CLUB_FAIL"
export const ADD_NEW_CLUB = "ADD_NEW_CLUB"
export const FAIL_TO_ADD_CLUB = "FAIL_TO_ADD_CLUB"
export const DELETE_CLUB = "DELETE_CLUB"
export const UPDATE_CLUB = "UPDATE_CLUB"
export const LOADER_START = "LOADER_START"
export const LOADER_CLOSE = "LOADER_CLOSE"
export const DELETE_MULTIPLE_CLUB = "DELETE_MULTIPLE_CLUB"