import React from 'react';
import { Dialog } from '@mui/material';

const Deleteclub = () => {
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <React.Fragment>
                <Dialog
                    className="deletemodal"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <h3 className='text-woodsmoke text-[26px] text-center font-semibold pb-6'>Delete User</h3>
                    <h5 className='text-cloud-gray text-lg font-semibold'>Are you sure you want to Delete this user ?</h5>
                    <div className='pt-10'>
                        <button className='bg-amour-gray text-red-500 min-w-[177px]' autoFocus onClick={handleClose} >
                            Cancel
                        </button>
                        <button onClick={handleClose}>
                            Delete
                        </button>
                    </div>
                </Dialog>
            </React.Fragment>
        </>
    )
}
export default Deleteclub;