import { GET_USERS_COUNT, SET_ERROR, SET_LOADING, GET_DASHBOARD_DATA, GET_DASHBOARD_DATA_FAIL } from './dashboard.type'

import axios from 'axios'

const setLoading = (isLoading) => ({
    type: SET_LOADING,
    payload: isLoading,
});

const setError = (error) => ({
    type: SET_ERROR,
    payload: error,
});



export const getCounts = () => async (dispatch) => {
    try {
        dispatch(setLoading(true))
        const response = await axios.get(`v1/dashboard/total_counts`);
        dispatch(setLoading(false))
        dispatch({ type: GET_USERS_COUNT, payload: response.data.data })

    } catch (error) {
        console.error(error);
        dispatch(setError(error.messgage))

    }
};

export const getUser = (data) => async (dispatch) => {
    try {
        const response = await axios.post(`v1/dashboard/user_list`, { ...data });
        if (response.data.success) {

            dispatch({ type: GET_DASHBOARD_DATA, payload: response.data.data })
        } else {
            dispatch({ type: GET_DASHBOARD_DATA_FAIL })
        }

    } catch (error) {
        console.error(error);
        dispatch(setError(error.messgage))

    }
};
