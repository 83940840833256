import { DELETE_FEEDBACK, DELETE_MULTIPLE_FEEDBACK, GET_FEEDBACK_BY_ID, GET_FEEDBACK_COUNTS, GET_FEEDBACK_COUNTS_FAIL, GET_FEEDBACK_LIST, GET_FEEDBACK_LIST_FAIL, GET_FEEDBACK_OBJECT } from "./feedback.type"


const initialState = {
    feedbackCounts: null,
    feedbackList: [],
    totalPage: 0,
    feedbackObject: null
}

const feedbackReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FEEDBACK_COUNTS:
            return {
                ...state,
                feedbackCounts: action.payload
            }
        case GET_FEEDBACK_COUNTS_FAIL:
            return {
                ...state,
                feedbackCounts: null
            }
        case GET_FEEDBACK_LIST:
            return {
                ...state,
                feedbackList: action.payload.feedback,
                totalPage: action.payload.total
            }

        case GET_FEEDBACK_LIST_FAIL:
            return {
                ...state,
                feedbackList: [],
                totalPage: 0
            }

        case GET_FEEDBACK_OBJECT:
            const data = state.feedbackList.find(feedback => feedback._id == action.payload.id)
            return {
                ...state,
                feedbackObject: data
            }

        case GET_FEEDBACK_BY_ID:
            return {
                ...state,
                feedbackObject: action.payload
            }

        case DELETE_FEEDBACK:
            const Fdata = state.feedbackList.filter(feedback => feedback._id !== action.payload.id)
            return {
                ...state,
                feedbackList: Fdata,
                totalPage: state.totalPage - 1
            }
        case DELETE_MULTIPLE_FEEDBACK:
            const Alldata = state.feedbackList.filter(feedback => !action.payload.includes(feedback._id))
            return {
                ...state,
                feedbackList: Alldata,
                totalPage: Alldata.length
            }
        default:
            return state
    }
}

export default feedbackReducer