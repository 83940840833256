import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Sidebar from '../Components/Sidebar/Sidebar';
import Notification from '../Components/Notification/Notification';
import Addnewnotification from '../Components/Addnewnotification/Addnewnotification';

const Notificationpage = () => {
  return (
    <>
      <div className='bg-light-gray h-screen'>
        <Sidebar />
        <div className='w-full lg:w-[calc(100%_-_350px)] ml-auto h-full overflow-auto flex flex-col'>
          <Routes>
            <Route path="/*" element={<Notification />} />
            <Route path="/addnewnotification" element={<Addnewnotification />} />
          </Routes>
        </div>
      </div>
    </>
  )
}

export default Notificationpage;